import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HealthLibraryService } from '../health-library.service';
import { ShareLinkComponent } from '../share-link/share-link.component';
import { MatDialog } from '@angular/material/dialog';
import * as enTranslations from '../../../../../assets/i18n/en.json'; 
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RatingDialogComponent } from '../../rating-dialog/rating-dialog.component';

@Component({
  selector: 'app-single-article',
  templateUrl: './single-article.component.html',
  styleUrls: ['./single-article.component.scss']
})
export class SingleArticleComponent implements OnInit {
type:number
ratings
review:any
oneStars
twoStars
threeStars
fourStars
fiveStars
articles:any =[]
keys:any
content
filteredDetails: any= [];
translationKeys = Object.keys(enTranslations); 

  constructor(private apiService:HealthLibraryService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translateService: TranslateService,
  private sanitizer: DomSanitizer




  ) { }
  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.params['id'];
this.getArticle()
this.getRatings()

console.log(this.filteredDetails,"article")}

getRatings(){
  this.apiService.getRatings(this.type).subscribe((res:any)=>{
    this.ratings = res
    this.review = res.items
    this.oneStars = +(+this.ratings.one_stars / this.ratings.reviews_count) * 100
    console.log
    this.twoStars = +(this.ratings.two_stars / this.ratings.reviews_count) * 100
    this.threeStars = +(this.ratings.three_stars / this.ratings.reviews_count) * 100
    this.fourStars = +(+this.ratings.four_stars / +this.ratings.reviews_count) * 100
    this.fiveStars = +(this.ratings.five_stars / this.ratings.reviews_count) * 100

  })
}
addReview(){
  this.dialog.open(RatingDialogComponent, {
    panelClass: 'terms-conditions-dialog-panel',
    width: '60vw',
    minWidth: '400px',
    data: {
      entity:  this.type
  },
  })
}
toggleSave(){
  this.apiService.toggleSave(this.type).subscribe((res)=>{
    console.log(res)
    this.getArticle()
  })
}
getArticle(){
  this.apiService.getSingleArticle(this.type).subscribe((res:any)=>{
    
    this.content =res
    this.articles= res.details
    for (let i = 0; i < this.articles.length; i++) {
      const pattern = /(_nbsp_|__nbsp__|__ nbsp __| .__ NBSP__|_ nbsp _ |_ nbsp _  |: _ nbsp _|: __ nbsp__|NBSP|.__ nbsp__| __ nbsp__| .__ & nbsp __|nbsp __)/g;

      console.log(this.articles,'article-new')
      this.articles[i].Content =
      this.articles[i].Content = this.articles[i].Content.replace(pattern, '&nbsp;');}
})

}

    



openDialog(){
    const dialog = this.dialog.open(ShareLinkComponent, {
      panelClass: 'delete-dialog-panel',
      disableClose: false,
      width: '70vw',
      minWidth: '100px',
      // data: { entity: e, customer_id: e.id },
    });

    dialog.afterClosed().subscribe((result) => {
    this.getRatings()

      
    });
  
}
}
