import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

 
  countDown!: Subscription;
  counter = 60;
  tick = 1000;
  disabled = true;
code:number
  constructor(
      public dialog: MatDialog,
      private router: Router,
      private authService:AuthService,
      
      
  ) {
  }
  route() {
      this.dialog.closeAll();
      this.router.navigate(["signup"]);
  }

  ngOnInit(): void {
      this.countDownSubscription();
  }

  countDownSubscription() {
      this.countDown = timer(0, this.tick)
          .pipe(take(this.counter))
          .subscribe(() => {
              --this.counter;
              if (this.counter == 0) {
                  this.countDown.unsubscribe();
                  this.disabled = false;
              }
          });
  }

  transform(value: number): string {
      const minutes: number = Math.floor(value / 60);
      return (
          ("00" + minutes).slice(-2) +
          ":" +
          ("00" + Math.floor(value - minutes * 60)).slice(-2)
      );
  }

  onCodeChanged(code: string) {}

  onCodeCompleted(code: number) {
      console.log('verify',code)
      this.code =  code
      this.router.navigate(["/subscribe"]);
      
  }

  resend() {
      if (!this.disabled) {
          this.disabled = true;
          this.counter = 60;
          this.countDownSubscription();
      }
  }

  sendOtp(){
      const body ={
          otp:this.code
      }
      this.authService.sendOtp(body).subscribe((res)=>{
this.router.navigate(['/subscribe'])

      })
  }
}

