import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-phone-number-dialog",
    templateUrl: "./phone-number-dialog.component.html",
    styleUrls: ["./phone-number-dialog.component.scss"],
})
export class PhoneNumberDialogComponent implements OnInit {

    phone : number = null;

    constructor(
        public dialogRef: MatDialogRef<PhoneNumberDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    confirm() {
        let res = true;
        this.dialogRef.close({phone : this.phone});
    }

    cancel() {
        let res = false;
        this.dialogRef.close(res);
    }
}
