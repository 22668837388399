<!-- <app-header-two></app-header-two> -->

<div class="wrapper">
    <h2 class="ms-3">
        {{diseaseInfo?.title}} 
    </h2>
    <section *ngFor="let key of keys">
        <div class="title">
          <img src="./../../../../assets/images/know-more/definition.svg">
          {{ key | translate | titlecase }}
        </div>
      
        <div class="card">
          <!-- Check if the value is an array -->
          <ul *ngIf="isArray(articles[key]); else showString">
            <li *ngFor="let item of articles[key]">
              {{ item }}
            </li>
          </ul>
          <!-- Template for string -->
          <ng-template #showString>
            {{ articles[key] }}
          </ng-template>
        </div>
      </section>
      

</div>