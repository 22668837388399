import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-diseases-list",
    templateUrl: "./diseases-list.component.html",
    styleUrls: ["./diseases-list.component.scss"],
})
export class DiseasesListComponent implements OnInit {
    diseases: any[] = [];
    params$;
    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.params$ = this.route.params.subscribe((params) => {
            let id = Number(params["id"]);
            if (id !== undefined) this.fillDiseases(id);
        });
    }

    ngOnInit(): void {}

    fillDiseases(id) {
        this.apiService.getDiseasesByID(id).subscribe((res) => {
            if (!res) return;
            this.diseases = res["data"]['items'];
        });
    }

    navigateToViewDisease(obj) {
        this.router.navigate(["/disease", { id: obj.id }], {
            relativeTo: this.route,
        });
    }
}
