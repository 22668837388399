import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../../../services/api.service";

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {
  isSubmitted = false;

  password : string = ''
  newPwForm!: FormGroup
  constructor(
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    private apiService: ApiService,

    @Inject(MAT_DIALOG_DATA) public data: any,        private formBuilder: FormBuilder

) {}

ngOnInit() {
  console.log(this.data,"data")
  this.initPwForm()
}
initPwForm() {
  this.newPwForm = this.formBuilder.group({
      new_password: [
          '', [Validators.required, this.minLength,
          this.hasLowerCaseValidator,
          this.hasUpperCaseValidator,
          this.hasSpecialCharacter,
          this.hasNumber,]
      ],
      password_confirmation: ["", Validators.required],
}, { validators: this.confirmPasswordValidator });}
confirm() {
  this.isSubmitted = true;

    let res = true;
 let body ={
  "old_password": this.data.password, 
  "password": this.newPwForm.value.new_password,
  "password_confirmation":this.newPwForm.value.password_confirmation
 }
 this.apiService.changePassword(body).subscribe((res)=>{

   this.dialogRef.close('success');

 })
}

cancel() {
    let res = false;
    this.dialogRef.close(res);
}
getSecurityErrors(controlName: string): ValidationErrors | null {
  return this.newPwForm?.controls[controlName].errors;
}




hasUpperCaseValidator(control: FormControl) {
  if (
    control.value != null &&
    control.value === control.value.toLowerCase()
  ) {
    return { upperCase: true };
  }

  return null;
}

hasLowerCaseValidator(control: FormControl) {
  if (control.value != null && control.value === control.value.toUpperCase())
    return { lowerCase: true };

  return null;
}

hasSpecialCharacter(control: FormControl) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (control.value != null && !specialChars.test(control.value))
    return { specialCharacter: true };

  return null;
}

hasNumber(control: FormControl) {
  if (control.value != null && !/\d/.test(control.value))
    return { hasNumber: true };

  return null;
}

minLength(control: FormControl) {
  if (control.value.length < 8) return { minLength: true };
  return null;
}

confirmPasswordValidator(control: AbstractControl) {
  return control.value.new_password === control.value.password_confirmation
    ? null
    : { passwordsNotMatched: true };
}

getPasswordMismatchError() {
  return this.newPwForm?.errors
    ? this.newPwForm.errors['passwordsNotMatched']
    : null;
}
}
