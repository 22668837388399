import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorage: any;
  private imageSource = new BehaviorSubject<string | null>(this.getImageFromLocalStorage());
  image$ = this.imageSource.asObservable();

  private loggedIn = new BehaviorSubject<boolean | null>(this.getStatus());
  logged$ = this.loggedIn.asObservable();

  constructor(private router: Router
) {
    this.localStorage = localStorage;
  }
  private getImageFromLocalStorage(): string | null {
    return localStorage.getItem('user_avatar');
  }
  private getStatus(): any | null {
    return localStorage.getItem('token');
  }
  getImage(): string | null {
    return localStorage.getItem('user_avatar');
  }
  getStatuslogged(): string | null {
    return localStorage.getItem('token');
  }
  updateStatus(token:any ): void {
    localStorage.setItem('token', token);
    this.imageSource.next(token); // Emit the updated image
  }

  updateImage(imageUrl: string): void {
    localStorage.setItem('user_avatar', imageUrl);
    this.imageSource.next(imageUrl); // Emit the updated image
  }
  retrieve(key: string) {
    const item = this.localStorage.getItem(key);

    return item !== null && item !== 'undefined'
      ? JSON.parse(this.localStorage.getItem(key))
      : null;
  }

  store(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    this.localStorage.removeItem(key);
  }

  removeAll() {
    this.localStorage.clear();
  }

  checkRoles(moduleName: string) {
    const roles = localStorage.getItem('roles');
    // const rolesArray = JSON.parse(roles!);
    // const moduleRole = rolesArray.find((role: any) => role.name == moduleName);
    if (roles?.includes(moduleName)) {
      return true;
    }
    return false;
  }
}
