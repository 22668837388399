<div  *ngIf="hasNav" >
  <div *ngIf="hasNav">
    <mat-toolbar class="primary-toolbar">
      <button
      *ngIf="!isBasePath"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        >
        <mat-icon  class="custom-icon-color" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="items w-100 d-flex justify-content-between align-items-center">
        <a (click)="reload('home')">
          <img
              src="assets/img/logo.png"
              alt="logo"
              style="height: 70px; width: 100px"
          />
      </a>
  
     <div class="gap-4 d-flex">

      <!-- routerLink="/ai-home" -->
      <a *ngIf="!isLoggedIn" (click)="warning()">
        {{'Home'| translate}}
      </a>
      <!-- routerLink="/health-library" -->
  <a *ngIf="!isLoggedIn" (click)="warning()" >
    {{'Health Library'| translate}}
  </a>
  <!-- routerLink="/ask-ai" -->
  <a *ngIf="!isLoggedIn" (click)="warning()">
    {{'ask_ai'| translate}}
  </a>
</div>

    
        <div  class="end-section d-flex align-items-center">
          <div *ngIf="isLoggedIn" class="option-item">
            <!-- <div class="search-btn-box">
                <button
                    mat-icon-button
                    class="search-btn"
                >
                    <mat-icon
                        svgIcon="search"
                    ></mat-icon>
                </button>
            </div> -->
        </div>
        <!-- <div class="notifications" >
          <button mat-button [matMenuTriggerFor]="notificationsMenu">
            <img src="./../../../assets/images/nav/icons/blue-icon/bell.svg" />
          </button>
          <mat-menu #notificationsMenu="matMenu">
            <div class="p-4">
              <h5>notifications</h5>
              <hr />
              <div *ngFor="let item of notifications?.items">
                <div>{{ item.message.title }}</div>
                <div class="date">{{ item.message.created_at | dateAgo }}</div>
              </div>
            </div>
          </mat-menu>
        </div> -->
          <!-- <div class="user"> -->
            <!-- <div > -->
            <button *ngIf="isLoggedIn"  class="img-wrapper" mat-button routerLink="/patient-profile">
                <img class="circle-img"
                  src="{{
                    userLogo !== 'null' && userLogo !== null ? userLogo : '../../../../assets/img/profile-user-svgrepo-com.svg'
                  }}"
                />
                <span>{{ userName }}</span>
              </button>


              <a *ngIf="!isLoggedIn" class="register"  routerLink="/register">
                {{'sign_up'| translate}}
              </a>
              <a *ngIf="!isLoggedIn" class="login"  routerLink="/login">
                {{'Login'| translate}}
              </a>
            <!-- </div> -->
            <!-- <mat-menu #userMenu="matMenu">
              <button mat-menu-item routerLink="/patient-profile">Profile</button>
            </mat-menu> -->
          <!-- </div> -->
       
          <div class="lang">
            <!-- mat-button [matMenuTriggerFor]="langMenu -->
             <img class="mx-2" src="./../assets/nav/globe.svg">
              <a (click)="setLang()">{{lang}}</a>
            <!-- <mat-menu #langMenu="matMenu">
              <button mat-menu-item (click)="setLang('ar')">ar</button>
              <button mat-menu-item (click)="setLang('en')">en</button>
            </mat-menu> -->
          </div>
        </div>
      </div>
    </mat-toolbar>
    <!-- <app-header-two ></app-header-two> -->
  </div>
  <!-- <app-header-two></app-header-two> -->
  <!--     
  <div [dir]="$any(dir)">
      <button
        type="button"
        class="oc-icon"
        aria-label="Toggle sidenav"
        mat-icon-button
        [class]="isLg ? 'small-oc' : 'lg-oc'"
        (click)="drawer.toggle(); isLg = !isLg"
      > 
  
      >>
      </button>
    </div> -->
  
  <mat-sidenav-container class="sidenav-container" [dir]="$any(dir)">
      <mat-sidenav
        #drawer
        class="sidenav"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="'over'"
        [opened]=" false"
      >
      <!-- [mode]="(isHandset$ | async) ? 'over' : 'side'" -->
  <!--  -->
        <div class="side-nav mt-md-5 pt-5">
          <div>
          <div *ngFor="let item of menuItems">
            <div
            (click)="onNavItemClicked()"

            [routerLink]="item.path"
              [ngClass]="{ 'hidden-element': !showItem(item) }"
              class="nav-item"
              routerLinkActive="active"
              >
              <img
                class="side-nav__icon"
                [src]="'assets/images/blue/icons/' + item.icon + '.svg'"
                alt=""
              />
              <span class="sidenav__title">{{ item.text | translate | titlecase }}</span>
            </div>
          </div>
        </div>
          <div class="my-5 mx-5 d-flex align-items-end" (click)="logout()"><span> <img src="assets/images/blue/icons/logout.svg" alt=""></span>
            {{'Logout'| translate}}</div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content  class="container-content">
  <div style="
  min-width: 100vw;
  min-height: 75vh;">

    <router-outlet> </router-outlet>
  </div>
        <!-- Add Content Here -->
        <app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/login' || location == '/register' || location == '/ask-ai'|| location == '/subscribe')"></app-footer>
      </mat-sidenav-content>
    </mat-sidenav-container>
  <!-- <router-outlet></router-outlet> -->
  
</div>

<div *ngIf="!hasNav">
  <router-outlet> </router-outlet>

</div>
