<div class="wrapper d-flex flex-column align-items-center">
    <P><strong>Forget Password</strong></P>
    <!-- <img src="assets/images/auth/icons/confirm-dialog.svg" /> -->
    <p>
        Password Reset Link Sent! Please check your verified email or phone
        number for the reset link. If you don't see it, be sure to check your
        spam or junk folder.
    </p>
    <button type="submit" (click)="route()">Ok</button>
</div>
