import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { HealthLibraryService } from "./health-library.service";

@Component({
    selector: "app-health-library",
    templateUrl: "./health-library.component.html",
    styleUrls: ["./health-library.component.scss"],
})
export class HealthLibraryComponent implements OnInit {
    categories: any[] = [];
    data:any = [
        // Your JSON data here...
      ];
      
  categorizedData = {
    news: [],
    medication: [],
    dietPlan: [],
    dentistry: [],
    psychiatry: [],
    vaccination: [],
    diseases: []
  };
  

    constructor(
        private apiService: HealthLibraryService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        
    }

    ngOnInit(){
        this.getHealthLibrary()

    }

    getHealthLibrary(){
        this.apiService.getHealthLibrary().subscribe((res)=>{
            console.log(res)
            this.data = res
            this.categorizeData();

        })

    }




  categorizeData() {
    this.data.forEach(item => {
      switch (item.type) {
        case 1:
          this.categorizedData.news.push(item);
          break;
        case 2:
          this.categorizedData.medication.push(item);
          break;
        case 3:
          this.categorizedData.dietPlan.push(item);
          break;
        case 4:
          this.categorizedData.dentistry.push(item);
          break;
        case 5:
          this.categorizedData.psychiatry.push(item);
          break;
        case 6:
          this.categorizedData.vaccination.push(item);
          break;
        case 7:
          this.categorizedData.diseases.push(item);
          break;
        default:
          console.log(`Unknown type ${item.type}`);
      }

    });
    console.log(this.categorizedData)
  }
}


