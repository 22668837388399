import { OnInit } from '@angular/core';
import {Component, ViewChild} from '@angular/core';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
// import {MatDatepickerModule} from '@angular/material/datepicker';
// import {MatInputModule} from '@angular/material/input';
// import {MatFormFieldModule} from '@angular/material/form-field';
// import {MatIconModule} from '@angular/material/icon';
// import {MatButtonModule} from '@angular/material/button';
// import {MatNativeDateModule} from '@angular/material/core';
import { AiDiagnosisService } from '../../ai-diagnosis/ai-diagnosis.service';
// import { CommonModule } from '@angular/common';
import { ChartConfiguration } from 'chart.js';
// import { NgChartsModule } from 'ng2-charts';
// import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-diagnosis-history',
  templateUrl: './diagnosis-history.component.html',
  styleUrls: ['./diagnosis-history.component.scss'],
  // imports: [
  //   CommonModule,
  //   MatButtonModule,
  //   NgChartsModule,
  //   RouterModule,
  //   MatExpansionModule,
  //   MatIconModule,
  //   MatFormFieldModule,
  //   MatInputModule,
  //   MatDatepickerModule,
  //   MatNativeDateModule,
  // ],
})
export class DiagnosisHistoryComponent implements OnInit {
  // @ViewChild(MatAccordion) accordion: MatAccordion;
history:any
public doughnutChartDatasets: ChartConfiguration<"doughnut">["data"]["datasets"] =
[
    // { data: [50, 150, 120], label: "Series B" },
    // { data: [250, 130, 70], label: "Series C" },
];

public doughnutChartOptions: ChartConfiguration<"doughnut">["options"] = {
responsive: true,
cutout: "90%",
plugins: {
    legend: {
        display: false,
    },
},
};


  constructor(private diagnosisService:AiDiagnosisService) { }

  ngOnInit(): void {
    this.getDiagnosisHistory()
  }

  getChartData(result: any) {
    return {
      datasets: [
        {
          data: [result.probability, 100 - result.probability],
          backgroundColor: ['#DE3163', 'pink'],
          borderWidth: [0.1],
          cutout: "90%",

        },
      ],
    };
  }

  getDiagnosisHistory(){
    this.diagnosisService.getDiagnosisHistory().subscribe((res)=>{
      this.history = res
    })
  }

}
