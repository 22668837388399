<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ "Health Library" | translate }}</h2>
        </div>

        <!-- <div class="feedback-slides owl-carousel owl-theme">
            <div
                class="single-feedback-item"
                *ngFor="let category of categories"
            >
                <mat-card>
                    <img
                        mat-card-image
                        [src]="
                            category?.image?.url
                                ? category.image.url
                                : 'assets/img/Group 52033.svg'
                        "
                        alt="image"
                    />

                    <mat-card-content>
                        <p class="library-name">
                            <span>{{ category.title }}</span>
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div> -->

        <div class="row">
            <div
                class="col-lg-4 col-md-4 col-sm-6"
                style="height: 250px"
                *ngFor="let category of categories"
            >
                <div
                    class="single-categories-listing-item bg"
                    [style.background-image]="
                        'url(' + category.image?.url + ')'
                    "
                >
                    <div class="icon"></div>
                    <h3>{{ category.title }}</h3>
                    <span [innerHtml]="category.text"></span>

                    <!-- <a (click)="navigateToViewNew(medication)" class="learn-more-btn"
                        >Learn More <i class="bx bx-chevron-right"></i
                    ></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
