import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from 'src/app/services/modal.service';
import { PasswordDialogComponent } from '../../common/password-dialog/password-dialog.component';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  securityForm!: FormGroup;
// 
  constructor(        private formBuilder: FormBuilder,
    private modal: ModalService,
    private apiService: ApiService,
    private dialog: MatDialog,



  ) { }

  ngOnInit(): void {
    this.initSecurityForm()

  }
  getSecurityErrors(controlName: string): ValidationErrors | null {
    return this.securityForm?.controls[controlName].errors;
}
initSecurityForm() {
  this.securityForm = this.formBuilder.group({
      password: ["", Validators.required],
      new_password: [
          "", Validators.required
      ],
      password_confirmation: ["", Validators.required],
});
}


updatePassword(){
  this.changePassword() 

  
}
changePassword() {
  //this.test()
  let defaultWidth: string = "40rem";
  const dialogRef = this.dialog.open(PasswordDialogComponent, {
      width: defaultWidth,
      data: this.securityForm.value
  
  });

  return dialogRef.afterClosed().subscribe((res) => {
      if (res ==='success'){
      this.modal.snackMessage("Password updated successfully");

      }

  });
}
}
