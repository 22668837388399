<!-- <app-header-two></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Your Question Has Been Sent</h2>
        </div>

       

        <div class="col-flex">
            <div class="row-flex">
                <div style="flex: 10; text-align: center">
                    <img src="assets/img/pass.png" width="150px" />
                </div>
            </div>

            <br>
            <br>

            <div class="row-flex">
                <div style="flex: 10">
                    <div class="destinations-btn-box">
                        <button
                            mat-raised-button
                            color="primary"
                            class="default-btn"
                            routerLink="/"
                        >
                            {{ "BACK TO HOME" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
