<app-header-two></app-header-two>

<div class="wrapper">
    <h2 class="ms-3">
        {{diseaseInfo?.title}} 
    </h2>
    <section *ngFor="let key of keys">
        <div class="title"> <img src="./../../../../assets/images/know-more/definition.svg">         {{ key   | translate |titlecase }}
        </div>

        <div  class="card">{{ articles[key] }}
        </div>
    </section>

</div>