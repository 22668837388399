import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HealthLibraryService {

  constructor(private httpClient: HttpClient) { }


viewCategory(type:number) {
  return this.httpClient.get(
      `${environment.baseUrl}` +
          `/api/contents/type/${type}`
  );
}

getHomeData(){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/contents`
);
}

getHealthLibrary(){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/contents`
);
}
getSingleArticle(id:number){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/contents/${id}
`
);
}


toggleSave(id:number){

  return this.httpClient.post(
    `${environment.baseUrl}` +
        `/api/contents/${id}/fav
`,{}
)
}


}
