import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-phone-number-verification-dialog",
    templateUrl: "./phone-number-verification-dialog.component.html",
    styleUrls: ["./phone-number-verification-dialog.component.scss"],
})
export class PhoneNumberVerificationDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PhoneNumberVerificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    confirm() {
        let res = true;
        this.dialogRef.close(res);
    }

    cancel() {
        let res = false;
        this.dialogRef.close(res);
    }

    onCodeChanged(code: string) {
        console.log(code);
    }

    onCodeCompleted(code: string) {
        console.log(code);
    }
}
