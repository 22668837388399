<section class="register-area">
    <mat-card>
        <div class="row m-0" style="height: 100%">
            <div class="col-lg-6 col-md-12 p-0 ">
                <div class="register-content">
                    <p class="ms-0">Sign Up</p>

                    <div class="register-form">
                        
                                <!-- <ng-template mat-tab-label>
                                    <img
                                        src="./../../../../assets/images/Auth/phone.svg"
                                        alt=""
                                    />
                                    <div class="me-2 tab-tite">
                                        Phone Number
                                    </div>
                                </ng-template> -->

                                <form name="txtForm" [formGroup]="phoneForm">
                                    <div class="form-group mb-3">
                                        <label for="name">User Name</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Your Name"
                                            class="form-control"
                                            formControlName="name"
                                        />
                                    </div>

                                    <div class="form-group mb-3">
                                        <label>
                                            Phone Number
                                            <span class="red-star" *ngIf="!phoneForm.value.email">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter Your phone number"
                                            class="form-control"
                                            name="txtName"
                                            formControlName="phone"
                                        />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label>
                                            Email
                                            <span class="red-star"  *ngIf="!phoneForm.value.phone && phoneForm.value.email">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            placeholder="Enter Your email"
                                            class="form-control"
                                            name="txtName"
                                            formControlName="email"
                                            
                                        />
                                    </div>
                                    <div class="form-group mb-3">
                                        <div>
                                            <div class="form-group mb-3">
                                                <label for="email"
                                                    >Date Of Birth</label
                                                >

                                                <input
                                                    type="date"
                                                    placeholder="Enter Your DOB"
                                                    class="form-control"
                                                    name="txtName"
                                                    formControlName="dob"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2 mb-2 radio-group">
                                        <label
                                            id="example-radio-group-label"
                                            class="me-4 ms-0"
                                            >Gender</label
                                        >
                                        <mat-radio-group
                                            aria-labelledby="example-radio-group-label"
                                            class="example-radio-group"
                                            formControlName="gender"
                                        >
                                            <mat-radio-button
                                                class="example-radio-butto m-3"
                                                value="1"
                                            >
                                                male
                                            </mat-radio-button>
                                            <mat-radio-button
                                                value="2"
                                                class="example-radio-button m-3"
                                            >
                                                female
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <section class="example-section mt-2 mb-3">
                                        <mat-checkbox
                                            class="example-margin"
                                            formControlName="checked"
                                        >
                                            I Agree
                                            <span routerLink="/terms" class="terms"
                                                >Terms and conditions </span
                                            >  -<span class="terms"> privacy
                                                policy</span>
                                        </mat-checkbox>
                                    </section>

                                    <button
                                        type="submit"
                                        [disabled]="!phoneForm.valid"
                                        (click)="signUp()"
                                    >
                                        Signup
                                    </button>
                                    <!-- [disabled]="
                                            !ngRegisterationForm.valid ||
                                            loading
                                        " -->
                                    <div class="center">
                                        Dont Have Any Account ?<a
                                            routerLink="/login"
                                            class="text-primary"
                                        >
                                            Login</a
                                        >
                                    </div>
                                </form>

                        <!-- <p>
                                    Already register?
                                    <a routerLink="/login">Login</a>
                                </p> -->
                    </div>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="register-image">
                    <img
                        src="assets/img/ashkan-forouzani-ignxm3E1Rg4-unsplash.png"
                        alt="image"
                    />
                </div>
            </div>
        </div>
    </mat-card>
</section>
