
<div class="display-web">
<div class="d-flex limit-section">
    
    <div class="question">
{{selectedQuestion?.user_message??response[0]?.user_message}}    </div>

</div>
<br>
    <div class="body row">
    <div class="col-lg-4 col-md-4 col-sm-12">
        <h2>{{'my_questions'| translate}}</h2>
        <mat-form-field appearance="outline" class="w-100">
            <input (input)="searchByUserMessage()"  [(ngModel)]="inputValue" matInput [placeholder]="'Search'">
            <mat-icon (click)="searchByUserMessage()" matSuffix>search</mat-icon>
          </mat-form-field>
        <div class="question-item" *ngFor="let question of search let i = index"   (click)="selectQuestion(i,question.id)"
        [ngClass]="{'active': selectedQuestionIndex === i}">
            
            <div >{{question.user_message | titlecase}}</div>
            <div class="time">
{{question.created_at | dateAgo }}
            </div>
        </div>
    </div>
     
    <div class="res d-flex col-lg-7 col-md-8 col-sm-12 " >
        <div class="circle-res" *ngIf="gender==1">
            <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
        </div>
        <div class="circle-res" *ngIf="gender==2">
            <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
        </div > <div [innerHTML]="selectedQuestion?.ai_message?? response[0].ai_message"></div>
    </div>
</div> 
</div>
<div class="display-mobile">
<div class="d-flex limit-section">
    
    <div class="question">
{{selectedQuestion?.user_message??response[0]?.user_message}}    </div>

</div>
<br>
    <div class="body row">
    <div class="col-lg-4 col-md-4 col-sm-12">
        <h2>{{'my_questions'| translate}}</h2>
        <mat-form-field appearance="outline" class="w-100">
            <input (input)="searchByUserMessage()"  [(ngModel)]="inputValue" matInput [placeholder]="'Search'">
            <mat-icon (click)="searchByUserMessage()" matSuffix>search</mat-icon>
          </mat-form-field>
            
         
        </div>

<mat-accordion>
    <div class="question-item" *ngFor="let question of search let i = index"   (click)="selectQuestion(i,question.id)">

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
            <div >{{question.user_message | titlecase}}</div>
            <div class="time">
                {{question.created_at | dateAgo }}
                            </div>
        </mat-panel-title>
    
      </mat-expansion-panel-header>
      <div class="res d-flex col-lg-7 col-md-8 col-sm-12 " >
        <div class="circle-res" *ngIf="gender==1">
            <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
        </div>
        <div class="circle-res" *ngIf="gender==2">
            <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
        </div > <div [innerHTML]="selectedQuestion?.ai_message?? response[0].ai_message"></div>
    </div>    </mat-expansion-panel>
    </div>

  </mat-accordion>
  

</div>

</div>


<div *ngIf="isLoading" class="spinner m-auto">
    <div class="m-auto">
  
      <mat-spinner color="#12B6DF"></mat-spinner>
    </div>
    <div>{{'ai_answering'| translate}}</div>
  </div>