<!-- <app-header-two></app-header-two> -->

<section class="news-section row">
    <div class="section-header">
        <h2>Health News</h2>
    </div>
    <div *ngFor="let card of items" class="col-lg-3 col-md-6 col-sm-10 mb-2" > 

    <div *ngIf="isArticle"[routerLink]="['/single-article',card.id]" class="news-cards">
        <!-- Repeat this card for each news item -->
        <div class="card ">
            <img [src]="card.image" alt="Article Image">
            <div class="p-2">

                <h3 class="h3-title">{{card.title}}</h3>
                <p  style="
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
">{{card.summary}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="isContnent" [routerLink]="['/single-content',card.id]" class="news-cards">
        <!-- Repeat this card for each news item -->
        <div class="card ">
            <!-- <img src="./../../../../assets/images/health-library/news.jpeg" alt="Article Image"> -->
            <div class="p-4">

                <h3 class="content-title">{{card.title}}</h3>
                <p  style="
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
">{{card.summary}}</p>
            </div>
        </div>
    </div>
    </div>
</section>
