import { CommonModule } from "@angular/common";
import {
    CUSTOM_ELEMENTS_SCHEMA,
    Component,
    Input,
    ViewChild,
    forwardRef,
} from "@angular/core";
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from "@angular/forms";
import { GeneralInputsAccessor } from "../../shared/classes/GeneralInputsAccessor";
import { ValidationErrorsComponent } from "../../shared/components/validation-errors/validation-errors.component";

@Component({
    selector: "app-general-input",
    templateUrl: "./general-input.component.html",
    styleUrls: ["./general-input.component.scss"],
    // imports: [CommonModule, FormsModule, ValidationErrorsComponent],
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeneralInputComponent),
            multi: true,
        },
    ],
    imports: [ValidationErrorsComponent, CommonModule, FormsModule],
})
export class GeneralInputComponent extends GeneralInputsAccessor {
    @Input() label!: string;
    @Input() required!: string;

    @Input() type!: string;
    @Input() placeholder!: string;
    @Input() icon!: string;
    @Input() isPassword = false;
    @Input() isSubmitted = false;
    @Input() errors!: ValidationErrors | null;
    @Input() mismatchError = false;
    @Input() isAuthInput = false;

    @Input() isDisabled = false;

    showPassword = false;
    passwordIconSrc = "assets/images/Auth/eye.svg";
    @ViewChild("passwordInput") passwordInput!: any;

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
        if (this.showPassword) {
            this.passwordInput.nativeElement.type = "text";
            this.passwordIconSrc = "assets/images/Auth/eye-hidden.svg";
        } else {
            this.passwordInput.nativeElement.type = "password";
            this.passwordIconSrc = "assets/images/Auth/eye.svg";
        }
    }

    changedValue(evt: any) {
        if (this.type == "tel") {
            let ASCIICode = evt.which ? evt.which : evt.keyCode;
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
                return false;
            return true;
        } else {
            return true;
        }
    }
}
