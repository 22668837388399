<!-- <app-header-two (notifyRouterChange)="clearNewsFilter($event)"></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container pt-3">
        <div class="row">
            <div class="col-lg-2 sort-by top-space-responsive">
                <span style="white-space: nowrap !important"
                    >{{ "Sort By" | translate }} :</span
                >
            </div>
            <!-- <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>{{ "Newest" | translate }}</option>
                    </select>
                </div>
            </div> -->
            <div class="col-lg-2 top-space-responsive">
                <!-- <div class="select-box">
                    <select class="form-control" name="txtCat">
                        <option>{{ "Category" | translate }}</option>
                        <option *ngFor="let cat of categories" [value]="cat.id">
                            {{ cat.title }}
                        </option>
                    </select>
                </div> -->

                <mat-form-field appearance="none">
                    <mat-select class="form-control" #selectedValue  name="txtCat" [placeholder]="'Category' | translate" (selectionChange)="filterByCategoryID($event)">
                        <mat-option *ngFor="let cat of categories" [value]="cat.id">
                            {{ cat.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>{{ "Top Viewed" | translate }}</option>
                    </select>
                </div>
            </div> -->
            <!-- <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>{{ "Popular" | translate }}</option>
                    </select>
                </div>
            </div> -->

            <div class="col-lg-1"></div>
        </div>

        <br />
        <br />

        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6" *ngFor="let new of news">
                <div
                    class="single-categories-listing-item bg"
                    [style.background-image]="'url(' + new.image.url + ')'"
                    (click)="navigateToViewNew(new)"
                >
                    <div class="icon"></div>
                    <h3>{{ new.title }}</h3>

                    <a (click)="navigateToViewNew(new)" class="learn-more-btn"
                        >Learn More <i class="bx bx-chevron-right"></i
                    ></a>
                </div>
            </div>
        </div>

        <mat-paginator
            *ngIf="lstLength"
            class="pagination"
            [length]="lstLength"
            [pageSize]="pageSize"
            [showFirstLastButtons]="true"
            [pageSizeOptions]="[]"
            (page)="setPageChangesEvents($event)"
            [pageIndex]="currentPage"
        ></mat-paginator>
    </div>
</section>
