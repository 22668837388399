import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorage: any;

  constructor(private router: Router) {
    this.localStorage = localStorage;
  }

  retrieve(key: string) {
    const item = this.localStorage.getItem(key);

    return item !== null && item !== 'undefined'
      ? JSON.parse(this.localStorage.getItem(key))
      : null;
  }

  store(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    this.localStorage.removeItem(key);
  }

  removeAll() {
    this.localStorage.clear();
  }

  checkRoles(moduleName: string) {
    const roles = localStorage.getItem('roles');
    // const rolesArray = JSON.parse(roles!);
    // const moduleRole = rolesArray.find((role: any) => role.name == moduleName);
    if (roles?.includes(moduleName)) {
      return true;
    }
    return false;
  }
}
