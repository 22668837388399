<!-- <app-header-two></app-header-two> -->
<br>
<div class="hero-image "     [lang]="lang"
> 
    <div class="hero-text">
      <p>AI-Powered Diagnosis</p>
      <p>Smarter, </p> <p class="blue">Faster and </p>
<p>More Accurate</p>
      <button class="default-btn-cover" routerLink="/ai-diagnosis">{{"AI Diagnosis"| translate}}</button>
    </div>
  </div>

  <h1>{{'Our_intelligent_diagnostic'| translate}}</h1>
  <section class="main-section">

<div class="d-flex row mb-2">
    <div class=" col-lg-4 col-md-6 col-sm-10 mb-2">

        <div class="machine-cards d-flex flex-column bd-highlight ">
            <h3>{{'News' | translate}}</h3>
<hr>
<p>{{'news_provide'| translate}}</p>
<div class="try mt-auto align-self-end bd-highlight" routerLink="/view-category/1">{{'check_latest_news'|translate |titlecase}}</div>
</div>
</div>

<div class=" col-lg-4 col-md-6 col-sm-10 mb-2">

    <div class="machine-cards d-flex flex-column bd-highlight ">
        <h3>{{'Health Library' | translate}}</h3>
        <hr>
        <p>{{'health_provides'| translate}}</p>
        <div class="try mt-auto align-self-end bd-highlight"routerLink="/health-library">{{'start_reading'|translate |titlecase}}</div>
        </div>
</div>


<div class=" col-lg-4 col-md-6 col-sm-10 mb-2">

    <div class="machine-cards d-flex flex-column bd-highlight ">
        <h3>{{'Ai Diagnosis' | translate}} <hr></h3>
        
        <p>{{'Ai_provide'| translate}}</p>
        <div class="try mt-auto align-self-end bd-highlight" routerLink="/ai-diagnosis">{{'try now'|translate |titlecase}}</div>
        </div>
</div>
</div>

  </section>
  
  
  <section class="articles">
      <h1 class="pt-4 " >{{'LATEST_BLOGS_ARTICLES'| translate}}</h1>

      <div class="d-flex row" >
<div *ngFor="let item of categorizedData.news | slice:0:3" class="col-sm-10 col-md-6 col-lg-4">

    
    <div class=" article-card" [routerLink]="['/single-article',item.id]">
        <div class="article-cover">

            <img class="mb-3 " [src]="item.image">
        </div>
<div class="icons d-flex">
    <div>

        <img src="../../../../assets/images/home/admin.svg" alt=""> admin
    </div>
    <div>

        <img src="../../../../assets/images/home/calendar.svg" alt=""> date
    </div>
    <div>

        <img src="../../../../assets/images/home/save.svg" alt=""> saved
    </div>
</div>

<h3 class="px-3">{{item.title}} </h3>
    </div>

    
</div>



      </div>
      
</section>

<section class="main-section">
    <h1>{{'News'| translate}}</h1>

    <div class="row">

        <div *ngFor="let item of categorizedData.news | slice:0:8"  class="col-sm-10 col-md-6 col-lg-3">
            <div class="news-card">
                <img class="mb-3 news-cover"  [src]="item.image">
                
<h3 class="p-2">{{item.title}} </h3>

<div class="d-flex news-info">
    <div class="me-3">
        floyd
    </div>
    <div>
        3 mins ago
    </div>
</div>
        
            </div>
            </div>
 
    </div>

</section>



 <h2 class="mt-4">Subscribe to our AI service package </h2>
<p>Choose the perfect plan for your business needs</p>
<section class="blog-area ptb-70" dir="ltr">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="subscribe basic">
                    <h2>{{'free'|translate}}</h2>
                    <p>{{'Best for personal use'| translate}}</p>
                    <div class="d-flex ">  
                        <h1>$0</h1>/ <span>{{'per month'| translate}}</span>

                    </div>
                    <br />

                    <p>
                        <mat-icon>done</mat-icon>
                        <span>Kanban Boards</span>
                    </p>
                    <p>
                        <mat-icon>done</mat-icon>
                        <span>Kanban Boards</span>
                    </p>  <p>
                        <mat-icon>done</mat-icon>
                        <span>Kanban Boards</span>
                    </p>    
                    

                    <div class="destinations-btn-box basic-btn">
                  

                        <button
                            mat-raised-button
                            class="default-btn"
                            (click)="subscribe('a')"
                        >
                            Subscribe Now
                        </button>
                    </div>
                </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="subscribe basic">
                <h2>{{'free'|translate}}</h2>
                <p>{{'Best for personal use'| translate}}</p>
                <div class="d-flex ">  
                    <h1>$0</h1>/ <span>{{'per month'| translate}}</span>

                </div>
                <br />

                <p>
                    <mat-icon>done</mat-icon>
                    <span>Kanban Boards</span>
                </p>
                <p>
                    <mat-icon>done</mat-icon>
                    <span>Kanban Boards</span>
                </p>  <p>
                    <mat-icon>done</mat-icon>
                    <span>Kanban Boards</span>
                </p>    
                

                <div class="destinations-btn-box basic-btn">
              

                    <button
                        mat-raised-button
                        class="default-btn"
                        (click)="subscribe('a')"
                    >
                        Subscribe Now
                    </button>
                </div>
            </div>
    </div>

        </div>
    </div>
</section>
