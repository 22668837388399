
<!-- <app-header-two></app-header-two> -->

<div class="main-div">
    <h3>Based on the symptoms you’ve provided and your medical profile, here are the most likely diagnoses, ranked by probability. For a more accurate result, please review your medical information.</h3>


    <div class="row justify-content-start">
        <div class="card  d-flex col-lg-5 col-md-10 me-4" *ngFor="let result of results" >
            <div class="chart position-relative">
                <div class="percentage-num">{{result.probability}}%</div>

                <div class="chart position-relative" style="z-index: 2">
                    <canvas
                        baseChart
                        [datasets]="doughnutChartDatasets"
                        [options]="doughnutChartOptions"
                        [legend]="true"
                        [type]="'doughnut'"
                    >
                    </canvas>
                    <!-- [labels]="doughnutChartLabels" -->
                </div>
            </div>
            <div>
                <p class="title">{{result.diagnosis}}</p>
                <p class="description">{{result.description}}</p>
<div class="know"  [routerLink]="['/know-more',result.disease_slug]">Know More</div>
            </div>
</div>
    </div>
</div>