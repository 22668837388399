import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { ValidationErrorsComponent } from '../../validation-errors/validation-errors.component';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GeneralButtonComponent } from '../../shared/components/buttons/general-button/general-button.component';
import { GeneralInputsAccessor } from '../../shared/classes/GeneralInputsAccessor';
@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ValidationErrorsComponent,
    GeneralButtonComponent,
  ],
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent extends GeneralInputsAccessor {
  @Input() label!: string;
  @Input() type!: string;
  @Input() placeholder!: string;
  @Input() icon!: string;
  @Input() isPassword = false;
  @Input() isSubmitted = false;
  @Input() errors!: ValidationErrors | null;
  @Input() mismatchError = false;
  @Input() isAuthInput = false;
  @Output() btnClicked = new EventEmitter<boolean>(false);
  @Input() isDisabled = false;

  @Input() showComment = false;
  showPassword = false;
  passwordIconSrc = 'assets/images/auth/icons/eye.svg';
  @ViewChild('passwordInput') passwordInput!: any;

  submitComment() {
    this.btnClicked.emit(true);
  }
}
