<div *ngIf="!loading && !errorMessage" class="d-flex" style="">
  <div >

<div class="example-stepper ">
  <mat-stepper
  [linear]="islinear"
  #stepper
    class=" ps-md-5 ps-lg-5"
    [orientation]="(stepperOrientation | async)!">
    <mat-step [stepControl]="firstFormGroup"  [label]="'symptom_selection' | translate">
      <div class="text"  class="top-section">
        <div class="talk-bubble tri-right round right-in container-fluid ml-4 web" >
          <div class="talktext">
       
                
              <h4 >{{'imSorryToHearThatYoureFeelingBad' | translate}}
              </h4>
              <div class="typing-animation">
            <h4 class="typing-text" >{{'whatSymptomsAreYouCurrentlyExperiencing' | translate}}</h4>
           
      </div>                                      
          </div>
          </div>
      </div>  
      
      <div [formGroup]="firstFormGroup"  >
    
        <div *ngFor="let question of step1Questions">

          <p class="title"> {{question.question}} <span class="error" *ngIf="question.id == 1">*</span>
          </p>
          <p>  ( {{'you_can_choose'|translate}} {{question.validation.max}}  {{'answers'| translate}} ) </p>
  
          <div class="container-fluid 
          ">
              <div class="row justify-content-start mb-3" *ngIf="question.id != 1">
                  <div class="card col-lg-6 col-md-12 d-flex me-1 mb-2"   *ngFor="let item of question.answers">
  <div > 
   
      <mat-checkbox [value]="item"  [disabled]="(selectedAnswers[question.id]?.length >= question.validation.max )&& !selectedAnswers[question.id].includes(item)" (change)="constructQuestionAnswer(question.id,$event.source.value, $event)">{{item}} </mat-checkbox>
  </div>

                  </div>


                  </div>
                  
                   <div *ngIf="question.id ==1"> 
                    <div class="row align" >
                      <div class="col-lg-6 col-md-12 align-self-start">
           
                  <mat-form-field class="input-container__input mt-2" appearance="outline">
                    <mat-chip-list #chipList>
                      <mat-chip   *ngFor="let symptom of selectedSymptoms" (keyup.enter)="selectSymptom($event)" [selectable]="true" [removable]="true" (removed)="removeSymptom(symptom)">
                        {{ symptom }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input
                      [placeholder]="'choose_symptoms' | translate"                        matInput
                        [formControl]="symptoms"
                        [matAutocomplete]="auto"
                        (keyup.enter)="selectSymptom($event)"
                        [(ngModel)]="input"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addSymptom($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSymptom($event)">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div class="error" *ngIf="errorMsg">{{errorMsg | translate | titlecase}}</div>
                  <div class="error" *ngIf="selectedSymptoms.length >10">{{'symptomsValidation' |translate}}</div>
                  
                
                  </div>
                  <div class=" col-lg-6 col-md-12 sevirty ">
                    <div *ngFor="let sevirtyItem of sevirtyList"  class="card mt-2 row">
                      <div class="col-lg-3 col-md-12">
                        {{sevirtyItem}} 
                      </div> 
                      <div class=" col-lg-8 col-md-12">
                        <mat-radio-group
                        color="primary"
                        value="1"
                    >
                        <mat-radio-button value="0"
                        class="me-2"
                            >{{'mild'|translate}}</mat-radio-button
                        >
                        <mat-radio-button value="1"  class="me-2"
                            >{{'moderate'|translate}}</mat-radio-button
                        >
                        <mat-radio-button value="2"  class="me-2"
                        >{{'severe'|translate}}</mat-radio-button
                    >
                    </mat-radio-group>
                   </div> 
                    
                </div> 
              </div>
                  </div>
                  </div> 
                  <!-- <div *ngIf="question.id == 1">
                    <div class="row align">
                      <div class="col-lg-6 col-md-12 align-self-start">
                  
                        <mat-form-field class="input-container__input mt-2" appearance="outline">
                          <mat-label>{{ 'choose_symptoms' | translate }}</mat-label>
                          <mat-select   (selectionChange)="onSymptomChange($event)"
                          [formControl]="symptoms" [(ngModel)]="selectedSymptoms"  multiple>
                            <mat-option  [disabled]="selectedSymptoms.length >9"  *ngFor="let option of filteredOptions | async" [value]="option">
                              {{ option }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="error" *ngIf="errorMsg">{{ errorMsg | titlecase }}</div>
                  
                      </div>
                      <div class="col-lg-6 col-md-12 sevirty">
                        <div *ngFor="let sevirtyItem of sevirtyList" class="card mt-2 row">
                          <div class="col-lg-3 col-md-12">
                            {{ sevirtyItem }}
                          </div>
                          <div class="col-lg-8 col-md-12">
                            <mat-radio-group color="primary" value="1">
                              <mat-radio-button value="0" class="me-2">
                                {{ 'mild' | translate }}
                              </mat-radio-button>
                              <mat-radio-button value="1" class="me-2">
                                {{ 'moderate' | translate }}
                              </mat-radio-button>
                              <mat-radio-button value="2" class="me-2">
                                {{ 'severe' | translate }}
                              </mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  
                  </div>
        </div>
        <div>
          <div class="bottom-section">
          <div class="bottom-mobile">
            <div *ngIf="gender ==1" >
                <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
            </div>
            <div *ngIf="gender ==2"  >
                <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
            </div>
          </div>
            <div class="destinations-btn-box">
              <div class="talk-bubble tri-right round right-in container-fluid ml-4 mobile" >
                <div class="talktext">
             
                      
                  <h4 >{{'imSorryToHearThatYoureFeelingBad' | translate}}
                  </h4>
                  <div class="typing-animation">
                    <h4 class="typing-text" >{{'whatSymptomsAreYouCurrentlyExperiencing' | translate}}</h4>
                   
              </div>                                      
                </div>
                </div>
                <button
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                 (click)=" goForward(stepper) ">
                    {{ "Next" | translate }}
                </button>
     
            </div>
            </div>
          <!-- <button mat-button >Next</button> -->
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" [label]="'symptom_timeline' | translate"[optional]="false">
      <div class="text margin-top"  >
        <div class="talk-bubble tri-right round right-in container-fluid ml-4 web" >
          <div class="talktext">
       
                
              <h4 >{{'toGetAMoreAccurateDiagnosis' | translate}}
              </h4>
              <div class="typing-animation">
            <!-- <h4 class="typing-text" >{{'whatSymptomsAreYouCurrentlyExperiencing' | translate}}</h4> -->
           
      </div>                                      
          </div>
          </div>
      </div>  
      
      <form [formGroup]="secondFormGroup" class="row">
          <div class="col-lg-5 col-sm-10 margin-top" *ngFor="let question of step2Questions">

            <p class="title" > {{question.question}}<span *ngIf="!question.validation.required">({{'optional'|translate}}  )</span>
            </p>
            <p *ngIf="question.validation.max >1">   ( {{'you_can_choose'|translate}} {{question.validation.max}} {{'answers'|translate}}  ) </p>
    
            <!-- <div class="container-fluid 
            ">
                <div class="row justify-content-start mb-3">
                    <div class="card col-lg-4 col-md-12 d-flex me-1 mb-2"   *ngFor="let item of question.answers">
    <div>
    
        <mat-checkbox [value]="item"  [disabled]="(selectedAnswers[question.id]?.length >= question.validation.max )&& !selectedAnswers[question.id].includes(item)" (change)="constructQuestionAnswer(question.id,$event.source.value, $event)">{{item}} </mat-checkbox>
    </div>
    
                    </div> 
                    </div>

                    </div> -->
                    <mat-form-field class="input-container__input mt-2"  appearance="outline">
                      <!-- multiple="true" -->
                      <mat-select (selectionChange)="constructQuestionAnswer(question.id,$event.source.value, $event)"
                  
                   
                    
                    >
                      <mat-option
                        *ngFor="let item of question.answers"
                        [value]="item"
                      >
                        {{ item }}
                      </mat-option>
                    </mat-select>
                    </mat-form-field>
          </div>
          <div class="col-lg-5 col-sm-10" *ngFor="let question of step3Questions">
<div style="min-height:5rem">

  <p class="title mt-3" > {{question.question}}<span *ngIf="!question.validation.required">({{'optional'|translate}}  )</span>
  </p>
  <div *ngIf="question.validation.max >1">   ( {{'you_can_choose'|translate}} {{question.validation.max}} {{'answers'|translate}}  ) </div>

</div>
            <!-- <div class="container-fluid 
            ">
                <div class="row justify-content-start mb-3">
                    <div class="card col-lg-4 col-md-12 d-flex me-1 mb-2"   *ngFor="let item of question.answers">
    <div>
    
        <mat-checkbox [value]="item"  [disabled]="(selectedAnswers[question.id]?.length >= question.validation.max )&& !selectedAnswers[question.id].includes(item)" (change)="constructQuestionAnswer(question.id,$event.source.value, $event)">{{item}} </mat-checkbox>
    </div>
    
                    </div> 
                    </div>

                    </div> -->
                    <mat-form-field class="input-container__input mt-2" appearance="outline">
                      <!-- multiple="true" -->
                      <mat-select
                   [multiple]="question.validation.max >1"
                   (selectionChange)="constructQuestionAnswer(question.id,$event.source.value, $event) "
                    
                    >
                      <mat-option
                        *ngFor="let item of question.answers"
                        [value]="item"
                      >
                        {{ item }}
                      </mat-option>
                    </mat-select>
                    </mat-form-field>
          </div>  
            <div class="destinations-btn-box">
                <button
                matStepperPrevious
                    mat-flat-button
                    class="discard"
                    >
                    <!-- [routerLink]="['/']" -->
                    {{ "back" | translate }}
                </button>
                <button
                (click)="sendResults()"
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                    
                >
                    {{ "Next" | translate }}
                </button>
     
            </div>
      </form>
    </mat-step>
 </mat-stepper>
</div>
</div>
<div class="bottom">
  <div *ngIf="gender ==1" >
      <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
  </div>
  <div *ngIf="gender ==2"  >
      <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
  </div>
</div>
</div>

<!-- <div *ngIf="loading" class="spinner m-auto">
  <div class="m-auto">

    <mat-spinner color="#12B6DF"></mat-spinner>
  </div>
  <div>{{'ai_reviews'|translate}}.</div>
</div> -->
<div *ngIf="errorMessage" class="errorMsg">{{errorMessage}}</div>

<div *ngIf="loading" class="d-flex m-auto loading">
  <div *ngIf="loading" class="mobile">
    <div *ngIf="gender ==1" class="">
        <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
    </div>
    <div *ngIf="gender ==2"  >
        <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
    </div>
  </div>
  <div class="talk-bubble tri-right round right-in">
      <div class="talktext">
          <div  class="dots-container">
              <div>{{'thinking'| translate | titlecase}}</div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
            
       
      </div> 
    </div>
  <div class="bottom">
    <div *ngIf="gender ==1" class="">
        <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
    </div>
    <div *ngIf="gender ==2"  >
        <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
    </div>
</div>

</div>