import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})
export class ShareLinkComponent implements OnInit {

  constructor(        private modal: ModalService,
  ) { }

  ngOnInit(): void {
  }
  currentUrl: string = window.location.href;  // Get current page URL

  facebookShareUrl: string = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}&amp;src=sdkpreparse"`;

  emailShareUrl: string = `mailto:?subject=Check this out&body=Here is a link I wanted to share with you: ${encodeURIComponent(this.currentUrl)}`;
  twitterShareUrl: string = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}&text=Check this out!`;
  linkedinShareUrl: string = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(this.currentUrl)}`;
  // pinterestShareUrl: string = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(this.currentUrl)}&media=${encodeURIComponent(this.imageUrl)}&description=${encodeURIComponent(this.description)}`;

  copyLink() {
    navigator.clipboard.writeText(this.currentUrl)
      .then(() => {
        this.modal.snackMessage("Link Copied");

      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  }

}
