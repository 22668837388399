import { MAT_DATE_FORMATS } from '@angular/material/core';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_FORMATS_PROVIDER = {
  provide: MAT_DATE_FORMATS,
  useValue: DATE_FORMATS,
};
