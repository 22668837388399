import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
    selector: "app-confirm-dialog",
    templateUrl: "./confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
    standalone: true,
})
export class ConfirmDialogComponent {
    constructor(public dialog: MatDialog, private router: Router) {}
    route() {
        this.dialog.closeAll();
        this.router.navigate(["login"]);
    }
}
