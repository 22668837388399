import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent {
  @Input() maxStars = 5; // Total number of stars
  @Output() ratingChange = new EventEmitter<number>(); // Emit rating change
  currentRating = 0; // Current selected rating
  hoverIndex = 0; // Current hover index

  get stars(): number[] {
    return Array(this.maxStars).fill(0);
  }

  setRating(rating: number): void {
    this.currentRating = rating;
    this.ratingChange.emit(this.currentRating);
  }

  setHover(rating: number): void {
    this.hoverIndex = rating;
  }

  clearHover(): void {
    this.hoverIndex = 0;
  }
}
