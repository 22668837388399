import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-ask-ai',
  templateUrl: './ask-ai.component.html',
  styleUrls: ['./ask-ai.component.scss']
})
export class AskAiComponent implements OnInit {
gender:number
inputValue: string = '';
response ='';
constructor(private http: HttpClient,
  private apiservice:ApiService,
) {}
  
  ngOnInit(): void {
  }
  sendData() {
    const data = { user_message: this.inputValue };
    this.apiservice.askAi(data)
      .subscribe((res:any)=>{
        this.response = res.answer
      })
  }
}
