import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-discover-questions-list",
    templateUrl: "./discover-questions-list.component.html",
    styleUrls: ["./discover-questions-list.component.scss"],
})
export class DiscoverQuestionsListComponent implements OnInit {
    questions: any[] = [];
    constructor(private apiService: ApiService) {
        this.fillQuestions();
        
    }

    ngOnInit(): void {}

    fillQuestions() {
        this.apiService.getQuestions().subscribe((res) => {
            console.log(res);
            this.questions = res['data']['items']
        });
    }

    
}
