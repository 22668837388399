import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  @Input('categories') categories : any[] = []

  constructor(private router : Router,private route : ActivatedRoute) { }

  ngOnInit(): void {
  }

  navigateToViewDiseasesByCategory(obj){
    this.router.navigate(["/diseases", { id: obj.id }], {
        relativeTo: this.route,
    });
}

}
