<section class="feedback-area ptb-100"> 
    <div class="container">
        <div class="section-title">
            <h2>{{'Health Prescriptions' | translate}} </h2>
        </div>
        <!-- <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <p>
                    11
                </p>

                <div class="info" >
                    <h3>Chocolate Cake</h3>
                    <img
                        src="assets/img/Group 51443.png"
                        class="shadow rounded-circle"
                        alt="image"
                    />
                </div>
            </div>

            
        </div> -->

        <div class="row">
            <div
                class="col-lg-4 col-md-4 col-sm-6"
                style="height: 250px;"
                *ngFor="let prescription of prescriptions"
            >
                <div
                    class="single-categories-listing-item bg"
                    [style.background-image]="'url(' + prescription.image?.url + ')'"
                >
                <div class="icon"></div>
                    <h3>{{ prescription.title }}</h3>
                    <span [innerHtml]="prescription.text"></span>

                    <!-- <a (click)="navigateToViewNew(medication)" class="learn-more-btn"
                        >Learn More <i class="bx bx-chevron-right"></i
                    ></a> -->
                </div>
            </div>
        </div>
    </div>
</section>
