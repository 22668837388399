import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService,
    private router: Router,
    private translate:TranslateService,
    private localStorageService: LocalStorageService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.localStorageService.removeAll()
            this.router.navigate(['/login']);

          } else if (error.status === 403) {

            this.router.navigate(['/dashboard']);

          } else if (error.status === 404) {
            this.notificationService.showErrorNotification(error.error.message??this.translate.instant("went_wrong"));
          } else {
            this.notificationService.showErrorNotification(error.error.message ??this.translate.instant("went_wrong"));
          }
        }

        return throwError(error);
      })
    );
  }
}
