<!-- <div class="example-action-buttons">
    <button mat-button (click)="accordion.openAll()">Expand All</button>
    <button mat-button (click)="accordion.closeAll()">Collapse All</button>
  </div> -->
  <!-- <mat-accordion class="example-headers-align" multi> -->
<div class="body">
 <h3>{{'diagnosis_history' | translate}}</h3>
  <div class="mb-2 " *ngFor="let diagnose of history?.items" >

      <!-- <mat-expansion-panel> -->
        <!-- <mat-expansion-panel-header> -->
          <!-- <mat-panel-title> -->
                <div class="d-block">

                    <div class=" justify-content-start">
                        <div class="d-flex dia-card mb-2" *ngFor="let result of diagnose.details" >
                            <div class="chart position-relative">
                                <div class="percentage-num">{{result.probability}}%</div>
                
                                <div class="chart position-relative" style="z-index: 2">
                                    <canvas
                                        baseChart
                                        [datasets]="doughnutChartDatasets"
                                        [options]="doughnutChartOptions"
                                        [legend]="true"
                                        [type]="'doughnut'"
                                
                                        [data]="getChartData(result)"
                                        
                                    >
                                    </canvas>
                                </div>
                            </div>
                            <div>
                                <p class="title">{{result.diagnosis}}</p>
                                <p class="description">{{result.description}}</p>
                <div class="know"  [routerLink]="['/know-more',result.disease_slug]">Know More</div>
                            </div>
                </div>
                    </div>                <br>
                </div>
          <!-- </mat-panel-title> -->
       
        <!-- </mat-expansion-panel-header> -->
    
        <!-- <mat-form-field>
          <mat-label>Extra info</mat-label>
          <input matInput [matDatepicker]="picker" (focus)="picker.open()" readonly>
        </mat-form-field>
        <mat-datepicker #picker></mat-datepicker> -->
      <!-- </mat-expansion-panel> -->
  </div>
  <!-- </mat-accordion> -->
  </div>