import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, inject, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import { MatIconRegistry } from "@angular/material/icon";

import {
    DOCUMENT,
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter, map, shareReplay } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { SwitchLanguageService } from "./services/switch-language.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
// declare let $: any;
import * as $ from "jquery";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
  @ViewChild("arRef") arRef: ElementRef<HTMLElement>;
  @Output() notifyRouterChange: EventEmitter<any> = new EventEmitter();

    location: any;
    routerSubscription: any;
    hasNav = true;
    lang: string = "";
    htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
  dir = localStorage.getItem('dir') || 'ltr';
  isLg: boolean = false;
  private breakpointObserver = inject(BreakpointObserver);
  menuItems = [{text:'Home',path:'/',    icon: 'home',
  },{text:'Health Library',path:'/health-library',    icon: 'health-library',
  },{
    text:'diagnosis_history',path:'/diagnosis-history', icon:'diagnosis-history'
  }];
  userName: string = '';
  userLogo: string | null = null;
  notifications: any;
  isLoggedIn: boolean;

  constructor(
    private translateService: TranslateService,
    // private localStorageService: LocalStorageService,

    // private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private switchLang: SwitchLanguageService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private renderer: Renderer2,

    // private authService: AuthService,
    // private notificationService: NotificationManagementService,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.router.events.subscribe((res) => {
      console.log("res");
      this.notifyRouterChange.emit();
  });
  this.matIconRegistry.addSvgIcon(
      "search",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/search-svgrepo-com.svg"
      )
  );
  this.lang = this.switchLang.getCurrentLang();
  if (localStorage.getItem("token")) this.isLoggedIn = true;
  else this.isLoggedIn = false;
  //   this.matIconRegistry.addSvgIcon(
  //     "search",
  //     this.domSanitizer.bypassSecurityTrustResourceUrl(
  //         "assets/img/search-svgrepo-com.svg"
  //     )
  // );
  
  }
//   ngOnInit() {
    // // this.addRolesToNavbar();
    // this.authService.data$.subscribe((newData: any) => {
    //   this.userLogo = newData.logo;
    //   this.userName = newData.user;
    // });
//   }
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 992px)')
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  setLang() {
    if (this.lang === 'ar'){
      this.translateService.setDefaultLang('en');
      this.translateService.use('en');
      this.htmlTag.lang = 'en';

      this.switchLang.ChangeLanguage('en');
      this.lang = this.switchLang.getCurrentLang();
      this.changeDetectorRef.detectChanges();
      window.location.reload();

    }else{
      this.translateService.setDefaultLang('ar');
      this.translateService.use('ar');
      this.htmlTag.lang = 'ar';
      this.switchLang.ChangeLanguage('ar');
      this.lang = this.switchLang.getCurrentLang();
      this.changeDetectorRef.detectChanges();
      window.location.reload();

    }
    this.dir = this.htmlTag.dir = this.lang === 'ar' ? 'rtl' : 'ltr';
    localStorage.setItem('dir', this.dir);
  }

  showItem(item: any) {
    const roles = localStorage.getItem('roles');
    if (roles?.includes(item.view) || item.view == true) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    // this.authService.logout().subscribe((res: any) => {});
    // this.localStorageService.removeAll();
    this.router.navigate(['/auth/login']);
  }

//   getNotifications() {
//     this.notificationService.getUserNotifications().subscribe((res) => {
//       this.notifications = res;
//     });
//   }


    ngOnInit() {
        this.recallJsFuntions();
        this.checkCurrentLang();
        this.router.events.subscribe((val) => {
          if (val instanceof NavigationStart) {
            if (val.url.includes('/login') || val.url.includes('/register'))
              this.hasNav = false;
            else this.hasNav = true;
          }
        });
  
    }
    reload(tabName) {
      // this.router.navigate(['/'])
      // window.location.reload()
      this.router.navigate(["/"])
      console.log(this.router.url);
      // if (tabName == "logo" || (this.router.url == "/" && tabName == "home"))
      //     window.location.reload();
      // else if (tabName == "home") ;
  }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/main.js");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    checkCurrentLang() {
        // this.translate.use(
        //     localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar"
        // );
        // if (this.translate.currentLang == "ar")
        //     document.documentElement.dir = "rtl";
        // else document.documentElement.dir = "ltr"

        this.switchLang.ChangeLanguage(
            localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
        );
    }


}
