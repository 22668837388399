<!-- <app-header-two></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container pt-3">
        <div class="row">
            <div class="col-lg-1 sort-by top-space-responsive">
                <span>Sort By :</span>
            </div>
            <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>Newest</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>Category</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>Top Viewed</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>Popular</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-2"></div>
        </div>

        <br />
        <br />

        <div class="row">
            <div
                class="col-lg-4 col-md-6"
                *ngFor="let initiative of initiatives"
            >
                <div class="single-blog-post">
                    <div class="post-image">
                        <a
                            routerLink="/blog-details-one"
                            style="text-decoration: underline"
                        >
                            <img [src]="initiative.image.url" alt="image" />
                        </a>
                        <div class="date">
                            <span>{{ initiative.published_at }}</span>
                        </div>
                    </div>

                    <div class="post-content text-center">
                        <span class="category">{{ initiative.title }}</span>
                        <h3>
                            <a>{{ initiative.desc }}</a>
                        </h3>
                        <a
                            class="details-btn"
                            (click)="navigateToViewinitiatives(initiative)"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
