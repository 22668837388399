import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-general-button',
  standalone: true,
  templateUrl: './general-button.component.html',
  styleUrls: ['./general-button.component.scss'],
  imports: [RouterModule, CommonModule],
})
export class GeneralButtonComponent {
  @Input() route!: string | any;
  @Input() title!: string;
  @Input() disable: boolean = false;

  @Input() hasAddRole: boolean = true;
  @Output() btnClicked = new EventEmitter<boolean>(false);

  buttonClicked() {
    this.btnClicked.emit(true);
  }
}
