import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-doctors-list",
    templateUrl: "./doctors-list.component.html",
    styleUrls: ["./doctors-list.component.scss"],
})
export class DoctorsListComponent implements OnInit {
    lang: string = "";
    doctors: any[] = [];
    constructor(
        private translateService: TranslateService,
        private apiService: ApiService,
        private router : Router
    ) {
        this.lang = localStorage.getItem("lang");
        this.translateService.onLangChange.subscribe((lang) => {
            this.lang = lang["lang"];
        });

        this.fillDoctors();
    }

    ngOnInit(): void {}

    fillDoctors() {
        this.apiService.getDoctors().subscribe((res) => {
            console.log(res);
            this.doctors = res["data"]["items"];
        });
    }
    
    navigateToDoctorProfile(doctor){

        this.router.navigate(['/doctor-profile', {id : doctor.field_id}])
    }
}
