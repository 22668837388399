
    <div class="container-fluid">

<div class="body  row">
 <h3>{{'diagnosis_history' | translate}}</h3>
  <div class="mb-2 col-lg-6 col-sm-12" *ngFor="let diagnose of history?.items" >


<div class=" new-card">
  <div class="date">{{(diagnose?.created_at | date : "MMM dd, yyyy 'at' hh:mm a")??'21 nov 2024'}}</div>
  <div class=" mb-2" *ngFor="let result of diagnose?.details" >

  <div class="d-flex">
    <div>       
                               <p class="title">{{result?.diagnosis}}</p>
    </div>
<div>
  <div class="mx-3 percentage">{{result?.probability}}%</div>
</div>
</div>
<p class="description">{{result.description}}</p>
  
</div>
<div class="know" (click)="saveId(diagnose.id)"  [routerLink]="['/results']">{{'know_more'|translate}}</div>

  </div>
  </div>
</div>
