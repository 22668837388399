<div mat-dialog-content>
    <mat-calendar [(selected)]="selected"></mat-calendar>

    <mat-card class="time-card">
        <div class="row">
           
            <div class="col-lg-3">
                <span class="time-value active">3:00 pm</span>
            </div>
            <div class="col-lg-3">
                <span class="time-value">3:00 pm</span>
            </div>
            <div class="col-lg-3">
                <span class="time-value">3:00 pm</span>
            </div>
            <div class="col-lg-3">
                <span class="time-value">3:00 pm</span>
            </div>

             
            <div class="col-lg-3">
                <span class="time-value active">3:00 pm</span>
            </div>
            <div class="col-lg-3">
                <span class="time-value">3:00 pm</span>
            </div>
            <div class="col-lg-3">
                <span class="time-value">3:00 pm</span>
            </div>
            <div class="col-lg-3">
                <span class="time-value">3:00 pm</span>
            </div>
           
        </div>

        <div class="destinations-btn-box">
            <button
                mat-raised-button
                color="primary"
                class="default-btn"
            >
                Reschedule
            </button>
        </div>
    </mat-card>
</div>

<!-- <div mat-dialog-actions>
    <div class="row-flex" style="margin-bottom: 5px">
        <button mat-button (click)="confirm()">OK</button>
        <button mat-button (click)="cancel()" *ngIf="data.cancelButton">
            Cancel
        </button>
        <div class="flex-space"></div>
    </div>
</div> -->
