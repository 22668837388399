<div class="body">
<div *ngIf="response == ''">

    <div *ngIf="gender===1">
        <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
    </div>
    <div *ngIf="gender===2">
        <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
    </div>

    <h1>Ask <span class="blue-text">{{gender === 1?'AVA':'Aiden'}}</span></h1>
</div>
<div class="res d-flex" *ngIf="response != ''">

    <div class="circle" *ngIf="gender===1">
        <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
    </div>
    <div *ngIf="gender===2">
        <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
    </div>    {{this.response}}
</div>

    <mat-form-field appearance="outline" class="wide-input">
        <input [(ngModel)]="inputValue" matInput [placeholder]="'Ask ' + (gender === 1 ? 'AVA' : 'Aiden')">
        <mat-icon (click)="sendData()" matSuffix>search</mat-icon>
      </mat-form-field>
      
</div>