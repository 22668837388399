<header class="header-area mb-3">
    <div class="navbar-area navbar-style-two">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo col-lg-2">
                        <a (click)="reload('home')">
                            <img
                                src="assets/img/logo.png"
                                alt="logo"
                                style="height: 70px; width: 100px"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav">
            <div
                class="container-fluid col-lg-12 d-flex"
                style="justify-content: space-between"
            >
                <nav
                    class="navbar navbar-expand-md navbar-light"

                >
                    <a class="navbar-brand" (click)="reload('logo')">
                        <img
                            src="assets/img/logo.png"
                            alt="logo"
                            class="logo-img"
                            style="
                                height: 70px;
                                padding-right: 110px;
                                padding-left: 110px;
                            "
                        />
                    </a>

                    <ul class="navbar-nav not-collapse">
                        <li class="nav-item">
                            <a
                                (click)="reload('home')"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "Home" | translate }}
                            </a>
                        </li>

                        <!-- <li class="nav-item">
                            <a
                                routerLink="/news-list"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "News" | translate }}</a
                            >
                        </li> -->

                        <li class="nav-item">
                            <a
                                [routerLink]="['/news-list']"
                                [queryParams]="{ categoryID: '6' }"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >
                                <!-- {{ "Health Prescriptions" | translate }} -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                [routerLink]="['/news-list']"
                                [queryParams]="{ categoryID: 'null' }"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >
                                <!-- {{ "Health Library" | translate }} -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                [routerLink]="['/news-list']"
                                [queryParams]="{ categoryID: '2' }"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >
                                <!-- {{ "Medications" | translate }} -->
                            </a>
                        </li>

                        <!-- <li class="nav-item">
                            <a
                                [routerLink]="['/news-list']"
                                [queryParams]="{ categoryID: '4' }"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "Diseases" | translate }}
                            </a>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a
                                [routerLink]="['/news-list']"
                                [queryParams]="{ categoryID: '10' }"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "Public Health" | translate }}
                            </a>
                        </li> -->

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >{{ "Discover" | translate }}
                                <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        [routerLink]="['/news-list']"
                                        [queryParams]="{ categoryID: '4' }"
                                        class="nav-link"
                                        style="
                                            text-decoration: underline;
                                            margin-left: -14px;
                                        "
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >{{
                                            "Suggested Questions And Answers"
                                                | translate
                                        }}</a
                                    >

                                    <a
                                        [routerLink]="['/about-us']"
                                        class="nav-link"
                                        style="
                                            text-decoration: underline;
                                            margin-left: -14px;
                                        "
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >{{ "About Us" | translate }}</a
                                    >

                                    <a
                                        [routerLink]="['/contact-us']"
                                        class="nav-link"
                                        style="
                                            text-decoration: underline;
                                            margin-left: -14px;
                                        "
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >{{
                                            "Contact US"
                                                | translate
                                        }}</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" *ngIf="!isLoggedIn">
                            <a
                                routerLink="/login"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "Login" | translate }}
                            </a>
                        </li>

                        <li class="nav-item" *ngIf="!isLoggedIn">
                            <a
                                routerLink="/register"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "Register" | translate }}
                            </a>
                        </li>

                        <li class="nav-item" *ngIf="isLoggedIn">
                            <a
                                (click)="signOut()"
                                style="cursor: pointer"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "Logout" | translate }}
                            </a>
                        </li>
                    </ul>
                    <div
                        class="collapse navbar-collapse mean-menu d-flex justify"
                        style="justify-content: end"
                    >
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a
                                    routerLink="/"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Home
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    routerLink="/news-list"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >News</a
                                >
                            </li>

                            <li class="nav-item">
                                <a
                                    [routerLink]="['/news-list']"
                                    [queryParams]="{ categoryID: '6' }"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{ "Health Prescriptions" | translate }}
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a
                                    routerLink="/health-library"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{ "Health Library" | translate }}
                                </a>
                            </li> -->
                            <li class="nav-item">
                                <a
                                    [routerLink]="['/news-list']"
                                    [queryParams]="{ categoryID: '2' }"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{ "Medications" | translate }}
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    [routerLink]="['/news-list']"
                                    [queryParams]="{ categoryID: '4' }"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{ "Diseases" | translate }}
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    [routerLink]="['/news-list']"
                                    [queryParams]="{ categoryID: '10' }"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{ "Public Health" | translate }}
                                </a>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Discover <i class="bx bx-chevron-down"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/discover-questions"
                                            class="nav-link"
                                            style="text-decoration: underline"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Suggested Questions And Answers</a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div class="others-option col-lg-2 my-2 float-right">
                            <div class="d-flex align-items-center">
                                <div class="option-item" routerLink="/patient-profile">
                                    <img
                                        src="../../../../assets/images/Path 105744.svg"
                                        class="user-avatar"
                                        style="cursor: pointer; width: 15px"
                                    />
                                </div>
                                <div class="option-item">
                                    <div class="cart-btn">
                                        <span
                                            class="lang"
                                            style="padding-left: 4px"
                                            *ngIf="lang == 'en'"
                                            (click)="changeLang('ar')"
                                            >EN</span
                                        >
                                        <span
                                            class="lang"
                                            style="padding-left: 4px"
                                            *ngIf="lang == 'ar'"
                                            (click)="changeLang('en')"
                                            >AR</span
                                        >
                                    </div>
                                </div>
                                <div class="option-item">
                                    <div class="search-btn-box">
                                        <button
                                            mat-icon-button
                                            class="search-btn"
                                        >
                                            <mat-icon
                                                svgIcon="search"
                                            ></mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    *ngIf="isLoggedIn"
                                    class="option-item"
                                
                                    routerLink="/patient-profile"
                                    
                                    >
                                    
                                    <!-- style="border: red solid 1px;" -->
                                    <img
                                    routerLink="/patient-profile"
                                    (click)="routeToProfile()"

                                        src="assets/img/profile-user-svgrepo-com.svg"
                                        class="user-avatar"
                                        style="cursor: pointer"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
