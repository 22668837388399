import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { ModalService } from "../../../services/modal.service";

@Component({
    selector: "app-contact-us",
    templateUrl: "./contact-us.component.html",
    styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
    fileName: string = "";
    file: File;
    medicalFields: any[] = [];
    user: any = {};
    countries: any[] = [];
    filteredCountries;
    country : string = '';
    constructor(
        private apiService: ApiService,
        private modal: ModalService,
        private router: Router
    ) {}

    ngOnInit(): void {
  
    }

    onChange(event) {
        this.file = event.target.files[0];
        this.fileName = event.target.files[0].name;
    }

    setFieldID(e) {
        console.log(e);
        this.user.country_id = e.value;
    }

    fillMedicalFields() {
        this.apiService.getMedicalFields().subscribe((res) => {
            if (!res) return;
            this.medicalFields = res["data"];
        });
    }

    postQuestion() {
        // if (!this.file || !this.file.name) {
        //     this.modal.openValidationDialog(
        //         "warning",
        //         "Error",
        //         "Please upload file or photo"
        //     );
        //     return;
        // }
        if (
            !this.user.name ||
            !this.user.email ||
            !this.user.title ||
            !this.user.country_id ||
            !this.user.message ||
            !this.user.phone
        ) {
            this.modal.openValidationDialog(
                "warning",
                "Error",
                "Please fill required fields"
            );
            return;
        }
        let files: any[] = [];
        files.push(this.file);
        this.user.files = files;
        console.log(this.user);
        this.apiService.sendMessage(this.user).subscribe(
            (res) => {
                if (res["status"] == false) {
                    this.modal.snackMessage(res["error"]);
                    return;
                } else {
                    this.router.navigate(["/ask-success"]);
                }
            },
            (error) => {
                this.router.navigate(["/ask-success"]);
                this.modal.snackMessage(error["error"].error);
                return;
            }
        );
    }

    fillCountries() {
        this.apiService.getCountries().subscribe((res) => {
            console.log(res);
            this.countries = res["data"];
            this.filteredCountries = this.countries.slice();
        });
    }
}
