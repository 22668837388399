<div class="right" (click)="cancel()"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M15 2.8125C8.27988 2.8125 2.8125 8.27988 2.8125 15C2.8125 21.7201 8.27988 27.1875 15 27.1875C21.7201 27.1875 27.1875 21.7201 27.1875 15C27.1875 8.27988 21.7201 2.8125 15 2.8125ZM19.4127 18.0873C19.5034 18.1735 19.576 18.277 19.6261 18.3916C19.6762 18.5063 19.7028 18.6298 19.7044 18.755C19.706 18.8801 19.6825 19.0043 19.6354 19.1202C19.5882 19.2361 19.5184 19.3414 19.4299 19.4299C19.3414 19.5184 19.2361 19.5882 19.1202 19.6354C19.0043 19.6825 18.8801 19.706 18.755 19.7044C18.6298 19.7028 18.5063 19.6762 18.3916 19.6261C18.277 19.576 18.1735 19.5034 18.0873 19.4127L15 16.326L11.9127 19.4127C11.7354 19.5811 11.4994 19.6736 11.255 19.6705C11.0105 19.6673 10.7769 19.5688 10.6041 19.3959C10.4312 19.2231 10.3327 18.9895 10.3295 18.745C10.3264 18.5006 10.4189 18.2646 10.5873 18.0873L13.674 15L10.5873 11.9127C10.4189 11.7354 10.3264 11.4994 10.3295 11.255C10.3327 11.0105 10.4312 10.7769 10.6041 10.6041C10.7769 10.4312 11.0105 10.3327 11.255 10.3295C11.4994 10.3264 11.7354 10.4189 11.9127 10.5873L15 13.674L18.0873 10.5873C18.2646 10.4189 18.5006 10.3264 18.745 10.3295C18.9895 10.3327 19.2231 10.4312 19.3959 10.6041C19.5688 10.7769 19.6673 11.0105 19.6705 11.255C19.6736 11.4994 19.5811 11.7354 19.4127 11.9127L16.326 15L19.4127 18.0873Z" fill="#0A0A0A"/>
  </svg></div>


<h3>

    {{"limited_feature"| translate}}

</h3>
<div  class="center" >

    <button routerLink="/subscribe">{{'Subscribe Now'|translate}}</button>
</div>
