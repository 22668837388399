<!-- <app-header-two></app-header-two> -->

<div class="wrapper ">
  <div class=" mb-3 talk-bubble tri-right round right-in container-fluid ml-4">
    <div class="talktext">
      <h3>{{'detailedDiagnosisInfo' | translate}} {{diseaseInfo?.name}}:</h3>
      </div>
      </div>

      <div class=" mb-3 talk-bubble-mobile container-fluid ml-4">
        <div class="">
          <h2>{{'detailedDiagnosisInfo' | translate}} {{diseaseInfo?.name}}:</h2>
          </div>
          </div>
      <div  class="d-flex reverse">
      <div class="scroll" style="">

      <div>
        
    <h2 class="ms-3">
        {{diseaseInfo?.name}} 
    </h2>
    <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/definition.svg"> {{'Definition & Overview'| translate}}</div>

        <div  class="card"> {{diseaseInfo?.definition_and_overview}}
        </div>
    </section>
    <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/symptoms.svg"> {{'Symptoms'| translate}} </div>

        <div  class="card">
               <!-- Check if the value is an array -->
          <ul >
            <li *ngFor="let item of diseaseInfo?.symptoms">
              {{ item }}
            </li>
          </ul>
        </div>
    </section>
    <div class="row">
        <section class=" col-md-12 col-lg-6">
            <div class="title"> <img src="./../../../../assets/images/know-more/causes.svg"> {{'causes'| translate}} </div>
    
            <div  class="card"> 
                <ul >
                    <li *ngFor="let item of diseaseInfo?.causes">
                      {{ item }}
                    </li>
                  </ul>
            </div>
        </section>
        <section class="col-lg-6 col-md-12">
            <div class="title"> <img src="./../../../../assets/images/know-more/prognosis.svg"> {{'prognosis'| translate}} </div>
    
            <div  class="card">
                <ul >
                    <li *ngFor="let item of diseaseInfo?.prognosis">
                      {{ item }}
                    </li>
                  </ul>
            </div>
        </section>
    </div>


    <div class="row">
        <section class="col-lg-6 col-md-12">
            <div class="title"> <img src="./../../../../assets/images/know-more/diagnosis.svg"> {{'diagnosis'|translate}} </div>
    
            <div  class="card">  {{diseaseInfo?.diagonsis??'-'}}
            </div>
        </section>
        <section class="col-lg-6 col-md-12">
            <div class="title"> <img src="./../../../../assets/images/know-more/prevention.svg"> {{'prevention'| translate}} </div>
    
            <div  class="card"> 
                <ul >
                    <li *ngFor="let item of diseaseInfo?.prevention">
                      {{ item }}
                    </li>
                  </ul>
            </div>
        </section>
    </div>

    <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/treatment.svg"> {{'complications' | translate}} </div>

        <div  class="card">      <ul >
            <li *ngFor="let item of diseaseInfo?.complications">
              {{ item }}
            </li>
          </ul>
        </div>
    </section>
  </div>

    <!-- <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/research.svg"> {{'research'| translate}} </div>

        <div  class="card">  {{diseaseInfo.research??'-'}}
        </div>
    </section> -->
  </div>
<div>
  <div class="bottom">
    <div *ngIf="gender ==1" >
        <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
    </div>
    <div *ngIf="gender ==2"  >
        <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
    </div>
  </div>
</div>
  </div></div>