import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { User } from "../../../models/user.model";
import { ModalService } from "../../../services/modal.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { OTPDialogComponent } from "./otp-dialog/otp-dialog.component";
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
    loading: boolean = false;
    user: User = new User();
    phoneForm!: FormGroup;
    genders = ["male", "female"];
    checked;
    isSubmitted = false;

    constructor(
        private authService: AuthService,
        private modal: ModalService,
        private fb: FormBuilder,

        private router: Router,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
     
         
        this.phoneForm = this.fb.group({
            phone: ["", []],
            dob: [],
            email:[],
            gender: [],
            name: [],
            checked: ["", Validators.required],
        });
    }
    getphoneControlErrors(controlName: string): ValidationErrors | null {
        return this.phoneForm.controls[controlName].errors;
    }
 

    signUp() {
        this.isSubmitted = true;
        let body = {};
   
            body = {
                is_email: this.phoneForm.value.phone?0:1, // if 0 => phone, 1=> email
                phone: this.phoneForm.value.phone,
                name: this.phoneForm.value.name,
                gender: +this.phoneForm.value.gender,
                dob: this.phoneForm.value.dob,
                email: this.phoneForm.value.email,

        
        }
    
        this.loading = true;
        this.authService.register(body).subscribe(
            (res) => {
                this.loading = false;
                console.log(res);
                this.dialog.open(OTPDialogComponent, {
                    panelClass: "confirm-dialog-panel",
                    width: "30vw",
                    disableClose: true,
                    minWidth: "500px",
                    data: {
                        entity:  this.phoneForm.value.phone?'phone':'email',
                    },
                });
        
            },
            (error) => {
                this.loading = false;
                if (error.status === 404)
                    this.modal.errorMessage(
                        "Something went wrong"
                    );
                else {
                    console.log(error);
                    this.modal.errorMessage(error["error"].message);
                }
            }
        );
    }
}
