import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { ModalService } from "../../../services/modal.service";
import { ChangeDetectorRef } from "@angular/core";
import { DatePipe } from "@angular/common";

@Component({
    selector: "app-subscribe",
    templateUrl: "./subscribe.component.html",
    styleUrls: ["./subscribe.component.scss"],
})
export class SubscribeComponent implements OnInit, AfterViewInit {
    @ViewChild("stepper") stepper: MatStepper;
    @ViewChild("firstStepper") firstStepper: MatStep;
    @ViewChild("secondStepper") secondStepper: MatStep;

    isCompleted: boolean = true;
    subscription: any = {};
    isEditable = false;
    firstTabName: string = "sssssssss";
    addNewCredit: boolean = false;
    sessionID;
    dob: Date;
    plan: string;
    session: any = [];
    constructor(
        private route: ActivatedRoute,
        private apiService: ApiService,
        private router: Router,
        private modal: ModalService,
        private cdref: ChangeDetectorRef,
        private datePipe: DatePipe
    ) {
        console.log("this.plan");
        this.route.paramMap.subscribe((paramMap) => {
            console.log(paramMap);
            // console.log(String(paramMap.get("plan")));
            if (!paramMap.has("firstTabName")) {
                this.firstTabName = "Sign Up";
                //this.cdref.detectChanges();
            } else {
                this.firstTabName = paramMap.get("firstTabName");
            }
            if (paramMap.has("sessionID")) {
                this.sessionID = Number(paramMap.get("sessionID"));
                this.session = JSON.parse(localStorage.getItem("session"));
                console.log(this.session);
                // this.cdref.detectChanges();
            }
            if (paramMap.has("plan")) {
                console.log("ssss");
                this.plan = String(paramMap.get("plan"));
                if (this.plan !== undefined) {
                    if (this.plan == "a") {
                        this.subscription.plan = "plan_a";
                        console.log(this.subscription);
                        // this.cdref.detectChanges();
                    } else if (this.plan == "b") {
                        this.subscription.plan = "plan_b";
                        // this.cdref.detectChanges();
                    }
                }
                //this.cdref.detectChanges();
            }
            console.log(this.plan);

            console.log(this.firstTabName);
            console.log(this.sessionID);
            //  this.cdref.detectChanges();
        });
    }

    ngOnInit(): void {}
    ngAfterViewInit(): void {
        this.firstStepper.completed = true;
        this.firstStepper.editable = false;
        this.stepper.selectedIndex = +1;
        this.cdref.detectChanges();
    }

    gotToCheckout() {
        if (this.sessionID) {
            this.subscription.session_id = this.sessionID;
            this.subscription.plan = "plan_one_time";
        }

        if (this.dob) {
            this.subscription.dob = this.datePipe.transform(
                this.dob,
                "dd/MM/yyyy"
            );
        }
        console.log(this.subscription);
        if (
            !this.subscription.height ||
            !this.subscription.weight ||
            !this.subscription.marital_status ||
            !this.subscription.blood_type
        ) {
            this.modal.snackMessage("Please fill the required fields");
            return;
        }

        this.secondStepper.completed = true;
        this.stepper.selectedIndex = 2;
    }

    checkout() {
        if (!this.subscription) {
            this.modal.snackMessage(
                "Please fill the required personal data fields"
            );
        }

        console.log(this.subscription);
        this.apiService.subscribe(this.subscription).subscribe(
            (res) => {
                if(res['status'] != false){
                    this.router.navigate(['/subscription-success'])
                }
            },
            (error) => {
                this.modal.snackMessage(error["error"].error);
                return;
            }
        );
    }
}
