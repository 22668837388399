<!-- <app-header-two></app-header-two> -->

<div class="wrapper">
    <h2 class="ms-3">
        {{diseaseInfo?.name}} 
    </h2>
    <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/definition.svg"> {{'Definition & Overview'| translate}}</div>

        <div  class="card"> {{diseaseInfo?.definition_and_overview}}
        </div>
    </section>
    <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/symptoms.svg"> {{'Symptoms'| translate}} </div>

        <div  class="card">{{diseaseInfo?.symptoms}}
        </div>
    </section>
    <div class="row">
        <section class=" col-md-12 col-lg-6">
            <div class="title"> <img src="./../../../../assets/images/know-more/causes.svg"> {{'causes'| translate}} </div>
    
            <div  class="card">  {{diseaseInfo?.causes}}
            </div>
        </section>
        <section class="col-lg-6 col-md-12">
            <div class="title"> <img src="./../../../../assets/images/know-more/prognosis.svg"> {{'prognosis'| translate}} </div>
    
            <div  class="card">  {{diseaseInfo?.prognosis??'-'}}
            </div>
        </section>
    </div>


    <div class="row">
        <section class="col-lg-6 col-md-12">
            <div class="title"> <img src="./../../../../assets/images/know-more/diagnosis.svg"> {{'diagnosis'|translate}} </div>
    
            <div  class="card">  {{diseaseInfo?.diagonsis??'-'}}
            </div>
        </section>
        <section class="col-lg-6 col-md-12">
            <div class="title"> <img src="./../../../../assets/images/know-more/prevention.svg"> {{'prevention'| translate}} </div>
    
            <div  class="card"> {{diseaseInfo?.prevention??"-"}}
            </div>
        </section>
    </div>

    <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/treatment.svg"> {{'treatment' | translate}} </div>

        <div  class="card">  {{diseaseInfo?.treatment??'-'}}
        </div>
    </section>
    <section>
        <div class="title"> <img src="./../../../../assets/images/know-more/research.svg"> {{'research'| translate}} </div>

        <div  class="card">  {{diseaseInfo.research??'-'}}
        </div>
    </section>
</div>