<!-- <app-header-two></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container pt-3">
        <div class="row">
            <div class="col-lg-1 sort-by top-space-responsive">
                <span>Sort By :</span>
            </div>
            <div class="col-lg-2">
                <div class="select-box">
                    <select class="form-control">
                        <option>Newest</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>Category</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>Top Viewed</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 top-space-responsive">
                <div class="select-box">
                    <select class="form-control">
                        <option>Popular</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-2"></div>
        </div>

        <br />
        <br />

        <div class="row">
            <div
                class="col-lg-6 col-md-6 top-space"
                *ngFor="let prescription of prescriptions"
            >
                <mat-card class="shadow">
                    <div class="row-flex">
                        <div
                            style="
                                flex: 1;
                                align-self: flex-start;
                                display: contents;
                            "
                        >
                            <img
                                [src]="prescription.image.url"
                                style="
                                    border-radius: 50%;
                                    height: 100px;
                                    width: 100px;
                                "
                            />
                        </div>
                        <div style="flex: 0.5"></div>
                        <div class="col-flex">
                            <div style="flex: 3">
                                <h2 class="name">{{ prescription.title }}</h2>
                            </div>
                            <div style="flex: 5; margin-top: -15px">
                                <p class="description">
                                    {{ prescription.desc }}
                                </p>
                            </div>

                            <div style="flex: 1">
                                <a
                                    (click)="
                                        navigateToViewPrescription(prescription)
                                    "
                                    class="learn-more-btn"
                                    >Read More
                                </a>
                            </div>
                        </div>
                        <div style="flex: 5"></div>
                    </div>
                </mat-card>
            </div>
            <br />
        </div>
    </div>
</section>
