import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChooseDoctorComponent } from '../../patient-profile/choose-doctor/choose-doctor.component';

@Component({
  selector: 'app-subscribe-warning',
  templateUrl: './subscribe-warning.component.html',
  styleUrls: ['./subscribe-warning.component.scss']
})
export class SubscribeWarningComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SubscribeWarningComponent>,

  ) { 
    
  }

  ngOnInit(): void {
  }

  cancel() {
    let res = false;
    this.dialogRef.close(res);
}
  
}
