import { Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";

@Injectable({
    providedIn: "root",
})
export class FormService {
    constructor() {}

    public validateForm(forms) {
        console.log(forms[0])
        let isValid = true;

        for (let form of forms) {
            isValid = form.valid;

            if (!isValid) {
                this.fireInvalidFormControls(form);
                return isValid;
            }
        }

        return isValid;
    }

    public fireInvalidFormControls(form: NgForm) {
        console.log(form.controls)
        if (!form) return;

        Object.keys(form.controls).forEach((key) => {
            if (!form.controls[key].valid) form.controls[key].markAsTouched();
        });
    }
}
