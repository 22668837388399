import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { ModalService } from "../../../services/modal.service";

@Component({
    selector: "app-ask-doctor",
    templateUrl: "./ask-doctor.component.html",
    styleUrls: ["./ask-doctor.component.scss"],
})
export class AskDoctorComponent implements OnInit {
    fileName: string = "";
    file: File;
    medicalFields: any[] = [];
    question: any = {};

    constructor(
        private apiService: ApiService,
        private modal: ModalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.fillMedicalFields();
    }

    onChange(event) {
        this.file = event.target.files[0];
        this.fileName = event.target.files[0].name;
    }

    setFieldID(e) {
        console.log(e);
        this.question.field_id = e.value;
    }

    fillMedicalFields() {
        this.apiService.getMedicalFields().subscribe((res) => {
            if (!res) return;
            this.medicalFields = res["data"];
        });
    }

    postQuestion() {
        if (!this.file || !this.file.name) {
            this.modal.openValidationDialog(
                "warning",
                "Error",
                "Please upload file or photo"
            );
            return;
        }
        if (
            !this.question.field_id ||
            !this.question.text ||
            !this.question.title
        ) {
            this.modal.openValidationDialog(
                "warning",
                "Error",
                "Please fill required fields"
            );
            return;
        }
        let files: any[] = [];
        files.push(this.file);
        this.question.files = files;
        console.log(this.question);
        this.apiService.postQuestion(this.question).subscribe(
            (res) => {
                if (res["status"] == false) {
                    this.modal.snackMessage(res["error"]);
                    return;
                } else {
                    this.router.navigate(["/ask-success"]);
                }
            },
            (error) => {
                this.router.navigate(["/ask-success"]);
                this.modal.snackMessage(error["error"].error);
                return;
            }
        );
    }
}
