import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AiDiagnosisService } from '../ai-diagnosis/ai-diagnosis.service';

@Component({
  selector: 'app-know-more',
  templateUrl: './know-more.component.html',
  styleUrls: ['./know-more.component.scss']
})
export class KnowMoreComponent implements OnInit {
slug:string;
diseaseInfo;
gender:any;
dir = localStorage.getItem('dir') || 'ltr';


constructor(
    private activatedRoute: ActivatedRoute,
    private aiService: AiDiagnosisService

  ) { 
    this.slug = this.activatedRoute.snapshot.params['slug'];

  }

  ngOnInit(): void {
    this.gender =  localStorage.getItem('user_doctor')

    this.getMoreInfo()
  }

  getMoreInfo(){
    this.aiService.getMoreDiagnose(this.slug).subscribe((res)=>{
      this.diseaseInfo = res 
      console.log(this.diseaseInfo)
    })
  }


}
