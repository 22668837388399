<div class="input-container mb-2">
  <label class="input-container__label">{{ label }}</label>
  <div
    class="input-container__wrapper d-block"
    [ngClass]="{
      'input-container__wrapper-error': isSubmitted && errors,
      'auth-input': isAuthInput
    }"
  >
    <img [src]="icon" *ngIf="icon" />

    <textarea
      class="input-container__wrapper__input"
      [placeholder]="placeholder"
      [(ngModel)]="input"
      (ngModelChange)="onChange($event)"
      (blur)="onTouched()"
      [disabled]="isDisabled"
      rows="5"
      cols="150"
    >
    </textarea>
    <div *ngIf="showComment">
      <hr />
      <div class="d-block">
        <div class="d-flex justify-content-end mt-2">
          <app-general-button
            title="Comment"
            (btnClicked)="submitComment()"
          ></app-general-button>
          <!--  -->
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="errors">
    <app-validation-errors
      [isSubmitted]="isSubmitted"
      [errors]="errors"
      [mismatchError]="mismatchError"
    ></app-validation-errors>
  </div>
</div>
