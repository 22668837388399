<!-- <app-header-two></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12" style="margin-top: -30px">
                <img
                    [src]="new?.image?.url"
                    width="100%"
                    style="height: 450px !important"
                />
            </div>

            <div class="col-lg-12 text-left" style="margin-top: 15px">
                <h2>{{ new.title }}</h2>
            </div>
            <!-- <div class="col-lg-12 text-left" style="margin-top: 5px">
                <span class="view">
                    <mat-icon svgIcon="view"></mat-icon>
                    {{ new.views_count }}</span
                >
                <span class="comment">
                    <mat-icon svgIcon="comment"></mat-icon>
                    {{ new.shares_count }}</span
                >
            </div> -->

            <div class="col-lg-12 text-left" style="margin-top: 10px">
                <p
                    #description
                    class="description"
                    [innerHtml]="newDescription"
                ></p>
            </div>

            <div class="col-lg-1" style="margin-top: 20px">
                <img
                    src="assets/img/user1.jpg"
                    class="avatar"
                    alt="image"
                    width="60px"
                />
            </div>
<!-- 
            <div class="col-lg-11" style="margin-top: 20px">
                <p class="comment-form-comment textarea-container">
                    <textarea
                        name="comment"
                        id="comment"
                        cols="45"
                        placeholder="Your Comment..."
                        rows="5"
                        maxlength="65525"
                        required="required"
                    ></textarea>
                    <a  class="default-btn">Publish</a>

                    <button mat-icon-button class="attachment">
                        <mat-icon svgIcon="attachment"></mat-icon>
                    </button>

                    <button mat-icon-button class="emoji">
                        <mat-icon svgIcon="emoji"></mat-icon>
                    </button>
                </p>
            </div>

            <div class="comments-area">
                <h3 class="comments-title">
                    {{ new.comments?.length }} Comments:
                </h3>

                <ol class="comment-list">
                    <li class="comment" *ngFor="let comment of new.comments">
                        <article class="comment-body">
                            <footer class="comment-meta">
                                <div class="comment-author vcard">
                                    <img
                                        [src]="comment.user_pic"
                                        class="avatar"
                                        alt="image"
                                    />
                                    <b class="fn">{{ comment.user_name }}</b>
                                    <span class="says">says:</span>
                                </div>

                                <div class="comment-metadata">
                                    <a routerLink="/">
                                        <span>{{ comment.created_at }}</span>
                                    </a>
                                </div>
                            </footer>

                            <div class="comment-content">
                                <p>
                                    {{ comment.text }}
                                </p>
                            </div>

                            <div class="comment-action">
                                <a>Like .</a>
                                <a>Reply .</a>

                                <span class="comment-reply-link">20h</span>
                            </div>
                        </article>

                        <ol class="children">
                            <li
                                class="comment"
                                *ngFor="let reply of comment.replies"
                            >
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img
                                                [src]="reply.user_pic"
                                                class="avatar"
                                                alt="image"
                                            />
                                            <b class="fn">{{
                                                reply.user_name
                                            }}</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/">
                                                <span>{{
                                                    reply.created_at
                                                }}</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>
                                            {{ reply.text }}
                                        </p>
                                    </div>

                                    <div class="comment-action">
                                        <a>Like .</a>
                                        <a>Reply .</a>

                                        <span class="comment-reply-link"
                                            >20h</span
                                        >
                                    </div>
                                </article>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div> -->
        </div>
    </div>
</section>
