import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-health-prescription-list",
    templateUrl: "./health-prescription-list.component.html",
    styleUrls: ["./health-prescription-list.component.scss"],
})
export class HealthPrescriptionListComponent implements OnInit {
    prescriptions: any[] = [];

    constructor(
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.fillPrescriptions();
    }

    ngOnInit(): void {}

    fillPrescriptions() {
        this.apiService.getPrescriptions().subscribe((res) => {
            console.log(res);
            this.prescriptions = res["data"]["items"];
        });
    }

    navigateToViewPrescription(prescriptionObj) {
        console.log(prescriptionObj);
        this.router.navigate(["/single-prescription", { id: prescriptionObj.id }], {
            relativeTo: this.route,
        });
    }
}
