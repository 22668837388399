import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {BreakpointObserver} from '@angular/cdk/layout';
import {StepperOrientation} from '@angular/material/stepper';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AiDiagnosisService } from './ai-diagnosis.service';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-ai-diagnosis',
  templateUrl: './ai-diagnosis.component.html',
  styleUrls: ['./ai-diagnosis.component.scss']
})
export class AiDiagnosisComponent implements OnInit {
  step1Questions
  step2Questions
  step3Questions
  selectedAnswers=[[],[],[],[],[],[],[]]
  answersDto=[]
  sevirtyList:any =[]
  loading = false
  filteredOptions: Observable<string[]>;
  symptoms = new FormControl('');
  selectedSymptoms: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA]
  errorMessage = null
  ngOnInit(): void {
  
 this.getQuestions()
 setTimeout(() => {
   this.filteredOptions = this.symptoms.valueChanges.pipe(
    startWith(''),
    map(value => this._filter(value || '')),
  
  );
 }, 500);

      
      }
    //   private _filter(value: string): string[] {
    //     const filterValue = value.toLowerCase();
    // console.log(this.step1Questions.answers,"ans")
    //     return this.step1Questions.answers.filter(option => option.toLowerCase().includes(filterValue));
      // }
      private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        // Add a check to ensure `this.step1Questions` is defined
        if (!this.step1Questions) {
          console.warn('Questions not yet loaded');
          return [];
        }
        console.log(this.step1Questions)
        return this.step1Questions[0].answers.filter(option => option.toLowerCase().includes(filterValue));
      }
      
    


  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  stepperOrientation: Observable<StepperOrientation>;

  constructor(private _formBuilder: FormBuilder, breakpointObserver: BreakpointObserver,private apiService:AiDiagnosisService,private router:Router) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
  }
  getQuestions(){
    this.apiService.getQuestions().subscribe((res:[any])=>{
      this.step1Questions = res.filter(q => q.step === 1);
  this.step2Questions = res.filter(q => q.step === 2);
  this.step3Questions = res.filter(q => q.step === 3);

    },(error)=>{
      this.errorMessage= error.error.message
    })
  }


  constructQuestionAnswer(questionId: number, answer: any,e:any) {
    console.log(questionId,answer,e)
    let index = this.answersDto.findIndex(obj => obj.question === questionId);
    if (e.checked) {
      this.selectedAnswers[questionId].push(answer);
    } else {
      this.selectedAnswers[questionId] = this.selectedAnswers[questionId].filter(value => value !== answer);
    } 

    if(questionId !==1){
    if (index !== -1) {
      let existingAnswers = this.answersDto[index].answer.split(',')
      console.log(existingAnswers);
      if(e.checked) {
        existingAnswers.push(answer);
      }else {
        existingAnswers = existingAnswers.filter(e => e !== answer)
      }
      this.answersDto[index] = {question: questionId, answer: existingAnswers.join(',') }

    }else{
      this.answersDto.push({question: questionId, answer})
    }
    }
    else {
      this.answersDto[0] = {question: questionId, answer: answer.join(',') }
this.sevirtyList = answer
      // this.answersDto = answer
    }
      
    }

    sendResults(){
      this.loading =  true
      
this.apiService.sendResults(this.answersDto).subscribe((res:any)=>{

// catch id 
// send id in request => 
// response as history item 
this.apiService.aiDiagnosis =  res.details
localStorage.setItem('diagnoseID',res.id)
this.router.navigate(['/results'])
})
    }


    addSymptom(event: MatChipInputEvent): void {
      const value = (event.value || '').trim();
      if (value && !this.selectedSymptoms.includes(value)) {
        this.selectedSymptoms.push(value);
      }
      // Clear the input value
      event.chipInput!.clear();
      this.symptoms.setValue(null);
      // this.sevirtyList = this.symptoms
// console.log(this.sevirtyList)

}

selectSymptom(event: MatAutocompleteSelectedEvent): void {
  const value = event.option.value;
  if (value && !this.selectedSymptoms.includes(value)) {
    this.selectedSymptoms.push(value);
  }
  this.symptoms.setValue(null);
  this.answersDto[0] = {question: 1, answer: this.selectedSymptoms.join(',') }
  this.sevirtyList = this.selectedSymptoms

    }
  
    removeSymptom(symptom: string): void {
      const index = this.selectedSymptoms.indexOf(symptom);
      if (index >= 0) {
        this.selectedSymptoms.splice(index, 1);
      }
  }
}

 


 

