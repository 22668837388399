<!-- <ng-container [ngSwitch]="stepperOrientation | async">
    <div *ngSwitchCase="'horizontal'">Make your screen smaller to see a vertical stepper</div>
    <div *ngSwitchCase="'vertical'">Make your screen larger to see a horizontal stepper</div>
  </ng-container> -->
  <!-- <app-header-two></app-header-two> -->
<div *ngIf="!loading && !errorMessage">

  <mat-stepper
    class="example-stepper ps-md-5 ps-lg-5"
    [orientation]="(stepperOrientation | async)!">
    <mat-step [stepControl]="firstFormGroup" label=" Symptom Selection">
      <form [formGroup]="firstFormGroup"  >
    
        <div *ngFor="let question of step1Questions">

          <p class="title"> {{question.question}}
          </p>
          <p>  ( you can choose up to {{question.validation.max}}  answers ) </p>
  
          <div class="container-fluid 
          ">
              <div class="row justify-content-start mb-3" *ngIf="question.id != 1">
                  <div class="card col-lg-6 col-md-12 d-flex me-1 mb-2"   *ngFor="let item of question.answers">
  <div > 
  
      <mat-checkbox [value]="item"  [disabled]="(selectedAnswers[question.id]?.length >= question.validation.max )&& !selectedAnswers[question.id].includes(item)" (change)="constructQuestionAnswer(question.id,$event.source.value, $event)">{{item}} </mat-checkbox>
  </div>

                  </div>


                  </div>
                  
                  <div *ngIf="question.id ==1"> 
                    <div class="row">
                      <div class="col-6 align-self-start">
                    <!-- <mat-form-field class="input-container__input mt-2" appearance="outline">

                    <mat-select
                    multiple="true"
                    [placeholder]="'choose your symptoms'"
                    [disabled]="false"
                    (selectionChange)="constructQuestionAnswer(1,$event.source.value, $event)"
                  >
                    <mat-option
                      *ngFor="let item of question.answers"
                      [value]="item"
                    >
                      {{ item }}
                    </mat-option>
                  </mat-select>
                  </mat-form-field> -->
<!-- 

                  <mat-form-field class="input-container__input mt-2" appearance="outline">
                    <input type="text"
                           placeholder="symptoms"
                           matInput
                           [formControl]="symptoms"
                           multiple="true"
                           [matAutocomplete]="auto">
                    <mat-autocomplete (change)="constructQuestionAnswer(1,$event.source.value, $event)"autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field> -->

                  <mat-form-field class="input-container__input mt-2" appearance="outline">
                    <mat-chip-list #chipList>
                      <mat-chip *ngFor="let symptom of selectedSymptoms" [selectable]="true" [removable]="true" (removed)="removeSymptom(symptom)">
                        {{ symptom }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input
                        placeholder="Add symptoms"
                        matInput
                        [formControl]="symptoms"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addSymptom($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSymptom($event)">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  
                
                  </div>
                  <div class=" col-lg-6 col-md-12 align-self-end">
                    <div *ngFor="let sevirtyItem of sevirtyList"  class="card mt-2 row">
                      <div class="col-lg-3 col-md-12">
                        {{sevirtyItem}} 
                      </div> 
                      <div class=" col-lg-8 col-md-12">
                        <mat-radio-group
                        color="primary"
                    >
                        <mat-radio-button value="0"
                        class="me-2"
                            >Mild</mat-radio-button
                        >
                        <mat-radio-button value="1"  class="me-2"
                            >Moderate</mat-radio-button
                        >
                        <mat-radio-button value="2"  class="me-2"
                        >Severe</mat-radio-button
                    >
                    </mat-radio-group>
                    <!-- <div (click)="constructQuestionAnswer(2,[sevirtyItem],null)">x</div> -->
                      </div> 
                    
                </div> </div>
                  </div>
                  </div>

                  </div>
        </div>
        <div>
            <div class="destinations-btn-box">
                
                <button
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                    matStepperNext
                >
                    {{ "Next" | translate }}
                </button>
     
            </div>
          <!-- <button mat-button >Next</button> -->
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label=" Symptom Timeline">
      <form [formGroup]="secondFormGroup">
          <div *ngFor="let question of step2Questions">

            <p class="title"> {{question.question}}<span *ngIf="!question.validation.required">(optional)</span>
            </p>
            <p *ngIf="question.validation.max >1">   ( you can choose up to {{question.validation.max}} answers ) </p>
    
            <div class="container-fluid 
            ">
                <div class="row justify-content-start mb-3">
                    <div class="card col-lg-4 col-md-12 d-flex me-1 mb-2"   *ngFor="let item of question.answers">
    <div>
    
        <mat-checkbox [value]="item"  [disabled]="(selectedAnswers[question.id]?.length >= question.validation.max )&& !selectedAnswers[question.id].includes(item)" (change)="constructQuestionAnswer(question.id,$event.source.value, $event)">{{item}} </mat-checkbox>
    </div>
    
                    </div>
                    </div>
                    </div>
          </div>
            
            <div class="destinations-btn-box">
                <button
                matStepperPrevious
                    mat-flat-button
                    class="discard"
                    >
                    <!-- [routerLink]="['/']" -->
                    {{ "Back" | translate }}
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                    matStepperNext
                >
                    {{ "Next" | translate }}
                </button>
     
            </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup" label=" Symptom Details">
      <form [formGroup]="thirdFormGroup">
        <div *ngFor="let question of step3Questions">

          <p class="title"> {{question.question}}<span *ngIf="!question.validation.required">(optional)</span>
          </p>
          <p *ngIf="question.validation.max >1"
            >  ( you can choose up to {{question.validation.max}} answers ) </p>
  
          <div class="container-fluid 
          ">
              <div class="row justify-content-start mb-3">
                  <div class="card col-lg-4 col-md-12 d-flex me-1 mb-2"   *ngFor="let item of question.answers">
  <div>
  
      <mat-checkbox class="text-wrap" [value]="item"       [disabled]="(selectedAnswers[question.id]?.length >= question.validation.max )&& !selectedAnswers[question.id].includes(item)"
        (change)="constructQuestionAnswer(question.id,$event.source.value, $event)" >
        <label>

          {{item}}
        </label>
         </mat-checkbox>
  </div>

                  </div>
                  </div>
                  </div>
        </div>   
        
        <div class="destinations-btn-box">
          <button
          matStepperPrevious
              mat-flat-button
              class="discard"
              >
              <!-- [routerLink]="['/']" -->
              {{ "Back" | translate }}
          </button>
          <button 
          (click)="sendResults()"
              mat-raised-button
              color="primary"
              class="default-btn"
          >
              {{ "Results" | translate }}
          </button>

      </div>
    
      </form>
    </mat-step>
  </mat-stepper>
</div>
<div *ngIf="loading" class="spinner m-auto">
  <div class="m-auto">

    <mat-spinner color="#12B6DF"></mat-spinner>
  </div>
  <div>The AI is reviewing your answers to provide an accurate diagnosis. This will just take a few moments.</div>
</div>
<div *ngIf="errorMessage" class="errorMsg">{{errorMessage}}</div>
