<h3>Share</h3>
<div>

    <div class="share">
    <!-- Share on Facebook -->
    <a [href]="facebookShareUrl" target="_blank">
        <svg height="40" width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#15366f" d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>    </a>
  
    <!-- Share via Email -->
    <a [href]="emailShareUrl" target="_blank">
        <svg  height="40" width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path fill="#959aa3" d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm-7.3-256.4c2.2 1.2 4.7 1.9 7.3 1.9s5.1-.6 7.3-1.9l120.6-66.4C382.5 172.9 368.7 160 352 160l-192 0c-16.7 0-30.5 12.9-31.9 29.3l120.6 66.4zm30 28c-7 3.8-14.8 5.8-22.7 5.8s-15.8-2-22.7-5.8L128 225.7l0 94.3c0 17.7 14.3 32 32 32l192 0c17.7 0 32-14.3 32-32l0-94.3L278.7 283.6z"/></svg>    </a>
  
    <!-- Share on X (Twitter) -->
    <a [href]="twitterShareUrl" target="_blank">
        <svg  height="40" width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/></svg>    </a>
  
    <!-- Share on LinkedIn -->
    <a [href]="linkedinShareUrl" target="_blank">
        <svg  height="40" width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>    </a>
    <!-- <a [href]="pinterestShareUrl" target="_blank">
        Share on Pinterest
      </a> -->
    </div>
    <!-- Copy Link Button -->
     <div class="d-flex mt-4">
        <div class="link">
            {{currentUrl}}
        </div>

         <button (click)="copyLink()">Copy</button>
     </div>
     
  </div>
  