import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-single-new",
    templateUrl: "./single-new.component.html",
    styleUrls: ["./single-new.component.scss"],
})
export class SingleNewComponent implements OnInit {
    params$;
    new: any = {};
    newDescription
    @ViewChild("description") description: ElementRef;
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private apiService: ApiService
    ) {
        this.params$ = this.route.params.subscribe((params) => {
            let id = Number(params["id"]);
            if (id !== undefined) this.fillNew(id);
        });

        this.matIconRegistry.addSvgIcon(
            "view",
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                "assets/img/Views.svg"
            )
        ),
            this.matIconRegistry.addSvgIcon(
                "comment",
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    "assets/img/Comments.svg"
                )
            ),
            this.matIconRegistry.addSvgIcon(
                "emoji",
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    "assets/img/face-svgrepo-com.svg"
                )
            ),
            this.matIconRegistry.addSvgIcon(
                "attachment",
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    "assets/img/attachment-svgrepo-com.svg"
                )
            );
    }
    ngOnInit(): void {}

    fillNew(id) {
        this.apiService.getSingleNew(id).subscribe((res) => {
            if (!res) return;
            this.new = res["data"];
            this.newDescription = this.new['text']
        });
    }
}
