import { Component, OnInit } from '@angular/core';
import { AiDiagnosisService } from '../ai-diagnosis/ai-diagnosis.service';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  results = []
  public doughnutChartDatasets: ChartConfiguration<"doughnut">["data"]["datasets"] =
  [
      {
          data: [350, 100],
          backgroundColor: ["#FFB6C1","#F30000"],
          borderWidth: [0.1],
      },
      // { data: [50, 150, 120], label: "Series B" },
      // { data: [250, 130, 70], label: "Series C" },
  ];

public doughnutChartOptions: ChartConfiguration<"doughnut">["options"] = {
  responsive: true,
  cutout: "90%",
  plugins: {
      legend: {
          display: false,
      },
  },
};

  constructor(private aiService:AiDiagnosisService) { 
    if (this.aiService.aiDiagnosis.length != 0){

      this.results =  this.aiService.aiDiagnosis
    }
    else{
      this.aiService.getSingleDiagnose().subscribe((res:any)=>{
        this.results = res.details
      })
    }
  }

  ngOnInit(): void {
  }

}
