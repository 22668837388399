<div class="text-align-center">
    <label class="upload-label text-align-center" for="file-input">
        <div class="ml-2 text-align-center">
            <input
                class="image-input"
                [(ngModel)]="input"
                type="file"
                (change)="selectionChange($event)"
                accept=".jpeg, .png, .jpg, .gif, .svg"
                id="file-input"
                [disabled]="isDisabled"
            />
        </div>
        <div *ngIf="previewUrl">
            <img [src]="previewUrl" class="preview" alt="Image " />
        </div>
        <div class="icon" *ngIf="showEdit">
            <img
                src="./../../../../.././../../assets/images/profile/edit.svg"
            />
        </div>
    </label>
</div>

<!-- <div class="signup" *ngIf="signup">
  <label class="sign-upload-label" for="file-input">
    <div>
      <div *ngIf="previewUrl === null">
        <img
          src="./../../../../../assets/images/nav/icons/blue-icon/camera.svg"
          alt=""
        />
      </div>

      <input
        class="image-input"
        [(ngModel)]="input"
        type="file"
        (change)="selectionChange($event)"
        accept="image/*"
        id="file-input"
      />
    </div>
    <div *ngIf="previewUrl">
      <img [src]="previewUrl" class="sign-preview" alt="Image " />
    </div>
  </label>
</div> -->
