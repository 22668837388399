<div class="body">
    <h1>{{"choose_dr"|translate}}</h1>
 
<div class="d-flex row">
    <div class= "col-sm-12 col-md-6 col-lg-6 d-flex mb-2 ">
        <div class="card "   [ngClass]="{'blue-bg': isBox1Active}"   (click)="chooseDr(1)">
<p>Doctor Aiden</p>
            <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
        </div>
    </div>
    <div class= "col-sm-12 col-lg-6  col-md-6 d-flex mb-2" >
        <div class="card " [ngClass]="{'blue-bg': !isBox1Active}"   (click)="chooseDr(2)" >
<p> Doctor Ava</p>
        <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
        </div>
            </div>
</div>
<div class="mt-3 float">
    <span (click)="cancel()"
                    mat-flat-button
                    class="discard"
                >
                    {{ "Discard" | translate }}
</span>    
    <button (click)="confirmDR()">{{'confirm'|translate}}</button>
</div>
</div>