import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AiDiagnosisService } from '../../ai-diagnosis/ai-diagnosis.service';
import { HealthLibraryService } from '../health-library.service';

@Component({
  selector: 'app-single-content',
  templateUrl: './single-content.component.html',
  styleUrls: ['./single-content.component.scss']
})
export class SingleContentComponent implements OnInit {
  type:number;
  diseaseInfo;
  articles:any =[]
keys:any
content
  constructor(
    private activatedRoute: ActivatedRoute,
    private aiService: HealthLibraryService

  ) { 
    this.type = this.activatedRoute.snapshot.params['id'];

  }

  ngOnInit(): void {
    this.getContent()
  }

  getContent(){
    this.aiService.getSingleArticle(this.type).subscribe((res:any)=>{
      this.diseaseInfo = res 
      console.log(this.diseaseInfo)
      this.articles= res.details
      this.keys = Object.keys(this.articles);
    })
  }


}
