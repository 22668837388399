<!-- <app-header-two></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 top-space-card">
                <mat-card class="user-card">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row-flex filter-header">
                                <div
                                    style="
                                        flex: 1;
                                        color: white;
                                        font-size: 14px;
                                    "
                                >
                                    FILTERS
                                </div>
                                <div style="flex: 5"></div>
                                <div style="flex: 1; text-align: end">
                                    <img src="assets/img/filter.svg" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 filter-card-space">
                            <div class="form-group">
                                <mat-select placeholder="Doctor Category">
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="col-lg-12 filter-card-space">
                            <div class="form-group">
                                <mat-select placeholder="Title">
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="col-lg-12 filter-card-space">
                            <div class="form-group">
                                <mat-select placeholder="Country">
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div class="col-lg-6 filter-card-space">
                            <div class="form-group">
                                <mat-select placeholder="From">
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div class="col-lg-6 filter-card-space">
                            <div class="form-group">
                                <mat-select placeholder="To">
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="col-lg-12 filter-card-space">
                            <div class="form-group">
                                <mat-select placeholder="Duration">
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div class="col-lg-12 filter-card-space">
                            <div class="form-group">
                                <mat-select placeholder="Session Fee">
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                    <mat-option>Test</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="destinations-btn-box">
                            <button
                                mat-raised-button
                                color="primary"
                                class="default-btn"
                            >
                                Search
                            </button>
                        </div>

                        <div class="row-flex">
                            <div style="flex: 1"></div>
                            <div
                                style="
                                    flex: 1;
                                    text-align: center;
                                    margin-top: 10px;
                                "
                            >
                                <p class="reset">Reset</p>
                            </div>
                            <div style="flex: 1"></div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="col-lg-9">
                <!-- <br />
                <br />

                <div
                    class="position-relative mt-3 mb-5 row justify-content-center"
                    [lang]="lang"
                >
                    <div
                        class="position-absolute top-0 start-50 translate-middle row justify-content-center"
                    >
                        <div class="col-lg-12 col-sm-8 col-md-9">
                            <form class="d-flex mb-5 mt-5" role="search">
                                <input
                                    class="form-control me-2 rounded-pill input1 border border-info doctor-search-inp"
                                    type="search"
                                    [placeholder]="'Doctor Name' | translate"
                                    aria-label="Search"
                                />
                                <button
                                    class="btn b1 rounded-pill col-lg-3"
                                    type="submit"
                                    style="
                                        background-color: #12b6df;
                                        color: #ffffff;
                                        text-transform: capitalize !important;
                                    "
                                >
                                    <span class="s1">{{
                                        "Search" | translate
                                    }}</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div class="row-flex">
                    <div class="online-now">
                        Online Now (You Can Start A Session Within Minutes)
                    </div>
                    <div style="flex: 1"></div>
                    <div style="flex: 2; text-align: end">
                        <div class="select-box">
                            <select class="form-control">
                                <option>{{ "Top Rate" | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div class="row">
                    <div class="col-lg-4">
                        <mat-card class="doctor-card ask-card">
                            <div class="pic-container">
                                <img
                                    class="doctor-pic"
                                    src="https://material.angular.io/assets/img/examples/shiba1.jpg"
                                />
                            </div>
                            <div class="col-flex doc-info">
                                <p class="doc-name">Heba A. Mahmoud</p>
                                <p class="doc-specialty">Dental Specialist</p>
                                <div class="star-rating">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                </div>
                                <p class="session-date">12/12/2012 5-pm</p>
                                <div
                                    class="row-flex"
                                    style="
                                        white-space: nowrap;
                                        color: #707070 !important;
                                        font-size: 13px !important;
                                    "
                                >
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1">
                                        <mat-icon>money</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        200$
                                    </div>
                                    <div style="flex: 1">
                                        <mat-icon>refresh</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        60 Minutes
                                    </div>
                                    <div style="flex: 1"></div>
                                </div>

                                <div class="row-flex book-container">
                                    <div style="flex: 1"></div>
                                    <div class="book-now">Book Now</div>
                                    <div style="flex: 1"></div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-lg-4">
                        <mat-card class="doctor-card ask-card">
                            <div class="pic-container">
                                <img
                                    class="doctor-pic"
                                    src="https://material.angular.io/assets/img/examples/shiba1.jpg"
                                />
                            </div>
                            <div class="col-flex doc-info">
                                <p class="doc-name">Heba A. Mahmoud</p>
                                <p class="doc-specialty">Dental Specialist</p>
                                <div class="star-rating">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                </div>
                                <p class="session-date">12/12/2012 5-pm</p>
                                <div
                                    class="row-flex"
                                    style="
                                        white-space: nowrap;
                                        color: #707070 !important;
                                        font-size: 13px !important;
                                    "
                                >
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1">
                                        <mat-icon>money</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        200$
                                    </div>
                                    <div style="flex: 1">
                                        <mat-icon>refresh</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        60 Minutes
                                    </div>
                                    <div style="flex: 1"></div>
                                </div>

                                <div class="row-flex book-container">
                                    <div style="flex: 1"></div>
                                    <div class="book-now">Book Now</div>
                                    <div style="flex: 1"></div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-lg-4">
                        <mat-card class="doctor-card ask-card">
                            <div class="pic-container">
                                <img
                                    class="doctor-pic"
                                    src="https://material.angular.io/assets/img/examples/shiba1.jpg"
                                />
                            </div>
                            <div class="col-flex doc-info">
                                <p class="doc-name">Heba A. Mahmoud</p>
                                <p class="doc-specialty">Dental Specialist</p>
                                <div class="star-rating">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                </div>
                                <p class="session-date">12/12/2012 5-pm</p>
                                <div
                                    class="row-flex"
                                    style="
                                        white-space: nowrap;
                                        color: #707070 !important;
                                        font-size: 13px !important;
                                    "
                                >
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1">
                                        <mat-icon>money</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        200$
                                    </div>
                                    <div style="flex: 1">
                                        <mat-icon>refresh</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        60 Minutes
                                    </div>
                                    <div style="flex: 1"></div>
                                </div>

                                <div class="row-flex book-container">
                                    <div style="flex: 1"></div>
                                    <div class="book-now">Book Now</div>
                                    <div style="flex: 1"></div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <br>
                <br> -->
                <div class="row-flex top-space" >
                    <div class="online-now">All Doctors</div>
                    <div style="flex: 1"></div>
                    <div style="flex: 3; text-align: end">
                        <div class="select-box">
                            <select class="form-control full-width">
                                <option>{{ "Top Rate" | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div class="row">
                    <div class="col-lg-4" *ngFor="let doctor of doctors">
                        <mat-card class="doctor-card ask-card">
                            <div class="pic-container">
                                <img
                                    class="doctor-pic"
                                    src="assets/img/user-avatar.jpg"
                                />
                            </div>
                            <div class="col-flex doc-info">
                                <p class="doc-name">{{ doctor.name }}</p>
                                <p class="doc-specialty">
                                    {{ doctor.specialities }}
                                </p>
                                <div class="star-rating">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                </div>
                                <p class="session-date">12/12/2012 5-pm</p>
                                <div
                                    class="row-flex"
                                    style="
                                        white-space: nowrap;
                                        color: #707070 !important;
                                        font-size: 13px !important;
                                    "
                                >
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1">
                                        <mat-icon>money</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        {{ doctor.pricing }}$
                                    </div>
                                    <div style="flex: 1">
                                        <mat-icon>refresh</mat-icon>
                                    </div>
                                    <div style="flex: 1; margin-top: -10px">
                                        {{ doctor.session_duration }} Minutes
                                    </div>
                                    <div style="flex: 1"></div>
                                </div>

                                <div class="row-flex book-container">
                                    <div style="flex: 1"></div>
                                    <div class="book-now" (click)="navigateToDoctorProfile(doctor)">Book Now</div>
                                    <div style="flex: 1"></div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
