<a class="general-btn" disabled="disable" [routerLink]="route" *ngIf="route">
  {{ title }}
</a>

<button
  class="general-btn"
  [disabled]="disable"
  (click)="buttonClicked()"
  *ngIf="!route"
>
  {{ title }}
</button>
