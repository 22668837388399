
<section  class="news-section row">
    <div class="section-header">
    
        <h2>{{"saved_content" | translate |titlecase}}</h2>
    </div>
    <div *ngIf="items.length==0"> <div class="not-found">
        <img src="./../../../../assets/images/health-library/not_found.png">
       <div>
    
           {{'no_saved'| translate}}
       </div> 
    </div></div>
    <div *ngFor="let card of items" class="col-lg-3 col-md-6 col-sm-10 mb-2" > 
    <div [routerLink]="['/single-article',card.id]" class="news-cards">
        <!-- Repeat this card for each news item -->
        <div class="card ">
            <img [src]="card.image" alt="Article Image">
            <div class="p-2">

                <h3>{{card.title}}</h3>
                <p  style="
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
">{{card.summary}}</p>
            </div>
        </div>
    </div>
    </div>
</section>