import { Injectable } from "@angular/core";

import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpErrorResponse,
} from "@angular/common/http";

import { Observable, of, from } from "rxjs";
import { map, timeout, retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private token: string;

    //public picName:string = '';

    constructor(private httpClient: HttpClient, private router: Router) {}
    isAuthenticated() {
        return !!localStorage.getItem("token");
    }

    saveToken(token: string): void {
        localStorage.setItem("token", token);
        this.token = token;
    }

    getToken(): string {
        //console.log(this.token)
        if (!this.token) {
            this.token = localStorage.getItem("token");
        }
        return this.token;
    }

    public getUserDetails() {
        const token = this.getToken();
        let payload;
        if (token) {
            // console.log(token);
            payload = token.split(".")[1];
            //console.log(payload);

            payload = window.atob(payload);
            // console.log(JSON.parse(payload));

            //console.log(payload)

            //this.picName = JSON.parse(payload).image

            return JSON.parse(payload);
        } else {
            return null;
        }
    }

    public isLoggedIn(): boolean {
        if (localStorage.getItem("token")) return true;
        else return false;

        // const user = this.getUserDetails();

        // if (localStorage.getItem("support") == "true") return true;
        // else if (user) {
        //     if (user.exp > Date.now() / 1000) {
        //         //console.log("true");
        //         return true;
        //     } else {
        //         //console.log("false");
        //         return false;
        //     }
        // } else {
        //     return false;
        // }
    }

    public login(
        email: string,
        password: string,
        type: number
    ): Observable<any> {
        return this.httpClient
            .request("post", `${environment.baseUrl}` + "/api/auth/login", {
                body: { email: email, password: password, is_email: type },
            })
            .pipe(
                map((res: any) => {
                    if (!res) return;

                    console.log(res);

                    if (res.success) {
                        this.saveToken(res["success"].access_token);
                        localStorage.setItem(
                            "user",
                            JSON.stringify(res["success"]["user"])
                        );
                    }

                    return res;
                })
            );
    }
    public phonelogin(
        phone: string,
        password: string,
        type: number
    ): Observable<any> {
        return this.httpClient
            .request("post", `${environment.baseUrl}` + "/api/auth/login", {
                body: { phone: phone, password: password, is_email: type },
            })
            .pipe(
                map((res: any) => {
                    if (!res) return;

                    console.log(res);

                    if (res.success) {
                        this.saveToken(res["success"].access_token);
                        localStorage.setItem(
                            "user",
                            JSON.stringify(res["success"]["user"])
                        );
                    }

                    return res;
                })
            );
    }
    public register(user: any): Observable<any> {
        return this.httpClient
            .request("post", `${environment.baseUrl}` + "/api/auth/register", {
                body: user,
            })
            .pipe(
                map((res: any) => {
                    if (!res) return;

                    console.log(res);

                    if (res.success) {
                        this.saveToken(res["success"].token);
                        localStorage.setItem(
                            "user",
                            JSON.stringify(res["success"]["user"])
                        );
                    }
                    return res;
                })
            );
    }

    public logout(): void {
        //  localStorage.clear();
        // this.router.navigate(["/auth/login"]);
    }

    forgetPw(body) {
        const url = `${environment.baseUrl}/api/auth/forgot-password`;
        return this.httpClient.post(url, body);
    }

sendOtp(otp:any){
    const url = `${environment.baseUrl}/api/auth/verify-otp`;
    return this.httpClient.post(url, otp)

}
}
