<div >
    <!-- <app-header-two></app-header-two> -->

    
    <div  class=" title">
        <div class="d-flex">
            <div class="headline ">
                {{ content.title   |titlecase }}
            </div>
            
            <div class="ms-2">
                <img *ngIf="content.is_wishlisted_by_user" src="./../../../../../assets/images/health-library/unsave.svg" alt="save article" (click)="toggleSave()">
                <img *ngIf="!content.is_wishlisted_by_user" src="./../../../../../assets/images/health-library/save.svg" alt="save article" (click)="toggleSave()">

                <img (click)="openDialog()" src="./../../../../../assets/images/health-library/share.svg" alt="">
            </div>
        </div>
        <div class=""><p>{{content.summary}}</p></div>
    </div>
    
    <div class="banner-container"  [style.backgroundImage]="'url(' + content.image + ')'">
        <!-- <img src="./../../../../assets/images/health-library/news.jpeg" alt="Article Image" class="banner">/ -->

    </div>
<!-- <section class="article" *ngFor="let article of articles"> -->
    <section class="article" *ngFor="let key of articles">

    <!-- <div class="date">{{article?.created_at | dateAgo}}</div> -->
<div class="d-flex">
    <div class="headline mb-4">
        {{ key.Title | titlecase }}
        </div>
<div class="ms-2">
</div>
</div>

<div class="paragraph"                    [innerHtml]="key.Content"
></div>
</section>
<!-- <img (click)="openDialog()" src="./../../../../../assets/images/health-library/share.svg" alt=""> -->

<section class="rating">
    <div class="headline"> {{'rating'| translate}} </div>
    <div class="d-flex gap-1 align-items-center rating-container">
        <div>                  <img src="assets/images/health-library/star-yellow.svg" />
            {{ratings?.average_rating}} Rating
        <div class="date">Based on {{ratings?.reviews_count}} reviews</div></div>
        <div class="stars-box">
            <div class="d-flex">

                <div *ngFor="let star of [1,2,3,4,5]; let i = index">
                  <img src="assets/images/health-library/star-yellow.svg" />
                </div>
                <div class="mx-3 rates">{{ratings?.five_star}}</div>
                <div class="progress mt-2" style="height: 6px; width:100%">
                    <div class="progress-bar " role="progressbar" aria-valuenow="60"
                    aria-valuemin="0" aria-valuemax="100" [style.width.%]="+fiveStars">
                    </div>
                  </div>
            </div>
    
            <div class="d-flex">
    
                <div *ngFor="let star of [1,2,3,4]; let i = index">
                  <img src="assets/images/health-library/star-yellow.svg" />
                </div>
                <div *ngFor="let star of [5]; let i = index">
                  <img src="assets/images/health-library/star-grey.svg">
                </div>
                <div class="mx-3 rates">{{ratings?.four_star}}</div>
                <div class="progress mt-2" style="height: 6px; width:100%">
                    <div class="progress-bar " role="progressbar" aria-valuenow="60"
                    aria-valuemin="0" aria-valuemax="100" [style.width.%]="+fourStars">
                    </div>
                  </div>
            </div>       <div class="d-flex">
    
                <div *ngFor="let star of [1,2,3]; let i = index">
                  <img src="assets/images/health-library/star-yellow.svg" />
                </div>
                <div *ngFor="let star of [4,5]; let i = index">
                  <img src="assets/images/health-library/star-grey.svg">
                </div>
                <div class="mx-3 rates">{{ratings?.three_star}}</div>
                <div class="progress mt-2" style="height: 6px; width:100%">
                    <div class="progress-bar " role="progressbar" aria-valuenow="60"
                    aria-valuemin="0" aria-valuemax="100" [style.width.%]="+threeStars">
                    </div>
                  </div>
            </div>       <div class="d-flex">
    
                <div *ngFor="let star of [1,2]; let i = index">
                  <img src="assets/images/health-library/star-yellow.svg" />
                </div>
                <div *ngFor="let star of [3,4,5]; let i = index">
                  <img src="assets/images/health-library/star-grey.svg">
                </div>
                <div class="mx-3 rates">{{ratings?.two_star}}</div>
                <div class="progress mt-2" style="height: 6px; width:100%">
                    <div class="progress-bar " role="progressbar" aria-valuenow="60"
                    aria-valuemin="0" aria-valuemax="100" [style.width.%]="+twoStars">
                    </div>
                  </div>
            </div>       <div class="d-flex">
    
                <div *ngFor="let star of [1]; let i = index">
                  <img src="assets/images/health-library/star-yellow.svg" />
                </div>
                <div *ngFor="let star of [2,3,4,5]; let i = index">
                  <img src="assets/images/health-library/star-grey.svg">
                </div>
                <div class="mx-3 rates">{{ratings?.one_star}}</div>
                <div class="progress mt-2" style="height: 6px; width:100%">
                    <div class="progress-bar " role="progressbar" aria-valuenow="60"
                    aria-valuemin="0" aria-valuemax="100"     [style.width.%]="+oneStars"
                    >
                    </div>
                  </div>
            </div>    
              <!-- <div class="d-flex">
     
                <div *ngFor="let star of [1,2,3,4,5]; let i = index">
                  <img src="assets/images/health-library/star-grey.svg">
                </div>
                <div class="mx-3 rates">{{ratings?.one_star}}</div>
                <div class="progress mt-2" style="height: 6px; width:100%">
                    <div class="progress-bar " role="progressbar" aria-valuenow="60"
                    aria-valuemin="0" aria-valuemax="100" style="width:60%">
                    </div>
                  </div>
            </div> -->
        </div>
        
      </div>
</section>




 <div class="wrapper">
    <hr />
    <section>
      <div class="mt-3">
        <div class="d-flex" *ngFor="let review of review">
          <div class="comment">
            <div class="user">
              <img [src]="review?.user.avatar" />
            </div>
            <p>
              <strong>{{ review?.user.name }}</strong>
            </p>
          </div>
          <div>
            <div class="d-block ms-4">
              <div>
                <div class="d-flex align-items-center">
                  <div *ngFor="let star of review?.rating | range; let i = index">
                    <img
                      style="width: 1rem"
                      src="assets/images/health-library/star-yellow.svg"
                      />
                  </div>
                  <div
                    *ngFor="let star of 5 - review?.rating | range; let i = index"
                  >
                    <img
                      style="width: 1rem"
                      src="assets/images/health-library/star-grey.svg"
                      />
                  </div>
                  <div class="date">{{ review?.created_at | dateAgo }}</div>
                </div>
                <p class="content">{{ review?.comment }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section class="add-review " ><div></div>
      <div (click)="addReview()" >+ Add Review</div></section>
  </div>
  </div>
