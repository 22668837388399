<div class="wrapper-btn">
  <app-cancel-button
    [title]="cancelBtnTitle"
    [disable]="disable"
    (btnClicked)="cancelButtonClicked()"
  ></app-cancel-button>

  <app-general-button
    [disable]="disable"
    [title]="saveBtnTitle"
    (btnClicked)="saveButtonClicked()"
  ></app-general-button>
</div>
