<div class="rating-stars">
    <i
      *ngFor="let star of stars; let index = index"
      class="star"
      [ngClass]="{'filled': index < hoverIndex || index < currentRating}"
      (click)="setRating(index + 1)"
      (mouseenter)="setHover(index + 1)"
      (mouseleave)="clearHover()"
    >
      ★
    </i>
  </div>
  