import {
    Component,
    EventEmitter,
    Input,
    Output,
    forwardRef,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { GeneralInputsAccessor } from "../../../classes/GeneralInputsAccessor";

@Component({
    selector: "app-image-upload",
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        CommonModule,
        MatInputModule,
    ],
    templateUrl: "./image-upload.component.html",
    styleUrls: ["./image-upload.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploadComponent),
            multi: true,
        },
    ],
})
export class ImageUploadComponent extends GeneralInputsAccessor {
    selectedValue: string | undefined;
    selectedCar: string | undefined;
    @Input() showEdit = false;
    @Input() placeholder!: string;
    @Input() items!: any[];
    @Input() isDisabled: boolean = false;

    @Input() icon!: string;
    @Input() label!: string;
    @Input() img!: string;
    @Output() optionSelected = new EventEmitter<string>();
    @Output() valueEmittedImage = new EventEmitter();
    previewUrl: string | ArrayBuffer | null = null;
    base64ImageString: any;
    selectedFile!: File;
    ngOnChanges() {
        if (this.img) {
            this.previewUrl = this.img;
        }
    }
    selectionChange(event: any): void {
        const file: File = event.target.files[0];
        if (file) {
            this.selectedFile = file;

            // File Preview
            const reader = new FileReader();
            reader.onload = () => {
                this.previewUrl = reader.result;
            };
            reader.readAsDataURL(file);
        }

        this.valueEmittedImage.emit(event);
    }
}
