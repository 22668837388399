
<!-- <app-header-two></app-header-two> -->
<div class="d-flex " style="align-items: center;">
    <div class="main-div">


<div class=" mb-3 talk-bubble tri-right round right-in container-fluid ml-4">
    <div class="talktext">
        <h3 class="me-4">{{'diagnoses_message'| translate}}</h3>
        <p class="me-4">{{'ai_services_disclaimer'|translate}}</p>
    </div>

    </div> 
    
    
    <div class=" mb-3 talk-bubble-mobile container-fluid ">
        <div class="">
            <h3 class="me-4">{{'diagnoses_message'| translate}}</h3>
            <p class="me-4">{{'ai_services_disclaimer'|translate}}</p>          </div>
          </div>
        <div class="row justify-content-start">
            <div class="card  d-flex col-lg-5 col-md-10" *ngFor="let result of results" >
                <div class="chart position-relative">
                    <div class="percentage-num">{{result.probability}}%</div>
    
                    <div class="chart position-relative" style="z-index: 2">
                        <canvas
                            baseChart
                            [datasets]="doughnutChartDatasets"
                            [options]="doughnutChartOptions"
                            [legend]="true"
                            [type]="'doughnut'"
                        >
                        </canvas>
                        <!-- [labels]="doughnutChartLabels" -->
                    </div>
                </div>
                <div>
                    <p class="title">{{result.diagnosis}}</p>
                    <p class="description">{{result.description}}</p>
    <div class="know"  [routerLink]="['/know-more',result.disease_slug]">{{'know_more'| translate}}</div>
                </div>
    </div>
        </div>
    </div>
    <div class="bottom">
        <div *ngIf="gender ==1" >
            <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
        </div>
        <div *ngIf="gender ==2"  >
            <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
        </div>
      </div>
</div>