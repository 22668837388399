import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-privacy-policy",
    templateUrl: "./privacy-policy.component.html",
    styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
    termsApproval: boolean = false;
    privacyApproval: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
