<div mat-dialog-content>
    <div class="row-flex">
        <div style="flex: 1"></div>
        <div  class="dialog-title">Verify your number with codes sent to you</div>
        <div style="flex: 1"></div>
    </div>
    <br>
    <div class="form-group">
        <code-input
            [isCodeHidden]="false"
            [codeLength]="6"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
        >
        </code-input>
    </div>
    <br>
    <p class="code-sent">we send code to 01068801080</p>
    <p class="not-receive">I Didn’t Receive The Code, <span class="resend">Resend</span></p>
    <div class="destinations-btn-box">
        <button mat-raised-button color="primary" class="default-btn">
            Submit
        </button>
    </div>
    <br>
</div>
