import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AiDiagnosisService {

  constructor(private httpClient: HttpClient) { 
  }


  aiDiagnosis= []
  getMedicalInfo() {
    return this.httpClient.get(
        `${environment.baseUrl}` +
            "/api/medical-file?" +
            `language=${
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "ar"
            }`
    );
}
getQuestions(){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        "/api/diagnostic/questions?" +
        `language=${
            localStorage.getItem("lang")
                ? localStorage.getItem("lang")
                : "ar"
        }`
);
}
sendResults(body){
  return this.httpClient.post(
    `${environment.baseUrl}` + "/api/diagnostic/submit?"+
    `language=${
        localStorage.getItem("lang")
            ? localStorage.getItem("lang")
            : "ar"
    }`,
  {'questions':body}
);

}
getSingleDiagnose(){
  const id =localStorage.getItem('diagnoseID')
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/diagnostic/${id}?language?` +
        `language=${
            localStorage.getItem("lang")
                ? localStorage.getItem("lang")
                : "ar"
        }`
);
}
getMoreDiagnose(slug){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/diseases/${slug}?` +
        `language=${
            localStorage.getItem("lang")
                ? localStorage.getItem("lang")
                : "ar"
        }`
);
}

getDiagnosisHistory(){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/diagnostic?` +
        `language=${
            localStorage.getItem("lang")
                ? localStorage.getItem("lang")
                : "ar"
        }`
);
}
}
