import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar, private http: HttpClient) {}

  sendFcmToken(token: any) {
    const url = `/api/dashboard/fcm`;
    return this.http.post(url, token);
  }

  showErrorNotification(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 3000, // adjust the duration as needed
      panelClass: ['custom-snack-bar-error', 'red-snackBar'],
    });
  }
  showsuccesNotification(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 10000, // adjust the duration as needed
      panelClass: ['custom-snack-bar', 'green-snackBar'],
    });
  }
  showNotifications(message: any) {
    this.snackBar.open(message, 'X', {
      // duration: 10000, // adjust the duration as needed
      panelClass: ['notification-snack-bar-error'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
