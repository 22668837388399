import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-ask-ai-history',
  templateUrl: './ask-ai-history.component.html',
  styleUrls: ['./ask-ai-history.component.scss']
})
export class AskAiHistoryComponent implements OnInit {

  selectedQuestion:any
  gender:any;
  search
  selectedQuestionIndex: number | null = 0;
  panelOpenState = false;

  // isLoading = false
  inputValue: string = '';
  response:any ='';
  public chartData;
  isLoading = true
  limit = 0
  public chartOptions = {
    responsive: true,
    cutout: '80%', // Creates a donut shape
    plugins: {
      tooltip: {
        enabled: false // Disable the tooltip if not needed
      }
    }
  }
  constructor(private http: HttpClient,
    private apiservice:ApiService,
  ) {}
    
    ngOnInit(): void {
      this.getData()
    this.gender =  localStorage.getItem('doctor')
  
    }

getData(){
this.apiservice.askAiHistory().subscribe((res:any)=>{
  this.isLoading =  false
  console.log(res)
  this.response = res.items
  this.search = res.items

})
}

getQuestion(id:number){
  this.selectedQuestion = this.response.find((item) => item.id === id);


}

selectQuestion(index: number,questionId): void {
  this.selectedQuestionIndex = index;
  this.getQuestion(questionId)
}


// Function to search items by `user_message`
 searchByUserMessage() {
  // console.log(query,"qqqqqqq")
  this.search= this.response.filter((item) =>
    item.user_message.toLowerCase().includes(this.inputValue.toLowerCase())
  );
// if(query == ''){
//   this.search =  this.response
// }

 }

    
}
  
         
      
   
  
  