import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-single-wellness-initiatives",
    templateUrl: "./single-wellness-initiatives.component.html",
    styleUrls: ["./single-wellness-initiatives.component.scss"],
})
export class SingleWellnessInitiativesComponent implements OnInit {
    params$;
    initiative: any = {};

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry
    ) {
        this.params$ = this.route.params.subscribe((params) => {
            let id = Number(params["id"]);
            if (id !== undefined) this.fillInitiative(id);
        });

        this.matIconRegistry.addSvgIcon(
            "view",
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                "assets/img/Views.svg"
            )
        ),
            this.matIconRegistry.addSvgIcon(
                "comment",
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    "assets/img/Comments.svg"
                )
            );
    }

    ngOnInit(): void {}

    fillInitiative(id) {
        this.apiService.getInitiativeByID(id).subscribe((res) => {
            if (!res) return;
            this.initiative = res["data"];
        });
    }
}
