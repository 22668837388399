import { Component, Inject, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HealthLibraryService } from '../health-library/health-library.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-rating-dialog',
  templateUrl: './rating-dialog.component.html',
  styleUrls: ['./rating-dialog.component.scss']
})
export class RatingDialogComponent implements OnInit {
comment=''

  constructor(private apiService:HealthLibraryService,
    private modal: ModalService,
    public dialogRef: MatDialogRef<RatingDialogComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: { entity: any }



  ) { 
    console.log(this.data);
  }

  ngOnInit(): void {
  }

selectedRating = 1;

onRatingChange(rating: number): void {
  this.selectedRating = rating;
  // this.sendRating()
  console.log('Selected Rating:', rating);
}


sendRating(){

  let body= {
    rating:this.selectedRating,
comment:this.comment
  }
  this.apiService.postRating(this.data.entity, body).subscribe((res)=>{
    this.modal.snackMessage("Password updated successfully");
    this.dialogRef.close(res);


  })
}
}
