<section class="register-area">
    <mat-card>
        <div class="row m-0" style="height: 100%">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="register-content">
                    <p>Welcome To Itammen</p>

                    <div class="register-form">
                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <img
                                        src="./../../../../assets/images/Auth/email.svg"
                                        alt=""
                                    />
                                    <div class="">
                                        Email Address
                                    </div>
                                </ng-template>

                                <form
                                    name="txtForm"
                                    class=""
                                    [formGroup]="emailForm"
                                    style="padding: 10px"
                                >
                                    <div class="form-group mb-3">
                                        <app-general-input
                                            type="email"
                                            formControlName="email"
                                            [isSubmitted]="isSubmitted"
                                            label="Email"
                                            [required]="true"
                                            placeholder="Enter Your email"
                                            [errors]="getEmailControlErrors('email')"
                                            [isAuthInput]="true"
                                        ></app-general-input>
                                    </div>
                                    <section>
                                        <div class="form-group mb-3">
                                            <app-general-input
                                                type="password"
                                                formControlName="password"
                                                label="Password"
                                                [required]="true"
                                                placeholder="Enter Your Password"
                                                [isPassword]="true"
                                                [errors]="getEmailControlErrors('password')"
                                                [isSubmitted]="isSubmitted"
                                                [isAuthInput]="true"
                                            ></app-general-input>
                                            <span>
                                                Please enter the password you
                                                received via email if you
                                                haven't changed it in your
                                                account security.
                                            </span>
                                        </div>
                                    </section>

                                    <section
                                        class="example-section mt-2 mb-4 bottom-section"
                                    >
                                        <div>
                                            <mat-checkbox
                                                class="example-margin"
                                                formControlName="rememberMe"
                                            >
                                                Remember me
                                            </mat-checkbox>
                                        </div>
                                        <div class="forgot-pw">
                                            <a
                                                (click)="
                                                    forgetPW(
                                                        1,
                                                        emailForm.value.email
                                                    )
                                                "
                                                class="forgot-pw"
                                            >
                                                Forgot password ?</a
                                            >
                                        </div>
                                    </section>
                                    <button
                                        type="submit"
                                        [disabled]="!emailForm.valid"
                                        (click)="login('email')"
                                    >
                                        Login
                                    </button>
                                    <div class="center">
                                        Dont Have Any Account ?<a
                                            routerLink="/register"
                                            class="text-primary"
                                        >
                                            Sign Up</a
                                        >
                                    </div>
                                </form></mat-tab
                            >

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <img
                                        src="./../../../../assets/images/Auth/phone.svg"
                                        alt=""
                                    />
                                    <div class="">
                                        Phone Number
                                    </div>
                                </ng-template>

                                <form
                                    name="txtForm"
                                    [formGroup]="phoneForm"
                                    class=""
                                    style="padding: 10px"
                                >
                                    <div class="form-group mb-3">
                                        <div class="form-group mb-3">
                                            <app-general-input
                                                type="text"
                                                formControlName="phone"
                                                label="Phone"
                                                placeholder="Enter Your Phone "
                                                [errors]="getphoneControlErrors('phone')"
                                                [required]="true"
                                                [isSubmitted]="isSubmitted"
                                                [isAuthInput]="true"
                                            ></app-general-input>
                                        </div>
                                    </div>
                                    <!-- icon="assets/images/Auth/locj.svg" -->
                                    <div class="form-group mb-3">
                                        <app-general-input
                                            type="password"
                                            formControlName="password"
                                            label="Password"
                                            placeholder="Enter Your Password"
                                            [isPassword]="true"
                                            [errors]="getphoneControlErrors('password')"
                                            [isSubmitted]="isSubmitted"
                                            [required]="true"
                                            [isAuthInput]="true"
                                        ></app-general-input>
                                        <span>
                                            Please enter the password you
                                            received via phone number if you
                                            haven't changed it in your account
                                            security.
                                        </span>
                                    </div>
                                    <section
                                        class="example-section mt-2 mb-4 bottom-section"
                                    >
                                        <div>
                                            <mat-checkbox
                                                class="example-margin"
                                                formControlName="rememberMe"

                                            >
                                                Remember me
                                            </mat-checkbox>
                                        </div>
                                        <div class="forgot-pw">
                                            <a
                                                class="forgot-pw"
                                                (click)="
                                                    forgetPW(
                                                        0,
                                                        phoneForm.value.phone
                                                    )
                                                "
                                            >
                                                Forgot password ?</a
                                            >
                                        </div>
                                    </section>
                                    <button
                                        type="submit"
                                        [disabled]="!phoneForm.valid"
                                        (click)="login('phone')"
                                    >
                                        Login
                                    </button>
                                    <div class="center">
                                        Dont Have Any Account ?<a
                                            routerLink="/register"
                                            class="text-primary"
                                        >
                                            Sign Up</a
                                        >
                                    </div>
                                </form></mat-tab
                            >
                        </mat-tab-group>
                    </div>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="register-image">
                    <img
                        src="assets/img/ashkan-forouzani-ignxm3E1Rg4-unsplash.png"
                        alt="image"
                    />
                </div>
            </div>
        </div>
    </mat-card>
</section>
