import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../services/api.service";
import { HealthLibraryService } from "../health-library/health-library.service";

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
    lang: string = "";
    home: any = {};
    data:any = [
        // Your JSON data here...
      ];
      categorizedData = {
        news: [],
        medication: [],
        dietPlan: [],
        dentistry: [],
        psychiatry: [],
        vaccination: [],
        diseases: []
      };
    constructor(
        private translateService: TranslateService,
        private apiService: HealthLibraryService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.lang = localStorage.getItem("lang");
        this.translateService.onLangChange.subscribe((lang) => {
            // console.log(lang)
            this.lang = lang["lang"];
            console.log(lang);
        });

        // this.fillHomeInfo();
    }

    ngOnInit(): void {
        this.getHomeInfo()
    }

    subscribe(plan) {
        this.router.navigate(["/subscribe", { plan: plan }], {
            relativeTo: this.route,
        });
    }
getHomeInfo(){
    this.apiService.getHomeData().subscribe((res)=>{
        console.log(res)
        this.data = res
        this.categorizeData()
    })
}
categorizeData() {
    this.data.forEach(item => {
      switch (item.type) {
        case 1:
          this.categorizedData.news.push(item);
          break;
        case 2:
          this.categorizedData.medication.push(item);
          break;
        case 3:
          this.categorizedData.dietPlan.push(item);
          break;
        case 4:
          this.categorizedData.dentistry.push(item);
          break;
        case 5:
          this.categorizedData.psychiatry.push(item);
          break;
        case 6:
          this.categorizedData.vaccination.push(item);
          break;
        case 7:
          this.categorizedData.diseases.push(item);
          break;
        default:
          console.log(`Unknown type ${item.type}`);
      }

    });
    console.log(this.categorizedData,"cattt")
  }


    navigateToViewNew(obj) {
        this.router.navigate(["/single-new", { id: obj.id }], {
            relativeTo: this.route,
        });
    }

   

    navigateToViewInitiative(obj){
        this.router.navigate(["/single-wellness", { id: obj.id }], {
            relativeTo: this.route,
        });
    }

 
}
