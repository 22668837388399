import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-subscribe-page',
  templateUrl: './subscribe-page.component.html',
  styleUrls: ['./subscribe-page.component.scss']
})
export class SubscribePageComponent implements OnInit {

  constructor(        private modal: ModalService,
    private router: Router,

  ) { }

  ngOnInit(): void {
  }
  subscribe(){
    this.modal.snackMessage("Welcome To ITAMMEN");
    this.router.navigate(['/login']);


  }

}
