



<div class="body row">
<div class="col-6 col-sm-12">

<h1><span class="blue-text">
 Hi I’m Ava!
</span> 
    How Can I help you 
    today?</h1>


    <p>Choose one of the options below to start</p>

    <div class="d-flex gap-2">
        <div> <button routerLink="/ai-diagnosis">Get aI diagnosis</button></div>
        <div> <button>Psychotherapy</button></div>
        <div> <button routerLink="/ask-ai">ASK {{gender === 1?'AVA':'Aiden'}}</button></div>
    </div>

    </div>
    <div class="col-6 col-sm-12">

        <div *ngIf="gender===1">
            <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
        </div>
        <div *ngIf="gender===2">
            <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
        </div>
    </div>

</div>
