import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-choose-doctor',
  templateUrl: './choose-doctor.component.html',
  styleUrls: ['./choose-doctor.component.scss']
})
export class ChooseDoctorComponent implements OnInit {


    isBox1Active: boolean = true;
  type:any =1
  @Output() valueEmittedImage = new EventEmitter();

    constructor(private apiService:ApiService,          private router: Router,
      private modal: ModalService,
      public dialogRef: MatDialogRef<ChooseDoctorComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      
     }
  
    ngOnInit(): void {
    }
  chooseDr(type:number){
    this.isBox1Active = !this.isBox1Active;
  this.type = type
  
  
  }
  confirmDR(){
    this.apiService.assignDr(this.type).subscribe((res)=>{
      localStorage.setItem("user_doctor",this.type);
  
      this.dialogRef.close({doctor : this.type});

      // this.router.navigate(['/ai-home']);
  
    })
  }
  
  cancel() {
    let res = false;
    this.dialogRef.close(res);
}
  }


