import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { ModalService } from "../../../services/modal.service";
import { User } from "src/app/models/user.model";
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../common/shared/components/confirm-dialog/confirm-dialog.component";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    loading: boolean = false;
    user: User = new User();

    emailForm!: FormGroup;
    phoneForm!: FormGroup;
    isSubmitted = false;

    constructor(
        private authService: AuthService,
        private modal: ModalService,
        private router: Router,
        private fb: FormBuilder,
        private dialog: MatDialog
    ) {}
    ngOnInit(): void {
        this.emailForm = this.fb.group({
            email: ["", [Validators.required]],
            password: ["", Validators.required],
            rememberMe: [false]
        });
        this.phoneForm = this.fb.group({
            phone: ["", [Validators.required]],
            password: ["", Validators.required],
            rememberMe: [false]
        });

        const savedData = localStorage.getItem('userData');
    if (savedData) {
      const userData = JSON.parse(savedData);
      if(userData.phone){

          this.phoneForm.patchValue({
            phone: userData.phone,
            password: userData.password,
            rememberMe: true
          });
      }else{
        this.emailForm.patchValue({
            email: userData.email,
            password: userData.password,
            rememberMe: true
          });
      }
    }
    }

    getphoneControlErrors(controlName: string): ValidationErrors | null {
        return this.phoneForm.controls[controlName].errors;
    }
    getEmailControlErrors(controlName: string): ValidationErrors | null {
        return this.emailForm.controls[controlName].errors;
    }

    forgetPW(type: number, value: string) {
        if (value === "") {
            this.modal.snackMessage(
                "Enter your " + (type == 1 ? "email" : "phone")
            );
            return;
        }
        let body = {};
        if (type === 0) {
            body = {
                is_email: type,
                phone: value,
            };
        } else {
            body = {
                is_email: type,
                email: value,
            };
        }

        this.authService.forgetPw(body).subscribe(
            (res) => {
                this.dialog.open(ConfirmDialogComponent, {
                    panelClass: "confirm-dialog-panel",
                    width: "30vw",
                    disableClose: true,
                    minWidth: "500px",
                    data: {
                        msg: "Password Reset Link Sent! Please check your verified email or phone number for the reset link. If you don't see it, be sure to check your spam or junk folder",
                        showLogin: true,
                    },
                });
            },
            (error) => {
                this.loading = false;
                if (error.status === 404) {
                    if (type == 1) {
                        this.modal.errorMessage(
                            " Email is not registered or your account hasn't been activated yet."
                        );
                    } else {
                        this.modal.errorMessage(
                            " Phone is not registered or your account hasn't been activated yet."
                        );
                    }
                } else {
                    this.modal.errorMessage(error["error"]["error"]);
                }
            }
        );
    }

    login(type: string) {
        if (this.loading) return;

        this.loading = true;
        this.isSubmitted = true;

        if (type == "email") {


            if (this.emailForm.value.rememberMe) {
                // Save user data to localStorage
                localStorage.setItem('userData', JSON.stringify({
                  email: this.emailForm.value.email,
                  password: this.emailForm.value.password
                }));
              } else {
                // Clear localStorage if Remember Me is unchecked
                localStorage.removeItem('userData');
              }

            this.authService
                .login(
                    this.emailForm.value.email,
                    this.emailForm.value.password,
                    1
                )
                .subscribe(
                    (res) => {
                        this.loading = false;
                        localStorage.setItem("token", res.access_token);

                            this.modal.snackMessage("Welcome To ITAMMEN");
                            this.router.navigate(['/']);
                        
                    },
                    (error) => {
                        this.loading = false;
                        if (error.status === 404)
                            this.modal.errorMessage(
                                " Email is not registered or your account hasn't been activated yet."
                            );
                        else {
                            this.modal.errorMessage(error["error"]["error"]);
                        }
                    }
                );
        }
        if (type == "phone") {

console.log(type)
console.log(this.phoneForm.value.rememberMe)
            if (this.phoneForm.value.rememberMe) {
                // Save user data to localStorage
                localStorage.setItem('userData', JSON.stringify({
                  phone: this.phoneForm.value.phone,
                  password: this.phoneForm.value.password
                }));
              } else {
                // Clear localStorage if Remember Me is unchecked
                localStorage.removeItem('userData');
              }
            this.authService
                .phonelogin(
                    this.phoneForm.value.phone,
                    this.phoneForm.value.password,
                    0
                )
                .subscribe(
                    (res) => {
                        this.loading = false;
                        localStorage.setItem("token", res.access_token);
                        localStorage.setItem("user_doctor", res.user_doctor);
                        
console.log(  res )
                        this.modal.snackMessage("Welcome To ITAMMEN");
                        if(res.user_doctor){
                            this.router.navigate(['/ai-home']);

                        }else{
                            this.router.navigate(['/home']);

                        }
                    },
                    (error) => {
                        this.loading = false;
                        this.loading = false;
                        if (error.status === 404)
                            this.modal.errorMessage(
                                " Phone is not registered or your account hasn't been activated yet."
                            );
                        else {
                            this.modal.errorMessage(error["error"]["error"]);
                        }
                    }
                );
        }
    }
}
