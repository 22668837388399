import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-wellness-initiatives-list",
    templateUrl: "./wellness-initiatives-list.component.html",
    styleUrls: ["./wellness-initiatives-list.component.scss"],
})
export class WellnessInitiativesListComponent implements OnInit {
    initiatives: any[] = [];

    constructor(
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.fillWellnessInitiatives();
    }

    ngOnInit(): void {}

    fillWellnessInitiatives() {
        this.apiService.getWellnessInitiatives().subscribe((res) => {
            console.log(res);
            this.initiatives = res["data"]["items"];
        });
    }

    navigateToViewinitiatives(obj) {
        console.log(obj);
        this.router.navigate(["/single-wellness", { id: obj.id }], {
            relativeTo: this.route,
        });
    }
}
