


<div class="container">
<div class="row" >
         
    <h3 >
        {{ "Security" | translate }}
    </h3>

    <div class="row">

        <form [formGroup]="securityForm" class="d-flex flex-column mt-4">
            <section class="row align-items-center">
                <div class="col-md-2 label">Current Password</div>
                <div class="col-md-10">
                    <app-general-input
                        formControlName="password"
                        type="password"
                        [isPassword]="true"

                        placeholder="Current Password"
                        [errors]="getSecurityErrors('password')"
                        ></app-general-input>
                        <!-- [isSubmitted]="isSubmitted" -->
                </div>
            </section>

          
   
            <div class="destinations-btn-box">
                <button
                    mat-flat-button
                    [routerLink]="['/']"
                    class="discard"
                >
                    {{ "Discard" | translate }}
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                    [disabled]="!securityForm?.value.password"
                    (click)="updatePassword()"
                >
                    {{ "Next" | translate }}
                </button>
     
            </div>
        </form>
    </div>

</div> 
</div>