import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-disease",
    templateUrl: "./disease.component.html",
    styleUrls: ["./disease.component.scss"],
})
export class DiseaseComponent implements OnInit {
    params$;
    disease: any = {};
    constructor(private apiService: ApiService, private route: ActivatedRoute) {
        this.params$ = this.route.params.subscribe((params) => {
            let id = Number(params["id"]);
            if (id !== undefined) this.fillDisease(id);
        });
    }

    ngOnInit(): void {}

    fillDisease(id) {
        this.apiService.getDiseaseByID(id).subscribe((res) => {
            if (!res) return;
            this.disease = res["data"];
        });
    }
}
