import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { SubscribeWarningComponent } from '../home-one/subscribe-warning/subscribe-warning.component';
import { MaxLimitComponent } from './max-limit/max-limit.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ask-ai',
  templateUrl: './ask-ai.component.html',
  styleUrls: ['./ask-ai.component.scss']
})
export class AskAiComponent implements OnInit {
gender:any;
dir = localStorage.getItem('dir') || 'ltr';

// isLoading = false
inputValue: string = '';
response:any ='';
isResponse = false
public chartData;
isLoading = false
limit = 0
question
public chartOptions = {
  responsive: true,
  cutout: '80%', // Creates a donut shape
  plugins: {
    tooltip: {
      enabled: false // Disable the tooltip if not needed
    }
  }
}
constructor(private http: HttpClient,
  private apiservice:ApiService,
  private dialog: MatDialog,
  private router: Router,
  private elRef: ElementRef
  


) {}

ngAfterViewInit() {
  const element = this.elRef.nativeElement.querySelector('.talktext');
  if (element.offsetHeight < (100 * window.innerHeight) / 100) {
    element.classList.add('show-scrollbar');
    element.classList.remove('hide-scrollbar');

  } else {
    element.classList.add('hide-scrollbar');
    element.classList.remove('show-scrollbar');
  }
}
  
  ngOnInit(): void {
  this.gender =  localStorage.getItem('user_doctor')

  }
  sendData() {
    this.isLoading= true
    this.question =  this.inputValue
    const data = { user_message: this.inputValue };
    this.inputValue = ''
//     this.apiservice.askAi(data)
//       .subscribe((res:any)=>{
//         this.isResponse = true
//         this.response = res
//         this.limit =  res.daily_limit
// if(this.limit == 5){
  
// }
//         this.chartData = {
//           datasets: [
//             {
//               data: [+res.daily_limit, +(5-res.daily_limit)],
//               backgroundColor: ['#00FF00', '#E0E0E0'],
//               borderWidth: 0
//             }
//           ]
//         };
// this.isLoading = false

       
//       },(error)=>{
//         console.log(error)
//         if(error.status === 403){
// this.router.navigate(['/ai-home'])
//           console.log(4033333)
//           this.warning()
//         }
//       }
//     )
//       console.log(this.chartData)
  }
  warning(){
      
    const dialogRef = this.dialog.open(MaxLimitComponent, {
        data: {
            
        },
        //cancelButton: true,
    }
    
    
)

return dialogRef.afterClosed().subscribe((res) => {
    console.log(res);
    if (res["doctor"]) {
    }
});
}
}
