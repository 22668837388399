<div class="body">
    <h1>{{"choose_dr"|translate}}</h1>
    <p>
        {{
           'change_dr_later'|translate 
        }}
    </p>
<div class="d-flex row">
    <div class= "col-sm-12 col-md-6 col-lg-6 d-flex ">
        <div class="card aiden"   [ngClass]="{'blue-bg': isBox1Active}"   (click)="chooseDr(1)">
<p>Doctor Aiden</p>
            <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
        </div>
    </div>
    <div class= "col-sm-12 col-lg-6  col-md-6 d-flex " >
        <div class="card ml-auto " [ngClass]="{'blue-bg': !isBox1Active}"   (click)="chooseDr(2)" >
<p> Doctor Ava</p>
        <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
        </div>
            </div>
</div>
<div class="mt-3">
    <button (click)="confirmDR()">{{'confirm'|translate}}</button>
</div>
</div>