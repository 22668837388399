<!-- <app-header-two></app-header-two> -->
<!--  -->
<section class="listing-categories-area pt-100 pb-70">
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-12 top-space-card">
                <mat-card class="user-card">
                    <div class="col-flex">
                  

                        <div style="flex: 1" class="text-center">
                            <mat-list>
                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('personalInfo')"
                                >
                                    <h4 mat-line>
                                        {{ "Personal Information" | translate }}
                                    </h4>
                                    <!-- <mat-icon *ngIf="lang == 'ar'"
                                        >chevron_left</mat-icon
                                    >
                                    <mat-icon *ngIf="lang == 'en'"
                                        >chevron_right</mat-icon
                                    > -->
                                </mat-list-item>

                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('medicalFile')"
                                >
                                    <h4 mat-line>
                                        {{ "Medical Information" | translate }}
                                    </h4>
            
                                </mat-list-item>
                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('security')"
                                >
                                    <h4 mat-line>
                                        {{
                                            "Security" | translate
                                        }}
                                    </h4>
                                 
                                </mat-list-item>


                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('history')"
                                >
                                    <h4 mat-line>
                                        {{
                                            "Diagnosis History" | translate
                                        }}
                                    </h4>
                                 
                                </mat-list-item>


                                
                                <mat-list-item
                                    class="list-item"
                                    (click)="changeView('article')"
                                >
                                    <h4 mat-line>
                                        {{
                                            "saved_content" | translate | titlecase
                                        }}
                                    </h4>
                                 
                                </mat-list-item>
      
                            </mat-list>
                        </div>
                    </div>
                </mat-card>
                `
            </div>
            <div class="col-lg-7 col-md-12" *ngIf="isMedicalFile">
                <h3>{{ "Medical Information" | translate }}</h3>
                <div class="d-flex mb-3">
                    <div class="chart position-relative">
                        <div class="
                        
                        ">{{percentage}} %</div>

                        <div class="chart position-relative" style="z-index: 2">
                            <canvas
                                baseChart
                                [datasets]="doughnutChartDatasets"
                                [options]="doughnutChartOptions"
                                [legend]="true"
                                [type]="'doughnut'"
                            >
                            </canvas>
                            <!-- [labels]="doughnutChartLabels" -->
                        </div>
                    </div>
                    <div>
                        <p class="title">Information</p>
                        <p class="info">
                            You're almost there! Complete your profile to unlock
                            a more personalized experience tailored to your
                            health needs. The more we know, the better we can
                            assist you.
                        </p>
                    </div>
                </div>
                <form [formGroup]="medicalForm" class="d-flex flex-column mt-4">
                    <section class="row align-items-center">
                        <div class="col-md-2 label">Date of birth</div>
                        <div class="col-md-10">
                      
                            <app-date-input
                            formControlName="dob"
                            placeholder="Date of birth"
                            [errors]="getMedicalErrors('dob')"
                            [isSubmitted]="isSubmitted"
                            [isDisabled]="!isMedicalEdit">

                            </app-date-input>
                        </div>
                    </section>

                    <section class="row align-items-center">
                        <div class="col-md-2 label">Height</div>
                        <div class="col-md-10">
                            <app-general-input
                                formControlName="height"
                                placeholder="Height"
                                [errors]="getMedicalErrors('height')"
                                [isSubmitted]="isSubmitted"
                                [isDisabled]="!isMedicalEdit"
                            ></app-general-input>
                        </div>
                    </section>
                    <section class="row align-items-center mt-4">
                        <div class="col-md-2 label">Blood Type</div>
                        <div class="col-md-10 mt-2">
                         
                            <mat-form-field class="input-container__input mt-2" appearance="outline">

                                <mat-select
                                formControlName="blood_type"

                                placeholder="Blood Type"
                                [disabled]="!isMedicalEdit"
                              >
                                <mat-option
                                  *ngFor="let item of bloodTypes"
                                  [value]="item"
                                >
                                  {{ item }}
                                </mat-option>
                              </mat-select>
                              </mat-form-field>
                        </div>
                    </section>
                    <section class="row align-items-center">
                        <div class="col-md-2 label">Weight</div>
                        <div class="col-md-10">
                            <app-general-input
                            
                                formControlName="weight"
                                placeholder=" Weight"
                                [errors]="getMedicalErrors('weight')"
                                [isSubmitted]="isSubmitted"
                                [isDisabled]="!isMedicalEdit"
                            ></app-general-input>
                        </div>
                    </section>

                    <section class="row align-items-center  mt-4">
                        <div class="col-md-2 label">Long term disease</div>
                        <div class="col-md-10  mt-2">
                          
                            
                            <mat-form-field class="input-container__input mt-2" appearance="outline">

                                <mat-select
                                formControlName="long_term_diseases"
                                multiple="true"
                                placeholder=" Long term disease"
                                [disabled]="!isMedicalEdit"
                              >
                                <mat-option
                                  *ngFor="let item of longTermDiseases"
                                  [value]="item.id"
                                >
                                  {{ item.name }}
                                </mat-option>
                              </mat-select>
                              </mat-form-field>
                        </div>
                    </section>

                    <section class="row align-items-center  mt-4">
                        <div class="col-md-2 label">Previous Surgeries</div>
                        <div class="col-md-10  mt-2">
                         
                                  <mat-form-field class="input-container__input mt-2" appearance="outline">

                                <mat-select
                                formControlName="previous_surgeries"
                                    multiple="true"
                                placeholder="Previous Surgeries"
                                [disabled]="!isMedicalEdit"
                              >
                                <mat-option
                                  *ngFor="let item of surgeries"
                                  [value]="item.id"
                                >
                                  {{ item.name }}
                                </mat-option>
                              </mat-select>
                              </mat-form-field>
                        </div>
                    </section>

                    <section class="row align-items-center  mt-4">
                        <div class="col-md-2 label">Family History</div>
                        <div class="col-md-10  mt-2">
                       
                            <mat-form-field class="input-container__input mt-2" appearance="outline">
                                <mat-select
                                formControlName="family_medical_history"
                                multiple="true"
                                placeholder="Family History"
                                [disabled]="!isMedicalEdit"
                              >
                                <mat-option
                                  *ngFor="let item of familyHistory"
                                  [value]="item.id"
                                >
                                  {{ item.name }}
                                </mat-option>
                              </mat-select>
                              </mat-form-field>
                        </div>
                    </section>

                    <section class="row align-items-center  mt-4">
                        <div class="col-md-2 label">Allergies</div>
                        <div class="col-md-10  mt-2">
                           

                            <mat-form-field class="input-container__input mt-2" appearance="outline">
                                <mat-select
                                formControlName="allergies"
                                multiple="true"
                                placeholder="allergies"
                                [disabled]="!isMedicalEdit"
                              >
                                <mat-option 
                                  *ngFor="let item of allergies"
                                  [value]="item.id"
                                >
                                  {{ item.name }}
                                </mat-option>
                              </mat-select>
                              </mat-form-field>
                        </div>
                    </section>

                    <section class="row align-items-center mt-3">
                        <div class="col-md-2 label">Medical Notes</div>
                        <div class="col-md-10">
                            <app-text-area   [errors]="getMedicalErrors('medical_notes')"
                            [isSubmitted]="isSubmitted"
                            formControlName="medical_notes"
                            [isDisabled]="!isMedicalEdit">

                            </app-text-area>
                            <!-- 
                               
                            ></app-general-input> -->
                        </div>
                    </section>
                    <div class="destinations-btn-box">
                        <button
                            *ngIf="!isMedicalEdit"
                            mat-raised-button
                            color="primary"
                            class="default-btn"
                            (click)="EditPersonalInfo('medical')"
                        >
                            {{ "EDIT" | translate }}
                        </button>
                    </div>
                    <div *ngIf="isMedicalEdit" class="destinations-btn-box">
                        <button
                            mat-flat-button
                            [routerLink]="['/']"
                            class="discard"
                        >
                            {{ "Discard" | translate }}
                        </button>
                        <button
                            mat-raised-button
                            color="primary"
                            class="default-btn"
                            (click)="updateMedicalInfo()"
                            [disabled]="isSubmitted"
                        >
                            {{ "SAVE" | translate }}
                        </button>
             
                    </div>
                </form>
            </div>
          
            <div class="col-lg-7 col-md-12" *ngIf="isSecurity">
         
                    <h3 >
                        {{ "Security" | translate }}
                    </h3>

                    <div class="row">
                
                        <form [formGroup]="securityForm" class="d-flex flex-column mt-4">
                            <section class="row align-items-center">
                                <div class="col-md-2 label">Current Password</div>
                                <div class="col-md-10">
                                    <app-general-input
                                        formControlName="password"
                                        type="password"
                                        [isPassword]="true"

                                        placeholder="Current Password"
                                        [errors]="getSecurityErrors('password')"
                                        [isSubmitted]="isSubmitted"
                                    ></app-general-input>
                                </div>
                            </section>
        
                          
                   
                            <div class="destinations-btn-box">
                                <button
                                    mat-flat-button
                                    [routerLink]="['/']"
                                    class="discard"
                                >
                                    {{ "Discard" | translate }}
                                </button>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="default-btn"
                                    [disabled]="!securityForm?.value.password"
                                    (click)="updatePassword()"
                                >
                                    {{ "Next" | translate }}
                                </button>
                     
                            </div>
                        </form>
                    </div>
                
            </div> 
        <div class="col-7" *ngIf="isPersonal">
                <h3>Personal Information</h3>
                <form
                    [formGroup]="personalForm"
                    class="d-flex flex-column mt-4"
                >
                    <section class="row">
                        <div class="label col-md-2">Photo</div>
                        <div class="col-md-10">
                            <app-image-upload
                                [img]="image"
                                formControlName="avatar"
                                (valueEmittedImage)="
                                    handleImageSelected($event)
                                "
                                [isDisabled]="!isEdit"
                                [showEdit]="isEdit"
                            ></app-image-upload>
                        </div>
                    </section>
                    <section class="row align-items-center">
                        <div class="col-md-2 label">Name</div>
                        <div class="col-md-10">
                            <app-general-input
                                formControlName="name"
                                placeholder="Business name"
                                [errors]="getControlErrors('name')"
                                [isSubmitted]="isSubmitted"
                                [isDisabled]="!isEdit"
                            ></app-general-input>
                        </div>
                    </section>

                    <section class="row align-items-center">
                        <div class="col-md-2 label">{{"Email"| translate}}</div>
                        <div class="col-md-10">
                            <app-general-input
                                [isDisabled]="true"
                                formControlName="email"
                                placeholder="Info@gmail.com"
                                [errors]="getControlErrors('email')"
                                [isSubmitted]="isSubmitted"
                            ></app-general-input>
                            <P>
                            {{"change_email?"| translate}}<span class="link" (click)="changeEmail()">{{"click_here"|translate}}</span>

                            </P>
                        </div>
                    </section>

                    <section class="row align-items-center mt-4">
                        <div class="col-md-2 label">Gender</div>
                        <div class="col-md-10">
                            <mat-radio-group
                                [disabled]="!isEdit"
                                color="primary"
                                formControlName="gender"
                            >
                                <mat-radio-button value="0"
                                    >Male</mat-radio-button
                                >
                                <mat-radio-button value="1"
                                    >Female</mat-radio-button
                                >
                            </mat-radio-group>
                            <app-validation-errors
                                [isSubmitted]="isSubmitted"
                                [errors]="getControlErrors('gender')"
                            ></app-validation-errors>
                        </div>
                    </section>
                    <section class="row align-items-center">
                        <div class="col-md-2 label">Phone Number</div>
                        <div class="col-md-10">
                            <app-general-input
                                [isDisabled]="true"
                                formControlName="phone"
                                placeholder="01xxxxxxxx"
                                [errors]="getControlErrors('phone')"
                                [isSubmitted]="isSubmitted"
                            ></app-general-input>
                            <P>
                                {{"change_phone?"| translate}}<span class="link" (click)="changePhoneNumber()">{{"click_here"| translate}}</span>
    
                                </P>
                        </div>
                    </section>
                    <div class="destinations-btn-box">
                        <button
                            *ngIf="!isEdit"
                            mat-raised-button
                            color="primary"
                            class="default-btn"
                            (click)="EditPersonalInfo('personal')"
                        >
                            {{ "EDIT" | translate }}
                        </button>
                    </div>
                    <div *ngIf="isEdit" class="destinations-btn-box">
                        <button
                            mat-flat-button
                            [routerLink]="['/']"
                            class="discard"
                        >
                            {{ "Discard" | translate }}
                        </button>
                        <button
                            mat-raised-button
                            color="primary"
                            class="default-btn"
                            (click)="updatePersonalInfo()"
                        >
                            {{ "SAVE" | translate }}
                        </button>
                        <!-- color="warn" -->
                        <!-- class="default-btn" -->
                    </div>
                </form>
            </div>
<!-- 
            <div class="col-lg-7 col-md-12" *ngIf="isHistory">
                <app-diagnosis-history></app-diagnosis-history>
            </div> -->

            <section *ngIf="isArticle" class="news-section col-lg-7 col-md-12">
                <div class="section-header">
                    <h2>{{"saved_content" | translate |titlecase}}</h2>
                </div>
                <div *ngIf="items.length==0"> {{"no_saved"| translate}}</div>
                <div *ngFor="let card of items" class="col-lg-3 col-md-6 col-sm-10 mb-2" > 
                <div [routerLink]="['/single-article',card.id]" class="news-cards">
                    <!-- Repeat this card for each news item -->
                    <div class="card ">
                        <img src="./../../../../assets/images/health-library/news.jpeg" alt="Article Image">
                        <div class="p-2">
            
                            <h3>{{card.title}}</h3>
                            <p  style="
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            ">{{card.summary}}</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
    </div>
</section>
