import { Component, OnInit } from '@angular/core';
import { HealthLibraryService } from '../health-library.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent implements OnInit {
type:number
items:any
isArticle:boolean = false
isContnent:boolean =false
  constructor(private apiService:HealthLibraryService,
    private activatedRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.params['id'];
this.viewCategory()
if(this.type == 1 ){
  this.isArticle= true
}else{
  this.isContnent = true
}

  }
viewCategory(){
  this.apiService.viewCategory(this.type).subscribe((res:any)=>{

this.items = res.items
  })

}
}
