import { Component, OnInit } from '@angular/core';
import { HealthLibraryService } from '../health-library.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent implements OnInit {
type:number
items:any
inputValue: string = '';
categoryItems
notFound = false
currentPage =1
isArticle:boolean = false
isContnent:boolean =false
  constructor(private apiService:HealthLibraryService,
    private activatedRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.params['id'];
this.viewCategory()
if(this.type == 1 ){
  this.isArticle= true
}else{
  this.isContnent = true
}

  }
viewCategory(){
  this.notFound = false
  this.items =[]

  this.apiService.viewCategory(this.type,this.currentPage,this.inputValue).subscribe((res:any)=>{
this.categoryItems = res
this.items = res.items
if(this.items.length == 0){
  this.notFound = true
}
  })


}
loadMore(){
this.currentPage++ 
this.apiService.viewCategory(this.type,this.currentPage,this.inputValue).subscribe((res:any)=>{
  this.categoryItems = res

  this.items.push(...res.items)
console.log(this.items)
    })}
}
