


<div mat-dialog-content>
    <div class="form-group mb-2">
        <strong>

            {{'change_phone'|translate}}
        </strong>
        <div class="grey-text">{{"Enter_phone_recieve_otp"|translate}}</div>
        <input
            type="text"
            class="form-control"
            placeholder="Phone"
            name="txtEmail"
            [(ngModel)]="phone"
        />
    </div>
    <div class="destinations-btn-box">
        <button
            mat-raised-button
            color="primary"
            class="default-btn"
            (click)="confirm()"
        >
            {{"submit"| translate}}
        </button>
    </div>
</div>

