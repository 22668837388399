<!-- <app-header-two></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section">
            <h2>
                {{
                    "Contact Us"
                        | translate
                }}
            </h2>
            
            
            
            
            
            
        </div>
        <p>{{ 'write_us_message' | translate }}</p>
       
        <mat-card class="ask-card">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="billing-details">
                            <div class="row">
                              

                                <div class="">
                                 

                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label  >{{ "Email" | translate }}</mat-label>
                                        <input matInput    type="text"
                                        name="txtEmail"
                                        [(ngModel)]="user.email"
                                    />
                                      </mat-form-field>
                                </div>



                                <div class="col-lg-12 col-md-12">
                                 



                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{ "Message" | translate }}</mat-label>
                                        <textarea          type="text"
                                        class="form-control"
                                        name="txtMsg"
                                        [(ngModel)]="user.message"    matInput placeholder=""></textarea>
                                      </mat-form-field>
                                </div>
                                <!-- <input
                                    #fileInput
                                    type="file"
                                    (change)="onChange($event)"
                                    style="display: none"
                                />
                                <div
                                    class="col-lg-12"
                                    style="text-align: center"
                                >
                                    <p *ngIf="fileName">{{ fileName }}</p>
                                </div>

                                <div class="col-lg-12 text-center top-space">
                                    <div class="destinations-btn-box">
                                        <button
                                            mat-raised-button
                                            color="primary"
                                            class="default-btn upload-btn"
                                            (click)="fileInput.click()"
                                        >
                                            {{
                                                "Upload File Or Photo"
                                                    | translate
                                            }}
                                        </button>
                                    </div>
                                </div> -->

                                <div class="col-lg-12 text-center top-space">
                                    <div class="destinations-btn-box">
                                        <button
                                            mat-raised-button
                                            color="primary"
                                            class="default-btn"
                                            (click)="postQuestion()"
                                        >
                                            {{ "Send" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</section>
