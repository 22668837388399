import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "../../../services/modal.service";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-doctor-profile",
    templateUrl: "./doctor-profile.component.html",
    styleUrls: ["./doctor-profile.component.scss"],
})
export class DoctorProfileComponent implements OnInit {
    params$;
    doctor: any = {};
    reviews: any[] = [];
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth();
    minDate = new Date(this.currentYear);
    maxDate = new Date(this.currentYear);
    id;
    prevTime = undefined;
    selectedTime = undefined;
    times: any[] = [];
    private _selected: Date;
    get selected(): Date {
        return this._selected;
    }
    set selected(value: Date) {
        console.log(value);
        this._selected = value;
        let val = this._selected;
        let date = this.datePipe.transform(val, "dd/MM/yyyy");
        console.log(date);
        this.getSessionTimes(this.id, date);
    }

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private modal: ModalService,
        private router: Router
    ) {
        this.params$ = this.route.params.subscribe((params) => {
            this.id = Number(params["id"]);
            if (this.id !== undefined) {
                this.fillDoctor(this.id);
                this.fillReviews(this.id);
            }
        });

        this.getSessionTimes(
            this.id,
            this.datePipe.transform(new Date(), "dd/MM/yyyy")
        );
    }

    ngOnInit(): void {}

    fillDoctor(id) {
        this.apiService.getDoctorByID(id).subscribe((res) => {
            if (!res) return;
            this.doctor = res["data"];
        });
    }

    fillReviews(id) {
        this.apiService.getDoctorReviews(id).subscribe((res) => {
            this.reviews = res["data"]["items"];
        });
    }

    getSessionTimes(id, date) {
        this.apiService.getDoctorSessionByDate(id, date).subscribe((res) => {
            if (!res) return;
            let available: any[] = res["data"]["available"];
            let notAvailable: any[] = res["data"]["not_available"];
            this.times = [...available, ...notAvailable];
            console.log(this.times);
        });
    }

    setSelectedTime(obj) {
        if (obj.status != 0) return;
        obj.status = 1;
        if (this.selectedTime == undefined) {
            this.selectedTime = obj;
            this.prevTime = undefined;
        } else {
            this.prevTime = this.selectedTime;
            this.selectedTime = obj;

            this.times.find((res) => res.id == this.prevTime.id).status = 0;
        }
    }

    bookSession() {
        if (!this.selectedTime) {
            this.modal.snackMessage("Please select session time");
            return;
        }
        this.apiService.postSession(this.selectedTime.id).subscribe(
            (res) => {
                this.modal.snackMessage(
                    "Session booked successfully at the selected time"
                );
                localStorage.setItem("session", JSON.stringify(res["data"]));
                this.router.navigate([
                    "/subscribe",
                    {
                        firstTabName: "Find Doctor",
                        sessionID: res["data"]["id"],
                    },
                ]);
            },
            (error) => {
                this.modal.snackMessage(error["error"].error);
            }
        );
    }
}
