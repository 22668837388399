<!-- <app-header-two></app-header-two> -->

<section class="news-section row">
    <div class="section-header">
        <h2>{{(type == 1? "HEALTH_ARTICLES": type == 2?"MEDICATIONS":type ==3 ?"Diet Plans": type ==4? "Dentistry": type ==5?"PSYCHIATRY":type == 6?"VACCINATIONS":type==7? "DISEASES":"") | translate}}</h2>
    </div>

    <div class="search-container mb-2">
        <mat-form-field   (keyup.enter)="viewCategory()"  class="wide-input" appearance="outline">
          <input
            [(ngModel)]="inputValue"
            matInput
            placeholder="Search"
            class="search-input"
          />
          <img (click)="viewCategory()"
            src="./../../../../assets/images/search.svg"
            alt="Search"
            class="search-icon"
          />
        </mat-form-field>
      </div>
      
      
      <div *ngFor="let card of items " class="col-lg-3 col-md-6 col-sm-12 mb-2" > 
        <div *ngIf="!notFound" >

    <div *ngIf="isArticle"[routerLink]="['/single-article',card.id]" class="news-cards">
        <!-- Repeat this card for each news item -->
        <div class="card ">
            <img [src]="card.image" alt="Article Image">
            <div class="p-2">

                <h3 class="h3-title">{{card.title}}</h3>
                <p  style="
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-top: 5px;
">{{card.summary}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="isContnent" [routerLink]="['/single-content',card.id]" class="news-cards">
        <!-- Repeat this card for each news item -->
        <div class="card ">
            <!-- <img src="./../../../../assets/images/health-library/news.jpeg" alt="Article Image"> -->
            <div class="p-4">

                <h3 class="content-title">{{card.title}}</h3>
                <p  style="
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-top: 5px;

">{{card.summary}}</p>
            </div>
        </div>
    </div>
    </div>
    
</div>
<div class="load" *ngIf="!notFound &&!(categoryItems.total == categoryItems.to)" (click)="loadMore()">{{'load_more'| translate}} </div>    
    <div class="not-found" *ngIf="notFound">
        <img src="./../../../../assets/images/health-library/not_found.png">
       <div>
    
           {{'no_content_available'| translate}}
       </div> 
    </div>
</section>