import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HealthLibraryService {

  constructor(private httpClient: HttpClient) { }
  getRatings(id:number,currentPage?: number) {
    return this.httpClient.get(
        `${environment.baseUrl}` +
            `/api/contents/${id}/reviews` +
           
            (currentPage ? "&page=" + `${currentPage}` : "")
    );
}

viewCategory(type:number, page=1,input='') {
  return this.httpClient.get(
      `${environment.baseUrl}` +
          `/api/contents/type/${type}?`+`language=${
            localStorage.getItem("lang")
                ? localStorage.getItem("lang")
                 : "ar"
        }`+`&page=${page}`+`&search=${input }`
  );
}

getHomeData(){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/contents`
);
}

getHealthLibrary(input=''){
  const    encodedValue = encodeURIComponent(input)

  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/contents?`+`language=${
                        localStorage.getItem("lang")
                            ? localStorage.getItem("lang")
                             : "ar"
                    }`+`&search=${encodedValue }`
);
}
getSingleArticle(id:number){
  return this.httpClient.get(
    `${environment.baseUrl}` +
        `/api/contents/${id}?`+`language=${
                        localStorage.getItem("lang")
                            ? localStorage.getItem("lang")
                             : "ar"
                    }`
);
}


toggleSave(id:number){

  return this.httpClient.post(
    `${environment.baseUrl}` +
        `/api/contents/${id}/fav
`,{}
)
}

postRating(id:number,body){

  return this.httpClient.post(
    `${environment.baseUrl}` +
        `/api/contents/${id}/reviews
`,body
)
}

}
