import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    AfterViewInit
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-news-list",
    templateUrl: "./news-list.component.html",
    styleUrls: ["./news-list.component.scss"],
})
export class NewsListComponent implements OnInit, OnDestroy,AfterViewInit {
    @ViewChild("selectedValue") selectedValue: MatSelect;
    news: any[] = [];
    private _lstLength: number;
    set lstLength(value: number) {
        this._lstLength = value;
    }
    get lstLength(): number {
        return this._lstLength;
    }

    currentPage = 0;
    pageSize = 12;
    categoryID: number;
    prevCategoryID: number;
    categories: any[] = [];
    constructor(
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute,
        private refDet: ChangeDetectorRef
    ) {
        this.fillNewsCategories();
        this.route.queryParams.subscribe((params) => {
            console.log(params);
            if (params["categoryID"]) {
                this.categoryID = Number(params["categoryID"]);
                
                this.fillSubNews(this.categoryID);
            } else {
                this.fillNews();
            }
        });
    }

    ngOnInit(): void {}
    ngAfterViewInit(): void {
       
    }

    fillSubNews(catID, currentPage?: number) {
        this.apiService.getSubNews(catID, currentPage).subscribe((res) => {
            console.log(res);
            this.news = res["data"]["items"];
            this.lstLength = res["data"].total_items_count;
            console.log(this.news);
        });
    }

    fillNews(currentPage?: number) {
        this.apiService.getNews(currentPage).subscribe((res) => {
            console.log(res);
            this.news = res["data"]["items"];
            this.lstLength = res["data"].total_items_count;
            console.log(this.news);
        });
    }

    navigateToViewNew(newObj) {
        console.log(newObj);
        this.router.navigate(["/single-new", { id: newObj.id }], {
            relativeTo: this.route,
        });
    }

    setPageChangesEvents(e) {
        this.currentPage = e.pageIndex;
        console.log("current page  => " + this.currentPage);
        this.pageSize = e.pageSize;
        if (this.currentPage > 0) {
            if (this.categoryID)
                this.fillSubNews(this.categoryID, this.currentPage + 1);
            else this.fillNews(this.currentPage + 1);
            return;
        } else {
            if (this.categoryID) this.fillSubNews(this.categoryID);
            else this.fillNews();
        }
        // this.iterator();
    }

    fillNewsCategories() {
        this.apiService.getNewsCategories().subscribe((res: any) => {
            console.log(res);
            this.categories = res["data"];
            console.log(this.categories);
            this.refDet.detectChanges();
        });
    }

    filterByCategoryID(e) {
        console.log(e);
        this.categoryID = e.value;
        this.fillSubNews(this.categoryID);
    }

    ngOnDestroy() {
        this.selectedValue.value = undefined;
    }

    clearNewsFilter(e){
        this.selectedValue.value = undefined
    }
}
