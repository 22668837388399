import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
    Output,
    EventEmitter,
} from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SwitchLanguageService } from "../../../services/switch-language.service";
import * as $ from "jquery";
@Component({
    selector: "app-header-two",
    templateUrl: "./header-two.component.html",
    styleUrls: ["./header-two.component.scss"],
})
export class HeaderTwoComponent implements OnInit, AfterViewInit {
    @ViewChild("arRef") arRef: ElementRef<HTMLElement>;
    @Output() notifyRouterChange: EventEmitter<any> = new EventEmitter();
    lang: string = "";
    isLoggedIn: boolean;
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private switchLang: SwitchLanguageService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private renderer: Renderer2
    ) {
        this.router.events.subscribe((res) => {
            console.log("res");
            this.notifyRouterChange.emit();
        });
        this.matIconRegistry.addSvgIcon(
            "search",
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                "assets/img/search-svgrepo-com.svg"
            )
        );
        this.lang = this.switchLang.getCurrentLang();
        if (localStorage.getItem("token")) this.isLoggedIn = true;
        else this.isLoggedIn = false;
    }

    ngOnInit(): void {}

    ngAfterViewInit() {
        // $('.lang').on('click', (evt) => {
        //   const target = evt.target
        //   console.log('test')
        // });
        // $(".lang").on("click", function(){
        //    console.log('ssssssss')
        // });
        // this.renderer.listen(document.getElementsByClassName('lang'), 'click', () => {
        //     alert('Buton was clicked');
        //   });
    }
    changeLang(lang) {
        console.log(lang);
        this.switchLang.ChangeLanguage(lang);
        this.lang = this.switchLang.getCurrentLang();
        this.changeDetectorRef.detectChanges();
        window.location.reload();
    }

    signOut() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.router.navigate(["/login"]);
    }
    test() {
        console.log("test");
    }

    reload(tabName) {
        // this.router.navigate(['/'])
        // window.location.reload()
        this.router.navigate(["/"])
        console.log(this.router.url);
        // if (tabName == "logo" || (this.router.url == "/" && tabName == "home"))
        //     window.location.reload();
        // else if (tabName == "home") ;
    }

    routeToProfile(){
        console.log("ppp")
        this.router.navigate(['./patient-profile'])
    }
}
