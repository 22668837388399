<!-- <app-header-two></app-header-two> -->



<header class="hero-header">
    <h1>{{'Health Library'| translate}}</h1>
    <p>{{'HEALTH_EXPLORE'| translate}}</p>
</header>
<div class="body">
    <!-- <hr> -->
    <!-- <section class="articles-section row">
        <div class="section-header">
            <h2>Articles</h2>
            <a routerLink="/view-category/1/Health" class="">View All</a>

        </div>
        
        <div class="article-cards row">
            <div *ngFor="let item of categorizedData.news | slice:0:3" class=" col-lg-3 col-md-6 col-sm-9">
                    <div class="card ms-3" [routerLink]="['/single-article',item.id]"> 
                    <img [src]="item.image" alt="Article Image">
                    <div class="p-2">
                        <h3>{{item.title |translate}}</h3>
                        <p>{{item.summary}}</p>
                        <span class="metadata">3 min read • 22 June 2024</span>
                    </div>
                   
                </div>
            </div>
            
        </div>
    </section> -->
    <div class="search-container">
        <mat-form-field   (keyup.enter)="getHealthLibrary()"  class="wide-input" appearance="outline">
          <input
            [(ngModel)]="inputValue"
            matInput
            placeholder="Search"
            class="search-input"
          />
          <img (click)="getHealthLibrary()"
            src="./../../../../assets/images/search.svg"
            alt="Search"
            class="search-icon"
          />
        </mat-form-field>
      </div>
      <div *ngIf="!notFound">
    <section class="news-section" *ngIf="categorizedData.news.length != 0">
        <div class="section-header">
            <h2 class="ps-2">{{'HEALTH_ARTICLES' | translate}}</h2>
            <a routerLink="/view-category/1">{{'VIEW_ALL'| translate}}</a>
        </div>
    

        <div class="article-cards row">
            <!-- Repeat this card for each news item -->
            <div *ngFor="let item of categorizedData.news |slice:0:3 "  class=" col-lg-3 col-md-6 col-sm-10">
                <div class="card"  [routerLink]="['/single-article',item.id]">
                <img [src]="item.image" alt="Article Image">
                <div class="p-2">

                    <h3 >{{item.title}}</h3>
                    <p>{{item.summary}}</p>
                </div>
            </div>
        </div>
        </div>
    </section>

    <section class="diseases-section" *ngIf="categorizedData.diseases.length != 0">
        <div class="section-header">
            <h2 class="ps-2">{{'DISEASES'|translate}}</h2>
            <a  routerLink="/view-category/7">{{'VIEW_ALL'| translate}}</a>
        </div>
        <div class="disease-items d-flex  row">

            <div *ngFor="let item of categorizedData.diseases" [routerLink]="['/single-content',item.id]"class="disease-item col-5 col-sm-9">
                <h3>{{item.title}}</h3>
                <p>{{item.summary}}</p>
            </div>
<div *ngIf="categorizedData.diseases.length == 0"> {{'NO_DISEASES'| translate}}</div>
        </div>
    </section>

    <!-- <section class="diet-plans-section">
        <div class="section-header">
            <h2 class="ps-2">Diet Plans</h2>
            <a routerLink="/view-category/3">View All</a>
        </div>
        <div class="diet-cards row">
            <div *ngFor="let item of categorizedData.dietPlan"  class=" col-lg-4 col-md-5 col-sm-9">
                <div class="diet-card" [routerLink]="['/single-content',item.id]">
                <h3 class="diet-title">{{item.title}}</h3>
                <div class="sub-title"><small>Education company</small></div>
                <p   style="
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;">{{item.summary}}</p></div>
        </div>
        </div>
    </section> -->

    <section *ngIf="categorizedData.medication.length != 0" class="medications-section">
        <div class="section-header">
            <h2 class="ps-2">{{'MEDICATIONS'|translate}}</h2>
            <a routerLink="/view-category/2">{{'VIEW_ALL'| translate}}</a>
        </div>
        <div class="row ">
            <div *ngFor="let item of categorizedData.medication"  [routerLink]="['/single-content',item.id]"  class="medication-info col-md-6 col-sm-7 col-lg-5 me-3">
<h3 class="med-title">{{item.title}}</h3>
                <p>{{item.summary}}</p>
     </div>
        
          
                   </div>     
    </section>


    
    <section *ngIf="categorizedData.vaccination.length != 0" class="vaccination-section">
        <div class="section-header">
            <h2 class="ps-2">{{'VACCINATIONS'|translate}}</h2>
            <a routerLink="/view-category/6">{{'VIEW_ALL'| translate}}</a>
        </div>
        <div class="row ">
            <div *ngFor="let item of categorizedData.vaccination" [routerLink]="['/single-content',item.id]" class="medication-info col-md-6 col-sm-7 col-lg-5 ">
<h3 class="med-title">{{item.title}}</h3>
                <p>{{item.summary}}</p>
     </div>
        
            
                   </div>     
    </section>

    <section *ngIf="categorizedData.psychiatry.length != 0" class="dentistry-section">
        <div class="section-header">
            <h2 class="ps-2">{{'PSYCHIATRY'| translate}}
            </h2>
            <a routerLink="/view-category/5">{{'VIEW_ALL'| translate}}</a>
        </div>
        <div class="dentistry-cards row">
            
            <div *ngFor="let item of categorizedData.psychiatry"   class=" col-lg-3 col-md-4 col-sm-5   ">
                <div class="dentistry-card"[routerLink]="['/single-content',item.id]">
                    <div style="height:3rem">

                        <h4>{{item.title}}</h4>
                    </div>
                    <p>{{item.summary}}</p></div>
    
                </div>  
        </div>
    </section>
</div>


<div class="not-found" *ngIf="notFound">
    <img src="./../../../../assets/images/health-library/not_found.png">
   <div>

       {{'no_content_available'| translate}}
   </div> 
</div>
</div>


