import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-ai',
  templateUrl: './home-ai.component.html',
  styleUrls: ['./home-ai.component.scss']
})
export class HomeAiComponent implements OnInit {
gender
  constructor() { }

  ngOnInit(): void {
  this.gender =  localStorage.getItem('user_doctor')
  console.log(this.gender)
  }

}
