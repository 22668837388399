<div class="wrapper d-flex flex-column align-items-center">
  <div class="d-flex w-100 justify-content-end mb-4">
    <!-- <div
      (click)="dialogRef.close()"
    >X</div> -->
  </div>
  <h3 class="title mb-4">{{'areYouSure'| translate}} {{ data.entity |translate }} ?</h3>
  <p>{{ data.note }}</p>
  <div class="buttons d-flex align-items-center justify-content-center gap-4">
    <div class="cancel" (click)="dialogRef.close(false)">{{'Cancel'| translate}}</div>
    <div class="delete" (click)="dialogRef.close(true)">{{ data.action | translate }}</div>
  </div>
</div>
