import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HealthLibraryService } from '../health-library.service';
import { ShareLinkComponent } from '../share-link/share-link.component';
import { MatDialog } from '@angular/material/dialog';
import * as enTranslations from '../../../../../assets/i18n/en.json'; 
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RatingDialogComponent } from '../../rating-dialog/rating-dialog.component';
import { ModalService } from 'src/app/services/modal.service';
import { ApiService } from 'src/app/services/api.service';
import { DeleteDialogComponent } from 'src/app/components/common/shared/components/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-single-article',
  templateUrl: './single-article.component.html',
  styleUrls: ['./single-article.component.scss']
})
export class SingleArticleComponent implements OnInit {
type:number
ratings
review:any
oneStars
twoStars
threeStars
isMyIdNotPresent = true
fourStars
fiveStars
articles:any =[]
keys:any
content
filteredDetails: any= [];
translationKeys = Object.keys(enTranslations); 
id;

  constructor(private apiService:HealthLibraryService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translateService: TranslateService,
  private sanitizer: DomSanitizer,
  private translate: TranslateService,

    private modal: ModalService,
    private profileService: ApiService



  ) { }
  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.params['id'];
this.getArticle()
this.getRatings()
this.getProfile()
console.log(this.filteredDetails,"article")}
getProfile(){
  this.profileService.getProfile().subscribe((res:any) => {
    this.id = res.id
  })

}
getRatings(){
this.isMyIdNotPresent = true
  this.apiService.getRatings(this.type).subscribe((res:any)=>{
    this.ratings = res
    this.review = res.items
    this.oneStars = +(+this.ratings.one_stars / this.ratings.reviews_count) * 100
    console.log
    this.twoStars = +(this.ratings.two_stars / this.ratings.reviews_count) * 100
    this.threeStars = +(this.ratings.three_stars / this.ratings.reviews_count) * 100
    this.fourStars = +(+this.ratings.four_stars / +this.ratings.reviews_count) * 100
    this.fiveStars = +(this.ratings.five_stars / this.ratings.reviews_count) * 100
     this.isMyIdNotPresent = !this.review?.some((item) => +item.user.id === +this.id);
     console.log(this.isMyIdNotPresent, this.id)

  })
}
addReview(){
const dialog =  this.dialog.open(RatingDialogComponent, {
    panelClass: 'terms-conditions-dialog-panel',
    width: '60vw',
    minWidth: '400px',
    data: {
      entity:  this.type
  }})
 dialog.afterClosed().subscribe((result) => {
    this.getRatings()

      
    });
}
editReview(review){
  const dialog =  this.dialog.open(RatingDialogComponent, {
    panelClass: 'terms-conditions-dialog-panel',
    width: '60vw',
    minWidth: '400px',
    data: {
      review: review,
      articleId : this.type
  }})
 dialog.afterClosed().subscribe((result) => {
    this.getRatings()

      
    });

}
toggleSave(status){
  this.apiService.toggleSave(this.type).subscribe((res)=>{
    console.log(res)

    this.getArticle()
    if(status){
      this.modal.snackMessage(this.translate.instant('articleRemovedSuccess'))
      
    }else{
      this.modal.snackMessage(this.translate.instant('articleSavedSuccess')
    );

    }
  })
}
getArticle(){
  this.apiService.getSingleArticle(this.type).subscribe((res:any)=>{
    
    this.content =res
    this.articles= res.details
    for (let i = 0; i < this.articles.length; i++) {
      const pattern = /(_nbsp_|__nbsp__|__ nbsp __| .__ NBSP__|_ nbsp _ |_ nbsp _  |: _ nbsp _|: __ nbsp__|NBSP|.__ nbsp__| __ nbsp__| .__ & nbsp __|nbsp __)/g;

      console.log(this.articles,'article-new')
      this.articles[i].Content =
      this.articles[i].Content = this.articles[i].Content.replace(pattern, '&nbsp;');}
})

}

    



openDialog(){
    const dialog = this.dialog.open(ShareLinkComponent, {
      panelClass: 'delete-dialog-panel',
      disableClose: false,
      width: '70vw',
      minWidth: '100px',
      // data: { entity: e, customer_id: e.id },
    });

    dialog.afterClosed().subscribe((result) => {
    this.getRatings()

      
    });
  
}

deleteReview(reviewId){
this.apiService.deleteReview(this.type,reviewId).subscribe((res)=>{
  this.getRatings()
  this.modal.snackMessage('Review deleted Successfully')
})
}

deleteTerm(reviewId) {
    const dialog = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'delete-dialog-panel',
      width: '35vw',
      minWidth: '500px',
      data: { entity: this.translate.instant('delete your comment'), action: 'Delete' },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
  this.deleteReview(reviewId)
       
      }
    });
  }
}
