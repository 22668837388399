import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-date-picker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent implements OnInit {
  selected: Date | null;
    constructor(
        public dialogRef: MatDialogRef<DatePickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    confirm() {
        let res = true;
        this.dialogRef.close(res);
    }

    cancel() {
        let res = false;
        this.dialogRef.close(res);
    }
}
