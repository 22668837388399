<div mat-dialog-content>
    <div class="form-group">
        <!-- <label
            >{{
                "Add New Password" | translate
            }}
            <span class="required"
                >*</span
            ></label
        >
        <input
            type="password"
            class="form-control"
            placeholder="New Password"
            name="txtPassword"
            [(ngModel)]="password"
        /> -->
   
        <form [formGroup]="newPwForm" class="d-flex flex-column mt-4">

        <section class="row align-items-center">
            <div class="col-md-2 label">New Password</div>
            <div class="col-md-10">
                <app-general-input
                #password
                    formControlName="new_password"
                    type="password"
                    [isSubmitted]="isSubmitted" 
                    [isAuthInput]="true"
                    [errors]="getSecurityErrors('new_password')"
                    [isPassword]="true"
                    placeholder="New Password"
                    ></app-general-input>
                    <!-- [errors]="getSecurityErrors('new_password')"-->
            </div>
        </section>
        <section class="row align-items-center">
            <div class="col-md-2 label">Confirm Password</div>
            <div class="col-md-10">
                <app-general-input
                    formControlName="password_confirmation"
                    type="password"
                    [isPassword]="true"
                    [isSubmitted]="isSubmitted" 
                    [isAuthInput]="true"
                    [errors]="getSecurityErrors('password_confirmation')"
                    placeholder="Confirm Password"
                    [mismatchError]="getPasswordMismatchError()"

                    ></app-general-input>
                 
            </div>
        </section>
   
        <div class="destinations-btn-box">
            <button
            mat-raised-button
            color="primary"
            class="default-btn"
            (click)="confirm()"
            >
            Submit
        </button>
    </div>
</form>
</div>
</div>

