<!-- <app-header-two></app-header-two> -->

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12" style="margin-top: -30px">
                <img [src]="initiative?.image?.url" width="100%" />
            </div>

            <div class="col-lg-12 text-left" style="margin-top: 15px">
                <h2>Covid Can Rebound !!!</h2>
            </div>
            <div class="col-lg-12 text-left" style="margin-top: 5px">
                <span class="view">
                    <mat-icon svgIcon="view"></mat-icon>
                    {{ initiative.views_count }}</span
                >
                <span class="comment">
                    <mat-icon svgIcon="comment"></mat-icon>
                    {{ initiative.shares_count }}</span
                >
            </div>

            <div class="col-lg-12 text-left" style="margin-top: 10px">
                <p class="description" [innerHtml]="initiative.text"></p>
            </div>
        </div>
    </div>
</section>
