<section class="reset-password">
    <div class="reset-password__container">
        <div class="x" >x</div>
        <div>
            <h4>OTP Code</h4>
        </div>

        <p>
            A 6-digit verification code has been sent to your {{ data?.entity }}
        </p>
        <div class="resend">{{ transform(counter) }} Second</div>

        <code-input
            [codeLength]="6"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
            [initialFocusField]="0"
        >
        </code-input>
<button (click)="sendOtp()">
    Confirm</button>
        <div class="otp">
            <span>OTP Code Not Sent</span>
            <span
                class="green"
                (click)="resend()"
                [ngClass]="{ active: !disabled }"
                >Re-Sent Code
            </span>
        </div>
    </div>
</section>
