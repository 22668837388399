import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  isBox1Active: boolean = true;
type =1
  constructor(private apiService:ApiService,          private router: Router,
    private modal: ModalService,
  ) { }

  ngOnInit(): void {
  }
chooseDr(type:number){
  this.isBox1Active = !this.isBox1Active;
this.type = type
}
confirmDR(){
  this.apiService.assignDr(this.type).subscribe((res)=>{
    this.modal.snackMessage("Doctor Assigned ");
    this.router.navigate(['/ai-home']);

  })
}
}
