

export class User{
    name : string = '';
    email : string = '';
    phone : string = '';
    dob : string = '';
    password : string = '';
    gender : string = '0';


}