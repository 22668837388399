import { Component, Input, forwardRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import {
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
} from "@angular/forms";
import { ValidationErrorsComponent } from "../../shared/components/validation-errors/validation-errors.component";
import { DATE_FORMATS_PROVIDER } from "../../shared/constants/date-formate";
import { GeneralInputsAccessor } from "../../shared/classes/GeneralInputsAccessor";

@Component({
    selector: "app-date-input",
    standalone: true,
    templateUrl: "./date-input.component.html",
    styleUrls: ["./date-input.component.scss"],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationErrorsComponent,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true,
        },
        DATE_FORMATS_PROVIDER,
    ],
})
export class DateInputComponent extends GeneralInputsAccessor {
    @Input() isSubmitted = false;
    @Input() errors!: ValidationErrors | null;
    @Input() placeholder!: string;
    @Input() maxDate!: string;
    @Input() isDisabled!: boolean;
}
