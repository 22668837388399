import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-email-dialog",
    templateUrl: "./email-dialog.component.html",
    styleUrls: ["./email-dialog.component.scss"],
})
export class EmailDialogComponent implements OnInit {

    email : string = ''
    constructor(
        public dialogRef: MatDialogRef<EmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    confirm() {
        let res = true;
        this.dialogRef.close({email : this.email});
    }

    cancel() {
        let res = false;
        this.dialogRef.close(res);
    }
}
