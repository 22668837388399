<!--  


<div class="container-fluid">

<div class="body row">
<div class="section col-md-6 col-lg-7 col-sm-5 mr-auto " style="padding-left: 4rem; padding-right: 4rem;">

<h1 class="actions"><span class="blue-text">{{'hi_Iam' | translate}}
  {{(gender == 2?'AVA':'Aiden') | translate}} !
</span><br>
    {{'how_to_help'|translate}}</h1>
    <br>

<div class="actions">
    <p class="display">{{'choose_one'| translate}}</p>

    <div class="d-flex gap-2 mb-3">
        <div> <button routerLink="/ai-diagnosis">{{'get_ai_diagnosis'| translate}}</button></div>
        <div> <button routerLink="/ask-ai">{{'ask'| translate}} {{(gender == 2?'AVA':'Aiden')| translate}}</button></div>
    </div>
</div>


    </div>
    <div class="section col-md-5 col-lg-5 col-sm-6 ml-auto">

        <div *ngIf="gender ==1" class="circle">
            <img src="./../../../../assets/home/Object.png
            ">
        </div>
        <div *ngIf="gender ==2" class="circle">
            <img src="./../../../../assets/home/Object (1).png">
        </div>
    </div>

</div>
</div>  -->



<div class="container-fluid">

    <div class="body d-lg-flex">
    <div class="section ">
    
    <h1 class="actions"><span class="blue-text">{{'hi_Iam' | translate}}
      {{(gender == 2?'AVA':'Aiden') | translate}}!
    </span><br>
        {{'how_to_help'|translate}}</h1>
        <br>
    
    <div class="actions">
        <p>{{'choose_one'| translate}}</p>
    
        <div class="d-flex gap-2 mb-3">
            <div> <button routerLink="/ai-diagnosis"><img src="./../../../../assets/home/search.svg">   {{'get_ai_diagnosis'| translate}}</button></div>
            <div> <button routerLink="/health-library"><img src="./../../../../assets/home/health.svg">   {{'health_library'| translate}}</button></div>
            <div> <button routerLink="/ask-ai"> <img src="./../../../../assets/home/ask.svg">  {{'ask'| translate}} {{(gender == 2?'AVA':'Aiden')| translate}}</button></div>
        </div>
    </div>
    
    
        </div>
    <div class="section">
    
            <div *ngIf="gender ==1" class="circle">
                <img class="web" src="./../../../../assets/home/Object.png">
                <img class="mobile" src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
            </div>
            <div *ngIf="gender ==2" class="circle">
                <img class="web" src="./../../../../assets/home/Object (1).png">
                <img class="mobile" src="./../../../../assets/home/AI_Ava-removebg-preview.png">
            </div>
        </div>
    
    </div>
    </div> 