import { ApiService } from 'src/app/services/api.service';
import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { ChartConfiguration, ChartOptions } from "chart.js";
@Component({
  selector: 'app-saved-content',
  templateUrl: './saved-content.component.html',
  styleUrls: ['./saved-content.component.scss']
})
export class SavedContentComponent implements OnInit {
  items;

  constructor(        private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.getSavedContent()
  }
  getSavedContent(){
    this.apiService.getSavedContent().subscribe((res:any)=>{
        this.items = res.items
    })
}
}
