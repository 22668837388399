import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../services/api.service";
import { ModalService } from "../../../services/modal.service";
import { DatePickerComponent } from "../../common/date-picker/date-picker.component";
import { EmailDialogComponent } from "../../common/email-dialog/email-dialog.component";
import { PasswordDialogComponent } from "../../common/password-dialog/password-dialog.component";
import { PhoneNumberDialogComponent } from "../../common/phone-number-dialog/phone-number-dialog.component";
import { PhoneNumberVerificationDialogComponent } from "../../common/phone-number-verification-dialog/phone-number-verification-dialog.component";
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { ChartConfiguration, ChartOptions } from "chart.js";

@Component({
    selector: "app-patient-profile",
    templateUrl: "./patient-profile.component.html",
    styleUrls: ["./patient-profile.component.scss"],
})
export class PatientProfileComponent implements OnInit {
    isMedicalFile: boolean = false;
    isPersonal: boolean = true;
    isSecurity: boolean = false;
    isArticle = false;
items;
    isMedicalConsultation: boolean = false;
    isSettings: boolean = true;
    isPaymentHistory: boolean = false;
    lang: string = "";
    isSessions: boolean = false;
    isDisabled = false;
    isWallet: boolean = false;
    isHistory:boolean =  false
    personalForm!: FormGroup;
    medicalForm!: FormGroup;
    securityForm!: FormGroup;
    image = "../../../../../../../../assets/img/avatar.jpg";
    isSubmitted = false;
    isEdit: boolean = false;
    medicalFile: any = {};
    setting: any = {};
    loading: boolean = false;
    questions: any[] = [];
    isMedicalEdit: boolean = false;
    imageChanged:boolean = false;
    percentage:number = 0
    // https://stackblitz.com/github/santam85/ng2-charts-pie-template?file=src%2Fapp%2Fapp.component.ts&preset=node
    public doughnutChartDatasets: ChartConfiguration<"doughnut">["data"]["datasets"]; 

    public doughnutChartOptions: ChartConfiguration<"doughnut">["options"] = {
        responsive: true,
        cutout: "80%",
        plugins: {
            legend: {
                display: false,
            },
        },
    };
    private _allowNotification: boolean = false;
    get allowNotification(): boolean {
        return this._allowNotification;
    }
    set allowNotification(value: boolean) {
        console.log(value);
    }

    private _autoRenewal: boolean = false;
    get autoRenewal(): boolean {
        return this._autoRenewal;
    }
    set autoRenewal(value: boolean) {
        console.log(value);
    }

    private _selectedLang: string = localStorage.getItem("lang");
    get selectedLang(): string {
        return this._selectedLang;
    }
    set selectedLang(value: string) {
        console.log(value);
    }

    upcomingSessions: any[] = [];
    pastSessions: any[] = [];
    bloodTypes=['A+',
        'A-',
        'B+',
       ' B-',
       ' AB+',
        'AB-',
        'O+',
        'O-'
        ]
         longTermDiseases = [
            { id: 1, name: 'Diabetes', name_ar: 'داء السكري' },
            { id: 2, name: 'Hypertension', name_ar: 'ارتفاع ضغط الدم' },
            { id: 3, name: 'Asthma', name_ar: 'الربو' },
            { id: 4, name: 'Chronic Kidney Disease', name_ar: 'مرض الكلى المزمن' },
            { id: 5, name: 'Chronic Obstructive Pulmonary Disease (COPD)', name_ar: 'مرض الانسداد الرئوي المزمن' },
            { id: 6, name: 'Cardiovascular Disease', name_ar: 'أمراض القلب والأوعية الدموية' },
            { id: 7, name: 'Arthritis', name_ar: 'التهاب المفاصل' },
            { id: 8, name: 'Cancer', name_ar: 'السرطان' },
            { id: 9, name: 'HIV/AIDS', name_ar: 'فيروس نقص المناعة البشرية / الإيدز' },
            { id: 10, name: 'Hepatitis', name_ar: 'التهاب الكبد' },
            { id: 11, name: 'Thyroid Disorder', name_ar: 'اضطراب الغدة الدرقية' },
            { id: 12, name: 'Epilepsy', name_ar: 'الصرع' },
            { id: 13, name: 'Multiple Sclerosis', name_ar: 'التصلب المتعدد' },
            { id: 14, name: 'Alzheimer’s Disease', name_ar: 'مرض الزهايمر' },
            { id: 15, name: 'Parkinson’s Disease', name_ar: 'مرض باركنسون' }
          ];
          

             surgeries = [
                { id: 1, name: 'Appendectomy', name_ar: 'استئصال الزائدة الدودية' },
                { id: 2, name: 'C-section', name_ar: 'الولادة القيصرية' },
                { id: 3, name: 'Coronary Artery Bypass', name_ar: 'جراحة مجازة الشريان التاجي' },
                { id: 4, name: 'Hip Replacement', name_ar: 'استبدال مفصل الورك' },
                { id: 5, name: 'Knee Replacement', name_ar: 'استبدال الركبة' },
                { id: 6, name: 'Hernia Repair', name_ar: 'إصلاح الفتق' },
                { id: 7, name: 'Gallbladder Removal', name_ar: 'استئصال المرارة' },
                { id: 8, name: 'Spinal Surgery', name_ar: 'جراحة العمود الفقري' },
                { id: 9, name: 'Tonsillectomy', name_ar: 'استئصال اللوزتين' },
                { id: 10, name: 'Cataract Surgery', name_ar: 'جراحة الساد' },
                { id: 11, name: 'Hysterectomy', name_ar: 'استئصال الرحم' }
              ];
              
             familyHistory = [
                { id: 1, name: 'Diabetes', name_ar: 'داء السكري' },
                { id: 2, name: 'Heart Disease', name_ar: 'أمراض القلب' },
                { id: 3, name: 'Stroke', name_ar: 'السكتة الدماغية' },
                { id: 4, name: 'Hypertension', name_ar: 'ارتفاع ضغط الدم' },
                { id: 5, name: 'Cancer (Breast, Colon, Prostate, etc.)', name_ar: 'السرطان (الثدي، القولون، البروستاتا، إلخ)' },
                { id: 6, name: 'Alzheimer\'s Disease', name_ar: 'مرض الزهايمر' },
                { id: 7, name: 'Parkinson\'s Disease', name_ar: 'مرض باركنسون' },
                { id: 8, name: 'Asthma', name_ar: 'الربو' },
                { id: 9, name: 'Osteoporosis', name_ar: 'هشاشة العظام' },
                { id: 10, name: 'Mental Health Disorders (Depression, Schizophrenia, etc.)', name_ar: 'اضطرابات الصحة العقلية (الاكتئاب، الفصام، إلخ)' },
                { id: 11, name: 'Obesity', name_ar: 'السمنة' },
                { id: 12, name: 'Kidney Disease', name_ar: 'أمراض الكلى' }
              ];
              
                 allergies = [
                    { id: 1, name: 'Pollen', name_ar: 'حبوب اللقاح' },
                    { id: 2, name: 'Dust Mites', name_ar: 'عث الغبار' },
                    { id: 3, name: 'Pet Dander', name_ar: 'وبر الحيوانات' },
                    { id: 4, name: 'Peanuts', name_ar: 'الفول السوداني' },
                    { id: 5, name: 'Shellfish', name_ar: 'المأكولات البحرية' },
                    { id: 6, name: 'Tree Nuts', name_ar: 'المكسرات' },
                    { id: 7, name: 'Soy', name_ar: 'فول الصويا' },
                    { id: 8, name: 'Dairy Products', name_ar: 'منتجات الألبان' },
                    { id: 9, name: 'Eggs', name_ar: 'البيض' },
                    { id: 10, name: 'Gluten', name_ar: 'الغلوتين' },
                    { id: 11, name: 'Penicillin', name_ar: 'البنسلين' },
                    { id: 12, name: 'Latex', name_ar: 'اللاتكس' },
                    { id: 13, name: 'Bee Stings', name_ar: 'لدغات النحل' }
                  ];
                  
    cancelledSessions: any[] = [];

    constructor(
        
        private translateService: TranslateService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        private modal: ModalService,
        private apiService: ApiService,
        private formBuilder: FormBuilder
    ) {
        this.lang = localStorage.getItem("lang");
        this.translateService.onLangChange.subscribe((lang) => {
            this.lang = lang["lang"];
        });

        // this.fillPatientConsultations();
    }

    ngOnInit(): void {
        // this.resetMedicalFile();
        // this.fillPatientSessions();
        this.getData();
console.log(this.percentage,100-this.percentage)
        this.initPersonalForm();
    }
    setChart(){
   setTimeout(()=>{

    this.doughnutChartDatasets    =
    [
        {
            data: [this.percentage,100-this.percentage],
            backgroundColor: [ "#12B6DF","#EBEDF3"],
            borderWidth: [0.1],
        },
        // { data: [50, 150, 120], label: "Series B" },
    ];
},100) 
console.log(this.percentage,100-this.percentage)

}
    getControlErrors(controlName: string): ValidationErrors | null {
        return this.personalForm?.controls[controlName].errors;
    }
    getMedicalErrors(controlName: string): ValidationErrors | null {
        return this.medicalForm?.controls[controlName].errors;
    }
    getSecurityErrors(controlName: string): ValidationErrors | null {
        return this.securityForm?.controls[controlName].errors;
    }
    handleImageSelected(event: any) {
        this.imageChanged = true
        const file = event.target.files[0];
        console.log(file);
        // this.formData.append('logo', file);
        this.image = file;
    }
    EditPersonalInfo(type) {
        switch (type) {
            case "personal":
                this.isEdit = true;

                break;
            case "medical":
                this.isMedicalEdit = true;
                break;
            default:
                break;
        }
    }
    getData() {
        this.apiService.getProfile().subscribe((res) => {
            this.patchPersonalData(res);
        });
    }
  
    getMedicalData() {
        this.initMedicalForm()
        this.apiService.getMedicalInfo().subscribe((res) => {
            console.log(res);

            this.patchMedicalData(res);
            this.setChart()
        });
    }


    
    patchPersonalData(data) {
        if (data.avatar) {
            this.image = data.avatar;
        }
        this.personalForm.patchValue({
            name: data.name,
            email: data.email ?? "",
            gender: data.gender.toString() ?? "-",
            phone: data.phone ?? "-",
        });
    }
    patchMedicalData(data) {
        const keys = Object.keys(data);

        // Count how many keys have values that are not null
        const nonNullValuesCount = keys.filter(key => data[key] !== null).length;
    
        // Calculate the percentage of keys with non-null values
         this.percentage = (nonNullValuesCount / keys.length) * 100;
         console.log(this.percentage,'100'
         )
        this.medicalForm.patchValue({
            allergies: data.allergies.split(',')
            .map(Number),
            blood_type: data.blood_type,
            created_at: data.created_at,
            dob: data.dob,
            family_medical_history: data.family_medical_history?.split(',')
  .map(Number),
            height: data.height,
            long_term_diseases: data.long_term_diseases?.split(',')
  .map(Number),
            medical_notes: data.medical_notes,
            previous_surgeries: data.previous_surgeries?.split(',')
            .map(Number),
            weight: data.weight,
        });
    }
    initPersonalForm() {
        this.personalForm = this.formBuilder.group({
            name: [""],
            email: [
                "",
                [
                
                    Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                ],
            ],
            avatar: ["", Validators.required],
            gender: [""],
            phone: [""],
        });
    }
    initSecurityForm() {
        this.securityForm = this.formBuilder.group({
            password: ["", Validators.required],
            new_password: [
                "", Validators.required
            ],
            password_confirmation: ["", Validators.required],
});
    }

    initMedicalForm() {
        this.medicalForm = this.formBuilder.group({
            allergies: [""],
            blood_type: [],
            created_at: ["", Validators.required],
            dob: [""],
            family_medical_history: [""],
            height: [""],
            long_term_diseases: [""],
            medical_notes: [""],
            previous_surgeries: [""],
            weight: [""],
        });
    }

    updatePassword(){
        this.changePassword() 

        
    }
    updatePersonalInfo() {
        this.isSubmitted = true;
        this.isEdit = true;

        console.log(this.personalForm.value, this.image);
        const formData = new FormData();
        formData.append("name", this.personalForm.get("name")?.value);
        formData.append("email", this.personalForm.get("email")?.value);
       this.imageChanged? formData.append("avatar", this.image):""
        formData.append("gender", this.personalForm.get("gender")?.value);
        formData.append("phone", this.personalForm.get("phone")?.value);
        // let body = {
        //     name: this.personalForm.get("name")?.value,
        //     email: this.personalForm.get("email")?.value,
        //     avatar: this.image,
        //     gender: this.personalForm.get("gender")?.value,
        //     phone: this.personalForm.get("phone")?.value,
        // };

        this.apiService.editProfile(formData).subscribe((res) => {
            this.isEdit = false;

            console.log(res);
            this.modal.snackMessage("Profile Updated Successfully");
            this.getData();
        });
    }

    updateMedicalInfo(){
        this.isMedicalEdit = true;

        this.isSubmitted = true;

        const formattedDate: string | null = this.datePipe.transform(
            this.medicalForm.value.dob,
            'yyyy-MM-dd'
          );
          let body ={
            ...this.medicalForm.value,
            dob: formattedDate,
            long_term_diseases: this.medicalForm.value.long_term_diseases.join(','),
            allergies: this.medicalForm.value.allergies.join(','),
            family_medical_history: this.medicalForm.value.family_medical_history.join(','),
            previous_surgeries:this.medicalForm.value.previous_surgeries.join(',')



          }
        this.apiService.editMedicalProfile(body).subscribe((res) => {
            this.isMedicalEdit=false
            console.log(res);
            this.modal.snackMessage("Medical Info Updated Successfully");
            this.getMedicalData();
            this.isSubmitted=false
        });
    }
    changeView(value) {
        if (value == "medicalFile") {
            this.isMedicalFile = true;
            this.isMedicalConsultation = false;
            this.isSettings = false;
            this.isPaymentHistory = false;
            this.isSessions = false;
            this.isWallet = false;
            this.isHistory = false;
            this.isPersonal = false;
            this.isSecurity = false;
            this.isArticle = false;

            this.getMedicalData();
        } else if (value == "security") {
            this.isMedicalFile = false;
            this.isHistory = false;
            this.isArticle = false;

            this.isSettings = false;
            this.isPaymentHistory = false;
            this.isSessions = false;
            this.isWallet = false;
            this.isPersonal = false;
            this.isSecurity = true;
            this.initSecurityForm()
        } 
         
else if (value == "personalInfo") {
            this.isSettings = false;
            this.isMedicalConsultation = false;
            this.isMedicalFile = false;
            this.isPaymentHistory = false;
            this.isSessions = false;
            this.isHistory = false;
            this.isArticle = false;

            this.isWallet = false;
            this.isPersonal = true;
            this.isSecurity = false

            
        }
    
    else if (value == "history") {
        this.isHistory = false;
        this.isMedicalConsultation = false;
        this.isMedicalFile = false;
        this.isPaymentHistory = false;
        this.isSessions = false;
        this.isWallet = false;
        this.isPersonal = false;
        this.isSecurity = false
        this.isHistory = true;
        this.isArticle = false;



        
    }
    else if (value == "article") {
        this.isHistory = false;
        this.isMedicalConsultation = false;
        this.isMedicalFile = false;
        this.isPaymentHistory = false;
        this.isSessions = false;
        this.isWallet = false;
        this.isPersonal = false;
        this.isSecurity = false
        this.isHistory = false;
        this.isArticle = true;
        this.getSavedContent()


        
    }
}
    rescheduleSession() {
        //this.test()
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(DatePickerComponent, {
            width: defaultWidth,
            data: {
                //cancelButton: true,
            },
        });

        return dialogRef.afterClosed();
    }

    changeEmail() {
        //this.test()
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(EmailDialogComponent, {
            width: defaultWidth,
            data: {
                //cancelButton: true,
            },
        });

        return dialogRef.afterClosed().subscribe((res) => {
            console.log(res);
            if (res["email"]) {
                this.updatePatientProp("email", res["email"]);
            }
        });
    }

    changePassword() {
        //this.test()
        let defaultWidth: string = "40rem";
        const dialogRef = this.dialog.open(PasswordDialogComponent, {
            width: defaultWidth,
            data: this.securityForm.value
        
        });

        return dialogRef.afterClosed().subscribe((res) => {
            if (res ==='success'){
            this.modal.snackMessage("Password updated successfully");

            }

        });
    }

    changePhoneNumber() {
        //this.test()
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
            width: defaultWidth,
            data: {
                //cancelButton: true,
            },
        });

        return dialogRef.afterClosed().subscribe((res) => {
            console.log(res);
            if (res["phone"]) {
                let body ={
                    phone:res["phone"]
                }
                this.apiService.changePhone(body).subscribe((res)=>{
                    this.modal.snackMessage(this.translateService.instant('Phone_changed'),);
                })
            }
        });
    }

    verifyPhoneNumber() {
        let defaultWidth: string = "350px";
        const dialogRef = this.dialog.open(
            PhoneNumberVerificationDialogComponent,
            {
                width: defaultWidth,
                data: {
                    //cancelButton: true,
                },
            }
        );

        return dialogRef.afterClosed();
    }

    resetMedicalFile() {
        this.medicalFile = JSON.parse(localStorage.getItem("user"));

        console.log(this.medicalFile);
        this.medicalFile.dob = new Date(this.medicalFile.dob);
    }

    updateMedicalFile() {

        if (this.loading) return;
        if (
            !this.medicalFile.email ||
            !this.medicalFile.name ||
            !this.medicalFile.height ||
            !this.medicalFile.width ||
            !this.medicalFile.phone ||
            !this.medicalFile.dob
        ) {
            this.isSubmitted = false;

            this.modal.openValidationDialog(
                "warning",
                "Error",
                "Please fill required fields"
            );
            return;
        }

        this.loading = true;
        this.isSubmitted = true;

        let dob = this.datePipe.transform(this.medicalFile.dob, "dd/MM/yyyy");


        this.apiService.updateProfile(this.medicalFile, dob).subscribe(
            (res) => {
                this.loading = false;
                console.log(res);
                if (res["status"] == false) {
                    this.modal.snackMessage(res["error"]);
                    return;
                } else {
                    this.medicalFile = res["data"];
                    localStorage.setItem("user", JSON.stringify(res["data"]));
                    this.modal.snackMessage("Profile updated successfully");
                }
            },
            (error) => {
                this.loading = false;
                this.modal.snackMessage(error["error"].error);
            }
        );
    }

    setNotificationPermission(e) {
        console.log(e);
    }

    setAutoRenewal(e) {
        console.log(e);
    }

    setLang(e) {
        console.log("e");
    }

    updatePatientProp(key, value) {
        this.isSubmitted = true;

        console.log(key, value);
        let prop = {};
        prop[key] = value;
        console.log(prop[key]);
        if (!prop[key]) {
            this.modal.snackMessage(`Please fill valid ${key}`);
            return;
        }
        this.apiService.updatePatientProperty(prop).subscribe(
            (res) => {
                if (res["status"] == false) {
                    console.log("error");
                    this.modal.snackMessage(res["error"]);
                    return;
                } else {
                    console.log(res);
                    localStorage.setItem("user", JSON.stringify(res["data"]));
                    this.medicalFile = res["data"];
                    this.modal.snackMessage(`${key} changed successfully`);
                }
            },
            (error) => {
                this.modal.snackMessage(error["error"].error);
            }
        );
    }

    fillPatientSessions() {
        this.apiService.getPatientSessions().subscribe((res) => {
            console.log(res);
            this.upcomingSessions = res["data"]["upcoming"];
            this.pastSessions = res["data"]["past"];
            this.cancelledSessions = res["data"]["cancelled"];
        });
    }

    fillPatientConsultations() {
        this.apiService.getMyQuestions().subscribe((res) => {
            this.questions = res["data"]["items"];
        });
    }

    getSavedContent(){
        this.apiService.getSavedContent().subscribe((res:any)=>{
            this.items = res.items
        })
    }
    openChangeEmailModal(){

    }
}
