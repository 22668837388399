import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgxTypedJsModule } from "ngx-typed-js";
import { MaterialModule } from "./material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { FeedbackComponent } from "./components/common/feedback/feedback.component";
import { ProcessComponent } from "./components/common/process/process.component";
import { PartnerComponent } from "./components/common/partner/partner.component";
import { HeaderTwoComponent } from "./components/layouts/header-two/header-two.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { RegisterComponent } from "./components/pages/register/register.component";
import { NewsListComponent } from "./components/pages/news-list/news-list.component";
import { HealthLibraryComponent } from "./components/pages/health-library/health-library.component";
import { WellnessInitiativesListComponent } from "./components/pages/wellness-initiatives-list/wellness-initiatives-list.component";
import { HealthPrescriptionListComponent } from "./components/pages/health-prescription-list/health-prescription-list.component";
import { SingleNewComponent } from "./components/pages/single-new/single-new.component";
import { DiseaseComponent } from "./components/pages/disease/disease.component";
import { SingleWellnessInitiativesComponent } from "./components/pages/single-wellness-initiatives/single-wellness-initiatives.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { SingleHealthPrescriptionComponent } from "./components/pages/single-health-prescription/single-health-prescription.component";
import { DiscoverQuestionsListComponent } from "./components/pages/discover-questions-list/discover-questions-list.component";
import { SubscribeComponent } from "./components/pages/subscribe/subscribe.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AskDoctorComponent } from "./components/pages/ask-doctor/ask-doctor.component";
import { PatientProfileComponent } from "./components/pages/patient-profile/patient-profile.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { SwitchLanguageService } from "./services/switch-language.service";
import { DatePickerComponent } from "./components/common/date-picker/date-picker.component";
import { EmailDialogComponent } from "./components/common/email-dialog/email-dialog.component";
import { PasswordDialogComponent } from "./components/common/password-dialog/password-dialog.component";
import { PhoneNumberDialogComponent } from "./components/common/phone-number-dialog/phone-number-dialog.component";
import { PhoneNumberVerificationDialogComponent } from "./components/common/phone-number-verification-dialog/phone-number-verification-dialog.component";
import { CodeInputModule } from "angular-code-input";
import { DoctorsListComponent } from "./components/pages/doctors-list/doctors-list.component";
import { DoctorProfileComponent } from "./components/pages/doctor-profile/doctor-profile.component";
import { AskSuccessfullyComponent } from "./components/pages/ask-successfully/ask-successfully.component";
import { SavedSuccessfullyComponent } from "./components/pages/saved-successfully/saved-successfully.component";
import { SubscribedSuccessfullyComponent } from "./components/pages/subscribed-successfully/subscribed-successfully.component";
import { SomethingWrongComponent } from "./components/pages/something-wrong/something-wrong.component";
import { ApiService } from "./services/api.service";
import { DialogMessageComponent } from "./components/common/dialog-message/dialog-message.component";
import { AuthService } from "./services/auth.service";
import { ModalService } from "./services/modal.service";
import { FormService } from "./services/form.service";
import { HttpRequestInterceptor } from "./interceptors/http-request.interceptor";
import { CommonModule, DatePipe } from "@angular/common";
import { DiseasesListComponent } from "./components/pages/diseases-list/diseases-list.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ContactUsComponent } from "./components/pages/contact-us/contact-us.component";
import { MatSelectFilterModule } from "mat-select-filter";
import { AboutUsComponent } from "./components/pages/about-us/about-us.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { GeneralInputComponent } from "./components/common/inputs/general-input/general-input.component";
import { RouterModule } from "@angular/router";
import { ValidationErrorsComponent } from "./components/common/shared/components/validation-errors/validation-errors.component";
import { ImageUploadComponent } from "./components/common/shared/components/inputs/image-upload/image-upload.component";
import { SaveCancelButtonsComponent } from "./components/common/shared/components/buttons/save-cancel-buttons/save-cancel-buttons.component";
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
import { NgChartsModule } from "ng2-charts";
import { TextAreaComponent } from "./components/common/inputs/text-area/text-area.component";
import { AiDiagnosisComponent } from "./components/pages/ai-diagnosis/ai-diagnosis.component";
import { ResultsComponent } from "./components/pages/results/results.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { KnowMoreComponent } from "./components/pages/know-more/know-more.component";
import { ApiErrorInterceptor } from "./components/common/shared/interceptors/api-error.interceptor";
import { DateInputComponent } from "./components/common/inputs/date-input/date-input.component";
import { DiagnosisHistoryComponent } from "./components/pages/patient-profile/diagnosis-history/diagnosis-history.component";
import { ViewCategoryComponent } from "./components/pages/health-library/view-category/view-category.component";
import { SingleArticleComponent } from "./components/pages/health-library/single-article/single-article.component";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { RangePipe } from "./pipes/range.pipe";
import { ShareLinkComponent } from "./components/pages/health-library/share-link/share-link.component";
import { SingleContentComponent } from "./components/pages/health-library/single-content/single-content.component";
import { SavedContentComponent } from "./components/pages/health-library/saved-content/saved-content.component";
import { HomePageComponent } from "./components/pages/home-page/home-page.component";
import { HomeAiComponent } from "./components/pages/home-ai/home-ai.component";
import { AskAiComponent } from "./components/pages/ask-ai/ask-ai.component";

@NgModule({
    declarations: [
        AppComponent,
        HomeOneComponent,
        FooterComponent,
        FeedbackComponent,
        ProcessComponent,
        PartnerComponent,
        HeaderTwoComponent,
        ErrorComponent,
        LoginComponent,
        RegisterComponent,
        NewsListComponent,
        HealthLibraryComponent,
        WellnessInitiativesListComponent,
        HealthPrescriptionListComponent,
        SingleNewComponent,
        DiseaseComponent,
        SingleWellnessInitiativesComponent,
        SingleHealthPrescriptionComponent,
        DiscoverQuestionsListComponent,
        SubscribeComponent,
        AskDoctorComponent,
        PatientProfileComponent,
        DatePickerComponent,
        EmailDialogComponent,
        PasswordDialogComponent,
        PhoneNumberDialogComponent,
        PhoneNumberVerificationDialogComponent,
        DoctorsListComponent,
        DoctorProfileComponent,
        AskSuccessfullyComponent,
        SavedSuccessfullyComponent,
        SubscribedSuccessfullyComponent,
        SomethingWrongComponent,
        DialogMessageComponent,
        DiseasesListComponent,
        ContactUsComponent,
        AboutUsComponent,
        PrivacyPolicyComponent,
        AiDiagnosisComponent,
        ResultsComponent,
        KnowMoreComponent,
        ViewCategoryComponent,
        ShareLinkComponent,
        SingleArticleComponent,
        SingleContentComponent,
        SavedContentComponent,
        DiagnosisHistoryComponent,
        HomePageComponent,
        HomeAiComponent,
        AskAiComponent
    ],
    imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    NgxTypedJsModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatSelectFilterModule,
    MatCheckboxModule,
    CommonModule,
    FormsModule,
    ImageUploadComponent,
    ValidationErrorsComponent,
    SaveCancelButtonsComponent,
    GeneralInputComponent,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    TranslateModule.forRoot({
        //defaultLanguage: 'ar',
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
        },
    }),
    CodeInputModule.forRoot({
        codeLength: 6,
        isCharsCode: true,
        code: "——————",
    }),
    NgChartsModule,
    TextAreaComponent,
    MatProgressSpinnerModule,
    DateInputComponent,
    DateAgoPipe,
    RangePipe,
    
],

    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },

        SwitchLanguageService,
        ApiService,
        AuthService,
        ModalService,
        FormService,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        DatePickerComponent,
        EmailDialogComponent,
        PasswordDialogComponent,
        PhoneNumberDialogComponent,
        PhoneNumberVerificationDialogComponent,
        DialogMessageComponent,
    ],
})
export class AppModule {}
