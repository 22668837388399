<div class="svg">

<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
    <g clip-path="url(#clip0_1574_32202)">
      <path d="M21.5 43C33.3741 43 43 33.3741 43 21.5C43 9.62588 33.3741 0 21.5 0C9.62588 0 0 9.62588 0 21.5C0 33.3741 9.62588 43 21.5 43Z" fill="#FF1F1F"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6343 26.7562L17.775 8.17085C17.5822 3.99799 25.4185 4.02355 25.2269 8.17085L24.3675 26.7562V28.2661C24.3675 29.8428 23.0777 31.1327 21.5009 31.1327C19.9241 31.1327 18.6343 29.8428 18.6343 28.2661V26.7562Z" fill="white"/>
      <path d="M21.5016 37.9886C23.0817 37.9886 24.3627 36.7077 24.3627 35.1276C24.3627 33.5475 23.0817 32.2666 21.5016 32.2666C19.9215 32.2666 18.6406 33.5475 18.6406 35.1276C18.6406 36.7077 19.9215 37.9886 21.5016 37.9886Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1574_32202">
        <rect width="43" height="43" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</div>

<p>You’ve reached your daily question limit!</p>
<p>Come back tomorrow to ask more, or review your question history in your profile.</p>