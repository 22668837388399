import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-feedback",
    templateUrl: "./feedback.component.html",
    styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
    @Input("prescriptions") prescriptions: any[] = [];
    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {}

    

    navigateToViewPrescription(obj){
        this.router.navigate(["/single-prescription", { id: obj.id }], {
            relativeTo: this.route,
        });
    }
}
