<div class="container">

    <div class="row">
        <div class="col-sm-10 col-md-3 col-lg-3" >
            <img src="./../../../../assets/images/subscribe.gif">
        </div>
        <div class="col-sm-10 col-md-7 col-lg-7" >
            
            <h1>{{"subscribe"| translate |titlecase}}</h1>
            <h3>
                {{"unlock_ittammen" |translate}}
            </h3>
        <div>{{"subscribe_description" | translate}}</div>
        <ul>
            <li>
                	{{"ai_diagnosis" | translate}}: {{'quick_insights' | translate}}
            </li>
            <li>	{{"ai_doctor" | translate}} (Ava/Aden): {{'chat_virtual_health_consultant' | translate}}</li>
            <li>	{{"health_library" | translate}}: {{'explore_health_articles' | translate}}</li>
            <li>{{"start_health_journey" | translate}}</li>

        </ul>
        <div  class="center" >

            <button routerLink="/subscribe"(click)="subscribe()" >{{'Subscribe Now'|translate}}</button>
        </div>
    </div>
</div>
</div>
