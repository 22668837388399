import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-single-health-prescription",
    templateUrl: "./single-health-prescription.component.html",
    styleUrls: ["./single-health-prescription.component.scss"],
})
export class SingleHealthPrescriptionComponent implements OnInit {
    params$;
    prescription: any = {};
    prescriptionDesc;
    constructor(private route: ActivatedRoute, private apiService: ApiService) {
        this.params$ = this.route.params.subscribe((params) => {
            let id = Number(params["id"]);
            if (id !== undefined) this.fillNew(id);
        });
    }

    ngOnInit(): void {}

    fillNew(id) {
        this.apiService.getHealthPrescription(id).subscribe((res) => {
            if (!res) return;
            this.prescription = res["data"];
            this.prescriptionDesc = this.prescription["text"];
        });
    }
}
