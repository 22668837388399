<!-- <app-header-two></app-header-two> -->

<!-- <header class="hero-header">
    <h1>Health Library</h1>
    <p>Explore a wide range of health news, articles, diet plans, and prescription guides to manage your well-being.</p>
</header> -->

<div class="body">
    <header class="hero-header">
        <h1>Health Library</h1>
        <p>Explore a wide range of health news, articles, diet plans, and prescription guides to manage your well-being.</p>
        <!-- <button class="filter-button">Filter</button> -->
    </header>
    <section class="articles-section row">
        <div class="section-header">
            <h2>Articles</h2>
            <a routerLink="/view-category/1">View All</a>

        </div>
        
        <div class="article-cards row">
            <!-- Repeat this card for each article -->
            <div *ngFor="let item of categorizedData.news | slice:0:3" class=" col-lg-3 col-md-6 col-sm-10">
                    <div class="card ms-3" [routerLink]="['/single-article',item.id]"> 
                    <img [src]="item.image" alt="Article Image">
                    <div class="p-2">
                        <h3>{{item.title |translate}}</h3>
                        <p>{{item.summary}}</p>
                        <span class="metadata">3 min read • 22 June 2024</span>
                    </div>
                   
                </div>
            </div>
            
        </div>
    </section>

    <section class="news-section">
        <div class="section-header">
            <h2 class="ps-2">Health News</h2>
            <a routerLink="/view-category/1">View All</a>
        </div>
    

        <div class="article-cards row">
            <!-- Repeat this card for each news item -->
            <div *ngFor="let item of categorizedData.news |slice:0:3 "  class=" col-lg-3 col-md-6 col-sm-10">
                <div class="card"  [routerLink]="['/single-article',item.id]">
                <img [src]="item.image" alt="Article Image">
                <div class="p-2">

                    <h3 >{{item.title}}</h3>
                    <p>{{item.summary}}</p>
                </div>
            </div>
        </div>
        </div>
    </section>

    <section class="diseases-section">
        <div class="section-header">
            <h2 class="ps-2">Diseases</h2>
            <a *ngIf="categorizedData.diseases.length == 0" routerLink="/view-category/7">View All</a>
        </div>
        <div class="disease-items d-flex  row">

            <div *ngFor="let item of categorizedData.diseases"  class="disease-item col-5 col-sm-10">
                <h3>{{item.title}}</h3>
                <p>{{item.summary}}</p>
            </div>
<div *ngIf="categorizedData.diseases.length == 0"> no diseases to show</div>
        </div>
    </section>

    <section class="diet-plans-section">
        <div class="section-header">
            <h2 class="ps-2">Diet Plans</h2>
            <a routerLink="/view-category/3">View All</a>
        </div>
        <div class="diet-cards row">
            <div *ngFor="let item of categorizedData.dietPlan"  class=" col-lg-4 col-md-5 col-sm-10">
                <div class="diet-card" [routerLink]="['/single-content',item.id]">
                <h3 class="diet-title">{{item.title}}</h3>
                <div class="sub-title"><small>Education company</small></div>
                <p   style="
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;">{{item.summary}}</p></div>
        </div>
        </div>
    </section>

    <section class="medications-section">
        <div class="section-header">
            <h2 class="ps-2">Medications</h2>
            <a routerLink="/view-category/2">View All</a>
        </div>
        <div class="row ">
            <div *ngFor="let item of categorizedData.medication"   class="medication-info col-md-6 col-sm-7 col-lg-5 me-3">
<h3 class="med-title">{{item.title}}</h3>
                <p>{{item.summary}}</p>
     </div>
        
          
                   </div>     
    </section>

    
    <section class="vaccination-section">
        <div class="section-header">
            <h2 class="ps-2">Vaccinations</h2>
            <a routerLink="/view-category/6">View All</a>
        </div>
        <div class="row ">
            <div *ngFor="let item of categorizedData.vaccination"  class="medication-info col-md-6 col-sm-7 col-lg-5 ">
<h3 class="med-title">{{item.title}}</h3>
                <p>{{item.summary}}</p>
     </div>
        
            
                   </div>     
    </section>

    <section class="dentistry-section">
        <div class="section-header">
            <h2 class="ps-2">Dentistry</h2>
            <a routerLink="/view-category/4">View All</a>
        </div>
        <div class="dentistry-cards row">
            
            <div *ngFor="let item of categorizedData.dentistry"   class=" col-lg-3 col-md-4 col-sm-5   ">
                <div class="dentistry-card"[routerLink]="['/single-content',item.id]">
                    <div style="height:3rem">

                        <h4>{{item.title}}</h4>
                    </div>
                    <p>{{item.summary}}</p></div>
    
                </div>  
        </div>
    </section>
</div>


