<div class="input-container mt-2">
    <label class="input-container__label"
        >{{ label }}<span *ngIf="required" style="color: red"> *</span></label
    >
    <div
        class="input-container__wrapper"
        [ngClass]="{
            'input-container__wrapper-error': isSubmitted && errors,

            'auth-input': isAuthInput
        }"
    >
        <img [src]="icon" *ngIf="icon" />

        <input
            #passwordInput
            class="input-container__wrapper__input form-control"
            [type]="type"
            [placeholder]="placeholder"
            (keypress)="changedValue($event)"
            [(ngModel)]="input"
            (ngModelChange)="onChange($event)"
            (blur)="onTouched()"
            [disabled]="isDisabled"
        />

        <img
            class="input-container__wrapper__eye-slash"
            *ngIf="isPassword"
            [src]="passwordIconSrc"
            (click)="toggleShowPassword()"
        />
    </div>

    <app-validation-errors
        [isSubmitted]="isSubmitted"
        [errors]="errors"
        [mismatchError]="mismatchError"
    ></app-validation-errors>
</div>
