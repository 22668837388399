<section class="reset-password container">
    <div class="reset-password__container">
        <div class="x" (click)="route()">x</div>
        <div>
            <h4>{{'otp_code' | translate }}</h4>
        </div>

        <p>
            {{'verification_code_sent' |translate}}
        </p>
        <div class="resend">{{ transform(counter) }} {{'seconds'| translate}}</div>

        <code-input
            [codeLength]="6"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
            [initialFocusField]="0"
        >
        </code-input>
<button (click)="sendOtp()">
    {{'confirm'| translate}}</button>
        <div class="otp">
            <span>
                {{'otp_code_not_sent' | translate}}
            </span>
            <span
                class="green"
                (click)="resend()"
                [ngClass]="{ active: !disabled }"
                >{{'re_sent_code'|translate}}
            </span>
        </div>
    </div>
</section>
