
<div class="mobile">

<br>
<div class="container"  [dir]="$any(dir)">
    <div class="w-100">
        
    
        <div  *ngIf="isResponse && !isLoading" class="d-flex limit-section ">
            
<!--          
            <div *ngIf="!isLoading" style="position: relative; display: inline-block; width:3rem; margin-top: 107px;">
                <canvas baseChart
                [data]="chartData"
                [options]="chartOptions"
                [type]="'doughnut'">
            </canvas>
            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 12px; color: #00FF00;">
                {{this.limit}}/5
            </div>
          
        </div> -->
        <!-- <div class="question">
            {{question}}
        </div> -->
        </div>
        </div>
<div >

<div *ngIf="!isResponse || isLoading" class="body">
    
    <div class="center" >
        <!-- avatar -->
<!-- 
        <div *ngIf="gender ==1" class="circle">
            <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
        </div> -->
        <!-- <div *ngIf="gender ==2" class="circle">
        <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
    </div> -->
    <!-- middle secion -->
    <div  class=" mobile title">
        <div class="mt-4">
            <img class="stars" src="./../../../../assets/home/stars.svg" alt="">
        </div>
        <div>
            <h1 class="d-block mx-3 mt-2">{{"ask"| translate |titlecase}} {{"DR"| translate}} <span class="blue-text">  {{(gender == 2?'AVA':'Aiden') | translate}}
                </span></h1>
                </div>
                <div class="">
                    <img  class="stars"src="./../../../../assets/home/stars.svg" alt="">
                </div>
        </div>
        <h2>{{'how_to_help'|translate}}</h2>
</div>
<!-- 
<div class="center">

    <div  *ngIf="!isLoading" class="d-flex limit-section ">
            
         
        <div *ngIf="!isLoading" style="position: relative; display: inline-block; width:3rem;">
            <canvas baseChart
            [data]="chartData"
            [options]="chartOptions"
            [type]="'doughnut'">
        </canvas>
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 12px; color: #00FF00;">
            {{this.limit}}/5
        </div>
      
        
    </div>
    <div class=""> -->
        <mat-form-field *ngIf="!isResponse " (keyup.enter)="sendData()" appearance="outline" class="wide-input ">
            <input  [(ngModel)]="inputValue" matInput   [placeholder]="('ask ' + (gender == 2 ? 'ava' : 'aiden') | translate | titlecase)" 
            >

            <span (click)="sendData()"  matSuffix><svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 71 71" fill="none">
                <path d="M33.3145 35.5013L24.8808 35.5013M51.9489 34.5441C52.1283 34.6314 52.2795 34.7675 52.3852 34.9367C52.491 35.106 52.547 35.3015 52.5468 35.5011C52.5467 35.7006 52.4904 35.8961 52.3844 36.0652C52.2784 36.2342 52.127 36.3701 51.9475 36.4572L19.8233 51.9274C19.6313 52.0199 19.4158 52.0524 19.205 52.0209C18.9942 51.9894 18.7977 51.8953 18.6411 51.7508C18.4844 51.6062 18.3748 51.4179 18.3264 51.2103C18.2781 51.0027 18.2932 50.7854 18.3699 50.5865L24.0248 35.8823C24.119 35.6371 24.119 35.3656 24.0248 35.1204L18.3713 20.4148C18.2953 20.2161 18.2806 19.9992 18.3291 19.7921C18.3776 19.585 18.4871 19.3972 18.6435 19.253C18.7998 19.1088 18.9958 19.0147 19.2061 18.9831C19.4165 18.9514 19.6315 18.9835 19.8234 19.0752L51.9489 34.5441Z" stroke="#12B6DF" stroke-width="3.39973" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></span>          </mat-form-field> 
              <div *ngIf="requiredMsg">
                {{'required'| translate}}
              </div>
          
    <!-- </div> -->
    <!-- </div>

    </div> -->

</div>
<div *ngIf="isResponse" class="body-res">
<!-- 
<div>
    
    <div class="res d-flex" >
        <div class="circle-res" *ngIf="gender==1">
            <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
        </div>
        <div class="circle-res" *ngIf="gender==2">
            <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
        </div>    <div class="ai-res" [innerHTML]="response.answer"></div>

    </div>
</div> -->

<div *ngIf="!isLoading" class="search" >
    <div class="center">

        <div  *ngIf="!isLoading" class="d-flex limit-section ">
                
             
            <div *ngIf="!isLoading" style="position: relative; display: inline-block; width:3rem; margin-top: 4vh">
                <canvas baseChart
                [data]="chartData"
                [options]="chartOptions"
                [type]="'doughnut'">
            </canvas>
            <div style="position: absolute; top: 57%; left: 50%; transform: translate(-50%, -50%); font-size: 12px; color: #00FF00;">
                {{this.limit}}/5
            </div>
          
        </div>
        <div class="">
            <mat-form-field (keyup.enter)="sendData()" appearance="outline" class="wide-input bottom">
                <input  [(ngModel)]="inputValue" matInput   [placeholder]="('ask ' + (gender == 2 ? 'ava' : 'aiden') | translate | titlecase)" 
                >
                
            <span (click)="sendData()"  matSuffix><svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 71 71" fill="none">
                <path d="M33.3145 35.5013L24.8808 35.5013M51.9489 34.5441C52.1283 34.6314 52.2795 34.7675 52.3852 34.9367C52.491 35.106 52.547 35.3015 52.5468 35.5011C52.5467 35.7006 52.4904 35.8961 52.3844 36.0652C52.2784 36.2342 52.127 36.3701 51.9475 36.4572L19.8233 51.9274C19.6313 52.0199 19.4158 52.0524 19.205 52.0209C18.9942 51.9894 18.7977 51.8953 18.6411 51.7508C18.4844 51.6062 18.3748 51.4179 18.3264 51.2103C18.2781 51.0027 18.2932 50.7854 18.3699 50.5865L24.0248 35.8823C24.119 35.6371 24.119 35.3656 24.0248 35.1204L18.3713 20.4148C18.2953 20.2161 18.2806 19.9992 18.3291 19.7921C18.3776 19.585 18.4871 19.3972 18.6435 19.253C18.7998 19.1088 18.9958 19.0147 19.2061 18.9831C19.4165 18.9514 19.6315 18.9835 19.8234 19.0752L51.9489 34.5441Z" stroke="#12B6DF" stroke-width="3.39973" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></span>
                <!-- <mat-icon (click)="sendData()" matSuffix>search</mat-icon> -->
              </mat-form-field>
              <div *ngIf="requiredMsg">
                {{'required'| translate}}
              </div>
              
        </div>
        </div>
    
        </div>

          
</div>
<div *ngIf="!response">
    <div *ngIf="gender ==1" class="circle">
        <img class="mobile" src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
    </div>
    <div *ngIf="gender ==2" class="circle">
        <img class="mobile" src="./../../../../assets/home/AI_Ava-removebg-preview.png">
    </div>
</div>
</div>

<div class="d-flex">
<div class="bottom left" >
    <div class="talk-bubble tri-right round right-in">
        <div class="talktext">
            <div *ngIf="isLoading" class="dots-container">
                <div>{{'thinking'| translate | titlecase}}</div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
              
            <h4 *ngIf="!isResponse && !isLoading">{{(gender==1? 'hiImEiden':'hiImAva') |translate}}</h4>
            <div class="typing-animation">
          <p class="typing-text" *ngIf="!isResponse && !isLoading">{{"WhatWouldYouLikeToKnow"| translate}} </p>
         
</div>                                      <div class="ai-res" [innerHTML]="response.answer"></div>
        </div>
    </div>
        <p class="disclaimer">{{'ai_services_disclaimer'|translate}}</p>

      </div>
      <div class="bottom">
        <div *ngIf="gender ==1" class="">
            <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
        </div>
        <div *ngIf="gender ==2"  >
            <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
        </div>
    </div>
</div>
</div>
</div>
</div> 
<!-- <div class="container-fluid"> -->











    
<div style="margin-top: 6rem;" class="title web">
<div class="mt-4">
    <img class="stars" src="./../../../../assets/home/stars.svg" alt="">
</div>
<div>
    <h1 class="d-block mx-3 mt-2">{{"ask"| translate |titlecase}} {{"DR"| translate}} <span class="blue-text">  {{(gender == 2?'AVA':'Aiden') | translate}}
        </span></h1>
        </div>
        <div class="mb-6">
            <img  class="stars"src="./../../../../assets/home/stars.svg" alt="">
        </div>
</div>

    <div class=" container web">
    
    
    <div class=" bottom-section ">
        
        <div class="end">

        <div class="bottom left" >
            <div class="talk-bubble tri-right round right-in">
                <div class="talktext">
                    <div *ngIf="isLoading" class="dots-container">
                        <div>{{'thinking'| translate | titlecase}}</div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </div>
                      
                    <h4 *ngIf="!isResponse && !isLoading">{{(gender==1? 'hiImEiden':'hiImAva') |translate}}</h4>
                    <div class="typing-animation">
                  <p class="typing-text" *ngIf="!isResponse && !isLoading">{{"WhatWouldYouLikeToKnow"| translate}} </p>
                 
</div>                                      <div class="ai-res" [innerHTML]="response.answer"></div>
                </div>
              </div>
            <br>
              <div class="d-flex">

        <div  *ngIf="isResponse && !isLoading" class="d-flex limit-section ">
            
         
            <div *ngIf="!isLoading" style="position: relative; display: inline-block; width:4rem;">
                <canvas baseChart
                [data]="chartData"
                [options]="chartOptions"
                [type]="'doughnut'">
            </canvas>
            <div style="position: absolute; top: 36%; left: 50%; transform: translate(-50%, -50%); font-size: 13px; color: #00FF00;">
                {{this.limit}}/5
            </div>
          
        </div>
        </div>
              <div>
        <mat-form-field *ngIf=" !isLoading || isLoading" (keyup.enter)="sendData()" appearance="outline" class="wide-input">
            <input  [(ngModel)]="inputValue" matInput  class=""   [placeholder]="('ask ' + (gender == 2 ? 'ava' : 'aiden') | translate | titlecase)" 
            >
        
            <span (click)="sendData()"  matSuffix><svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="66" viewBox="0 0 71 71" fill="none">
                <path d="M33.3145 35.5013L24.8808 35.5013M51.9489 34.5441C52.1283 34.6314 52.2795 34.7675 52.3852 34.9367C52.491 35.106 52.547 35.3015 52.5468 35.5011C52.5467 35.7006 52.4904 35.8961 52.3844 36.0652C52.2784 36.2342 52.127 36.3701 51.9475 36.4572L19.8233 51.9274C19.6313 52.0199 19.4158 52.0524 19.205 52.0209C18.9942 51.9894 18.7977 51.8953 18.6411 51.7508C18.4844 51.6062 18.3748 51.4179 18.3264 51.2103C18.2781 51.0027 18.2932 50.7854 18.3699 50.5865L24.0248 35.8823C24.119 35.6371 24.119 35.3656 24.0248 35.1204L18.3713 20.4148C18.2953 20.2161 18.2806 19.9992 18.3291 19.7921C18.3776 19.585 18.4871 19.3972 18.6435 19.253C18.7998 19.1088 18.9958 19.0147 19.2061 18.9831C19.4165 18.9514 19.6315 18.9835 19.8234 19.0752L51.9489 34.5441Z" stroke="#12B6DF" stroke-width="3.39973" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></span>
            </mat-form-field> 
            <div class="error" *ngIf="requiredMsg">
              {{'required'| translate}}
            </div>
        </div>
        </div>
        </div>
        <div class="bottom">
            <div *ngIf="gender ==1" class="">
                <img class="img" src="./../../../../assets/home/AI_Aiden-removebg-preview (3).png">
            </div>
            <div *ngIf="gender ==2"  >
                <img class="img"  src="./../../../../assets/home/AI_Ava-removebg-preview 1 (2).png">
            </div>
        </div>
    </div>

    </div>
</div>
<!-- </div> -->