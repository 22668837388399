import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-cancel-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss'],
})
export class CancelButtonComponent {
  @Input() title!: string;
  @Input() disable: boolean = false;
  @Output() btnClicked = new EventEmitter<boolean>(false);

  buttonClicked() {
    this.btnClicked.emit(true);
  }
}
