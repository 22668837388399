import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HealthLibraryService } from '../health-library.service';
import { ShareLinkComponent } from '../share-link/share-link.component';
import { MatDialog } from '@angular/material/dialog';
import * as enTranslations from '../../../../../assets/i18n/en.json'; 
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-single-article',
  templateUrl: './single-article.component.html',
  styleUrls: ['./single-article.component.scss']
})
export class SingleArticleComponent implements OnInit {
type:number
review:any
articles:any =[]
keys:any
content
filteredDetails: any= [];
translationKeys = Object.keys(enTranslations); 

  constructor(private apiService:HealthLibraryService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private translateService: TranslateService,



  ) { }
  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.params['id'];
this.getArticle()


console.log(this.filteredDetails,"article")}

toggleSave(){
  this.apiService.toggleSave(this.type).subscribe((res)=>{
    console.log(res)
    this.getArticle()
  })
}
getArticle(){
  this.apiService.getSingleArticle(this.type).subscribe((res:any)=>{
    this.content =res
    this.articles= res.details
    this.keys = Object.keys(this.articles);

  })
}


openDialog(){
    const dialog = this.dialog.open(ShareLinkComponent, {
      panelClass: 'delete-dialog-panel',
      disableClose: false,
      width: '70vw',
      minWidth: '100px',
      // data: { entity: e, customer_id: e.id },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {

      }
    });
  
}
}
