import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralButtonComponent } from '../general-button/general-button.component';
import { CancelButtonComponent } from '../cancel-button/cancel-button.component';

@Component({
  selector: 'app-save-cancel-buttons',
  standalone: true,
  templateUrl: './save-cancel-buttons.component.html',
  styleUrls: ['./save-cancel-buttons.component.scss'],
  imports: [CommonModule, GeneralButtonComponent, CancelButtonComponent],
})
export class SaveCancelButtonsComponent {
  @Input() saveBtnTitle!: string;
  @Input() cancelBtnTitle!: string;
  @Input() disable: boolean = false;

  @Output() saveBtnClicked = new EventEmitter<boolean>(false);
  @Output() cancelBtnClicked = new EventEmitter<boolean>(false);

  saveButtonClicked() {
    this.saveBtnClicked.emit(true);
  }

  cancelButtonClicked() {
    this.cancelBtnClicked.emit(true);
  }
}
